import React, { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';

import {
  Button,
  Row,
  Column,
  SearchBar,
  Avatar,
  Container,
  ScrollView,
  Text,
} from 'common';
import { Popover } from 'common';
import { FETCH_PATIENTS } from 'graphql/queries';
import { SEARCH_DEBOUNCE_TIME } from 'utils/constants';
import { formatTitleAndName } from 'utils/string';

const PatientSelect = ({ handleClose, handleSelect }) => {
  const [fetchPatients, { data: { patients } = {} }] =
    useLazyQuery(FETCH_PATIENTS);

  const handleSearch = useCallback(
    debounce((query) => {
      fetchPatients({
        variables: {
          query,
          inPractice: 'yes',
          page: 0,
          pageSize: 100,
        },
      });
    }, SEARCH_DEBOUNCE_TIME),
    [fetchPatients]
  );

  const handleQueryChange = (value) => {
    handleSearch(value);
  };

  const handleSelectPatient = useCallback((patient) => {
    handleSelect(patient);
    handleClose();
  }, []);

  return (
    <Popover open maxModal>
      <Container modifiers={['flexBox', 'fullHeight']}>
        <Text modifiers={['center', 'medium', 'bold']}>Select Patient</Text>
        <SearchBar
          onChange={handleQueryChange}
          autoFocus
          placeholder={'Search patients'}
        />
        <ScrollView>
          {patients?.map((contact) => (
            <Row
              modifiers="middle"
              key={contact._id}
              style={{ borderBottom: '1px solid gray' }}
              onClick={() => handleSelectPatient(contact)}
            >
              <Column>
                <Avatar
                  width={50}
                  height={50}
                  modifiers={['round']}
                  user={contact}
                />
              </Column>
              <Column modifiers="col">{formatTitleAndName(contact)}</Column>
            </Row>
          ))}
          {(!patients || patients.length === 0) && (
            <Text modifiers={['medium', 'center']}>No result</Text>
          )}
        </ScrollView>
        <Container modifiers={['flexFixed']}>
          <Row modifiers={['center']}>
            <Button onClick={handleClose}>Close</Button>
          </Row>
        </Container>
      </Container>
    </Popover>
  );
};

export default PatientSelect;

import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { useParams } from 'react-router-dom';
import qs from 'query-string';
import { useLazyQuery, useMutation } from '@apollo/client';
import { debounce } from 'lodash';

import assets from 'assets';
import {
  Container,
  Row,
  Column,
  BackButton,
  ContentCard,
  Avatar,
  Text,
  FormInput,
  Button,
} from 'common';
import { FormFileInput, SelectContact } from 'common';
import { MainLayout } from 'layouts';
import {
  FETCH_PRACTICE_DETAILS,
  FETCH_GROUP_DETAILS,
  FETCH_GROUP_MEMBER_LEADS,
} from 'graphql/queries';
import {
  INVITE_EXISTING_USERS_TO_GROUP,
  UPDATE_GROUP,
  UPDATE_PRACTICE,
} from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import { useFab } from 'contexts/fab';
import { SEARCH_DEBOUNCE_TIME } from 'utils/constants';

import Members from './Members';

const { Header, Content } = MainLayout;

export default function Group({ location }) {
  const fab = useFab();
  const [isEditing, setIsEditing] = useState(false);
  const [memberSearchModalVisible, setMemberSearchModalVisible] =
    useState(false);
  const { id } = useParams();
  const formRef = useRef();

  const query = qs.parse(location.search);
  const isPractice = query.type === 'practice';

  const [
    fetchPracticeDetails,
    {
      data: { practiceDetails } = {},
      loading: practiceDetailsLoading,
      refetch: refetchPracticeDetails,
    },
  ] = useLazyQuery(FETCH_PRACTICE_DETAILS);
  const [
    fetchGroupDetails,
    {
      data: { groupDetails } = {},
      loading: groupDetailsLoading,
      refetch: refetchGroupDetails,
    },
  ] = useLazyQuery(FETCH_GROUP_DETAILS);
  const [fetchGroupMemberLeads, { data: { groupMemberLeads } = {} }] =
    useLazyQuery(FETCH_GROUP_MEMBER_LEADS);
  const [inviteExistingUsersToGroup, { loading: inviting }] = useMutation(
    INVITE_EXISTING_USERS_TO_GROUP,
    {
      onCompleted: () => {
        if (isPractice) {
          refetchPracticeDetails();
        } else {
          refetchGroupDetails();
        }
      },
    }
  );
  const [updateGroup] = useMutation(UPDATE_GROUP);
  const [updatePractice] = useMutation(UPDATE_PRACTICE);
  const data = isPractice ? practiceDetails : groupDetails;
  const loading =
    (isPractice ? practiceDetailsLoading : groupDetailsLoading) || inviting;
  loadingVar(loading);

  const handleClickFab = useCallback(() => {
    setMemberSearchModalVisible(true);
  }, [setMemberSearchModalVisible]);

  const handleSearchMember = useCallback(
    debounce((query) => {
      fetchGroupMemberLeads({
        variables: {
          id,
          query,
          isPractice,
        },
      });
    }, SEARCH_DEBOUNCE_TIME),
    [isPractice]
  );

  const handleFetchDetails = useCallback(() => {
    if (isPractice) {
      fetchPracticeDetails({
        variables: {
          id,
        },
      });
    } else {
      fetchGroupDetails({
        variables: { id },
      });
    }
  }, [id, isPractice, fetchPracticeDetails, fetchGroupDetails]);

  const handleInvite = (newMembers) => {
    const inviteeIds = newMembers.map((m) => m._id);
    inviteExistingUsersToGroup({
      variables: {
        inviteeIds,
        groupId: id,
        isPractice,
      },
    });
  };

  useEffect(() => {
    if (data?.isAdmin) {
      fab.showFab();
      fab.setIcon(assets.icons.icAddUser);
      fab.setAction(handleClickFab);
    } else {
      fab.hideFab();
    }

    return () => {
      fab.hideFab();
    };
  }, [data, handleClickFab]);

  useEffect(() => {
    handleFetchDetails();
  }, [handleFetchDetails]);

  const handleToggleEdit = () => {
    if (isEditing) {
      formRef.current.submitForm();
    }
    setIsEditing(!isEditing);
  };

  const handleSaveGroup = async (values) => {
    const variables = {
      id,
      group: values,
    };
    if (isPractice) {
      await updatePractice({ variables });
    } else {
      await updateGroup({ variables });
    }
  };

  if (!data) {
    return null;
  }

  const { group } = data;
  const initialValues = {
    name: group?.name || '',
    phone: group?.phone || '',
    fax: group?.fax || '',
    email: group?.email,
    address: group?.address,
    description: group?.description || '',
    image: group?.image,
  };

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton />
        </Header.Left>
        <Header.Center>
          <Row modifiers={['middle', 'center']}>
            <Column>
              <Text modifiers={['secondaryColor']}>
                {isPractice ? 'Practice' : 'Group'}
              </Text>
            </Column>
          </Row>
        </Header.Center>
        <Header.Right>
          {data.isAdmin && (
            <Button onClick={handleToggleEdit}>
              {isEditing ? 'Done' : 'Edit'}
            </Button>
          )}
        </Header.Right>
      </Header>
      <Content>
        <Container>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={handleSaveGroup}
          >
            {() => (
              <Form>
                <ContentCard>
                  <Row>
                    <Column modifiers={['col_2']}>
                      <Field
                        required
                        disabled={!isEditing}
                        name="image"
                        component={FormFileInput}
                        s3={{ folder: 'groups' }}
                        modifiers={['round', 'flexColumn']}
                        accept="image/*"
                        width={50}
                        height={50}
                        addText="Add Photo"
                        editText="Edit"
                      >
                        <Avatar
                          image={assets.images.emptyAvatar}
                          width={50}
                          height={50}
                          modifiers={['round']}
                        />
                      </Field>
                    </Column>
                    <Column
                      modifiers={['col_10', 'flexBox', 'verticalContentCenter']}
                    >
                      <Field
                        name="name"
                        component={FormInput}
                        disabled={!isEditing}
                        hidden={!isEditing}
                      />
                    </Column>
                  </Row>
                  {isPractice && (
                    <>
                      <Row>
                        <Field
                          name="address.addressLine1"
                          component={FormInput}
                          disabled={!isEditing}
                          hidden={!isEditing}
                        />
                      </Row>
                      <Row>
                        <Field
                          name="address.city"
                          component={FormInput}
                          disabled={!isEditing}
                          hidden={!isEditing}
                        />

                        <Field
                          name="address.state"
                          component={FormInput}
                          disabled={!isEditing}
                          hidden={!isEditing}
                        />
                      </Row>
                      <Row>
                        <Field
                          name="address.zipcode"
                          component={FormInput}
                          disabled={!isEditing}
                          hidden={!isEditing}
                        />
                      </Row>
                      <Row>
                        <Column
                          modifiers={[
                            'col_3',
                            'flexBox',
                            'verticalContentCenter',
                          ]}
                        >
                          <Text>Ph:</Text>
                        </Column>
                        <Column modifiers={['col_9']}>
                          <Field
                            name="phone"
                            component={FormInput}
                            disabled={!isEditing}
                            hidden={!isEditing}
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column
                          modifiers={[
                            'col_3',
                            'flexBox',
                            'verticalContentCenter',
                          ]}
                        >
                          <Text>Fax:</Text>
                        </Column>
                        <Column modifiers={['col_9']}>
                          <Field
                            name="fax"
                            component={FormInput}
                            disabled={!isEditing}
                            hidden={!isEditing}
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column
                          modifiers={[
                            'col_3',
                            'flexBox',
                            'verticalContentCenter',
                          ]}
                        >
                          <Text modifiers={['center']}>email:</Text>
                        </Column>
                        <Column modifiers={['col_9']}>
                          <Field
                            name="email"
                            component={FormInput}
                            disabled={!isEditing}
                            hidden={!isEditing}
                          />
                        </Column>
                      </Row>
                    </>
                  )}
                  {!isPractice && (
                    <>
                      <Row>
                        <Text>description:</Text>
                      </Row>
                      <Row>
                        <Column modifiers={['col_12']}>
                          <Field
                            name="description"
                            component={FormInput}
                            disabled={!isEditing}
                            hidden={!isEditing}
                          />
                        </Column>
                      </Row>
                    </>
                  )}
                  <Members data={data} refresh={handleFetchDetails} />
                </ContentCard>
              </Form>
            )}
          </Formik>
        </Container>
      </Content>

      {memberSearchModalVisible && (
        <SelectContact
          open
          contactType={isPractice ? 'Providers' : 'Members'}
          users={groupMemberLeads}
          onSearch={handleSearchMember}
          handleClose={() => setMemberSearchModalVisible(false)}
          handleDone={handleInvite}
        />
      )}
    </MainLayout>
  );
}

import { createRef } from 'react';
import { Formik, Form } from 'formik';

import { Row, Button, BackButton } from 'common';
import { Container, Text, Modal } from 'common';
import { CustomRow, CustomField } from '../Frequency/Items';

import assets from 'assets';

const TriggerTypes = ['Consecutive', 'Total'];

const MedicationAlerts = ({ alerts, handleClose, handleSave }) => {
  const initialValues = {
    triggerValue: alerts?.triggerValue || 5,
    triggerType: alerts?.triggerType || 'Consecutive',
  };
  const formRef = createRef();

  const handleDoneClick = () => {
    handleSave(
      formRef.current.values.triggerValue,
      formRef.current.values.triggerType
    );
  };
  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle']}>
          <BackButton onClick={handleClose} />
          <Text>Medication Instructions</Text>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        <Container
          modifiers={['flexBox', 'fluid', 'fullHeight', 'backgroundGray']}
        >
          <Container modifiers={['flexFixed', 'padding_2']}>
            <Text modifiers={['medium']}>Medication Alerts</Text>
          </Container>
          <Container
            modifiers={['flexOne', 'fullHeight', 'flexBox', 'backgroundWhite']}
          >
            <Formik initialValues={initialValues} innerRef={formRef}>
              <Form>
                <Container>
                  <Text modifiers={['block', 'medium', 'secondary']}>
                    Allowable missed medications before trigger alert is sent
                  </Text>
                </Container>
                <Container>
                  <CustomRow>
                    <CustomField name="triggerValue" type="number" />
                    <CustomField
                      fieldType="picker"
                      title="SELECT ORDER"
                      options={TriggerTypes}
                      name="triggerType"
                    />
                  </CustomRow>
                </Container>
                <Container>
                  <Text modifiers={['block', 'orange', 'medium']}>
                    All parties will be alerted and prompted to book an
                    appointment if patient exceeds these limits.
                  </Text>
                </Container>
              </Form>
            </Formik>
          </Container>
          <Container modifiers={['flexFixed', 'footer', 'backgroundWhite']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={handleClose}
              />
              <Button
                modifiers={['primary', 'widthSmall', 'roundCorner']}
                onClick={handleDoneClick}
              >
                Done
              </Button>
            </Row>
          </Container>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default MedicationAlerts;

import { useState, useCallback, useMemo, useEffect } from 'react';
import { Row, Column, ScrollView, Text, Button } from 'common';

import { Container, Tabs, Popover } from 'common';
import VitalItem from './Item';
import Instruction from './Instruction';
import assets from 'assets';

const Main = ({
  vitals,
  updateVitals,
  handleAdd,
  handleShowPrevious,
  handleSave,
  handleClose,
}) => {
  const [deletingVital, setDeletingVital] = useState();
  const [editingVital, setEditingVital] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeVitals, updateActiveVitals] = useState({});
  const [hasIncompleteVital, updateHasIncompleteVital] = useState(false);
  useEffect(() => {
    const newVitals = {};
    let hasIncomplete = false;
    Object.values(vitals).forEach((vital) => {
      if (!vital.active) {
        return;
      }
      if (!vital.frequency) {
        hasIncomplete = true;
      }
      newVitals[vital.id] = vital;
    });
    updateActiveVitals(newVitals);
    updateHasIncompleteVital(hasIncomplete);
  }, [vitals]);

  const onRemove = useCallback((vital) => {
    setShowDeleteModal(true);
    setDeletingVital(vital);
  }, []);

  const onConfirmDelete = () => {
    deletingVital.active = false;
    setShowDeleteModal(false);
    updateVitals({ ...vitals });
  };

  const onEditVital = useCallback((vital) => {
    setEditingVital(vital);
  }, []);

  const handleSaveInstruction = (vital, frequency, alerts) => {
    vital.frequency = frequency;
    vital.alerts = alerts;
    updateVitals({ ...vitals });
  };

  const handleGroupFrequency = (vital, groupedVitals, frequency) => {
    vital.frequency = frequency;
    groupedVitals.forEach((vitalId) => {
      vitals[vitalId].frequency = frequency;
      vitals[vitalId].active = true;
    });
    updateVitals({ ...vitals });
  };

  const [activeTab, setActiveTab] = useState(0);
  const selectTab = (tab) => {
    if (tab === 1) {
      handleShowPrevious();
    }
  };

  return (
    <Container modifiers={['fluid', 'flexBox', 'fullHeight']}>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Column modifiers={['col']}>
            <Text modifiers={['bold', 'block']}>Vitals</Text>
            <Text modifiers={['muted', 'medium', 'block']}>
              Create vitals care plan (CP)
            </Text>
          </Column>
          <Button
            modifiers={['success', 'roundCorner', 'widthXSmall', 'noShadow']}
            onClick={handleAdd}
          >
            + ADD
          </Button>
        </Row>
      </Container>
      <Container
        modifiers={[
          'flexBox',
          'flexOne',
          'fullHeight',
          'backgroundWhite',
          'fluid',
        ]}
      >
        <Container modifiers={['flexFixed', 'topGutters_1']}>
          <Tabs current={activeTab} onChange={selectTab} noSeperator>
            <Tabs.Tab
              title="Recently added"
              modifiers={['xSmallPadding', 'noTab']}
            />
            <Tabs.Tab
              title="Previous CP Vitals"
              modifiers={['xSmallPadding']}
            />
          </Tabs>
        </Container>
        <ScrollView>
          {Object.values(activeVitals)?.length > 0 ? (
            Object.values(activeVitals).map((vital) => (
              <VitalItem
                vital={vital}
                key={vital.id}
                onRemove={() => {
                  onRemove(vital);
                }}
                onEdit={() => {
                  onEditVital(vital);
                }}
              />
            ))
          ) : (
            <Row modifiers={['fullHeight', 'middle', 'center']}>
              <Container>
                <img src={assets.icons.icNoVitals} />
                <Row modifiers={['withGutters']}>
                  <Text>
                    There are no care plan vital tasks. Please select from
                    previous vital plans or press add to create a new vital
                    plan.
                  </Text>
                </Row>
              </Container>
            </Row>
          )}
        </ScrollView>
      </Container>
      <Container modifiers={['flexFixed', 'footer', 'backgroundWhite']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Button
            image={assets.icons.icCloseGray}
            modifiers={['icon', 'transparent']}
            onClick={handleClose}
          />
          <Button
            modifiers={[
              hasIncompleteVital && 'disabled',
              'widthMedium',
              'roundCorner',
            ]}
            disabled={hasIncompleteVital}
            onClick={handleSave}
          >
            Save
          </Button>
        </Row>
      </Container>
      <Popover
        open={showDeleteModal}
        onConfirm={onConfirmDelete}
        onClose={() => setShowDeleteModal(false)}
        footer
      >
        <Text modifiers={['block', 'center']}>
          Are you sure want to delete this vital?
        </Text>
      </Popover>
      {editingVital && (
        <Instruction
          vital={editingVital}
          vitals={activeVitals}
          handleClose={() => setEditingVital(null)}
          handleDone={handleSaveInstruction}
          handleGrouping={handleGroupFrequency}
        />
      )}
    </Container>
  );
};

export default Main;

import { useContext, useMemo } from 'react';
import { Row, Column, Button, Widget } from 'common';

import assets from 'assets';

import { PatientHomeContext } from '../Context';
import { AppointmentServiceContext } from 'contexts/appointment';
import { CallServiceContext } from 'contexts/call';

const ScheduleTile = ({ handleClick }) => {
  const { patient } = useContext(PatientHomeContext);
  const { startingAppointments } = useContext(AppointmentServiceContext);
  const { hasOngoingCall, callRef } = useContext(CallServiceContext);

  const hasOngoingAppointment = useMemo(() => {
    if (!patient || !callRef.current?.appointment) {
      return false;
    }
    const appointment = startingAppointments.find(
      (appointment) => appointment._id === callRef.current?.appointment
    );
    return !!appointment;
  }, [patient, startingAppointments, hasOngoingCall]);

  const startingAppointment = useMemo(() => {
    if (!patient) {
      return null;
    }
    const appointment = startingAppointments.find(
      (appointment) =>
        appointment.patient?._id === patient._id ||
        appointment.patient === patient._id
    );
    return appointment;
  }, [patient, startingAppointments]);

  return (
    <Row modifiers={['center']}>
      <Column modifiers={['col_12', 'noPadding']}>
        {hasOngoingAppointment ? (
          <Row modifiers={['center', 'smallGutters']}>
            <Button modifiers={['small', 'info']} onClick={handleClick}>
              On Call
            </Button>
          </Row>
        ) : (
          startingAppointment && (
            <Row modifiers={['center', 'smallGutters']}>
              <Button
                modifiers={[
                  'small',
                  startingAppointment.status !== 'active' && 'success',
                ]}
                onClick={handleClick}
              >
                {startingAppointment.status === 'active' ? 'Join' : 'Start'}
              </Button>
            </Row>
          )
        )}
      </Column>
      <Widget
        icon={assets.icons.iconSchedule}
        title="Schedule"
        onClick={handleClick}
      />
    </Row>
  );
};

export default ScheduleTile;

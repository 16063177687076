import styled from 'styled-components';
import { buildStyledComponent } from 'style';
import { Container, Row, Text } from 'common';

const Component = ({
  noTitle,
  title,
  primary,
  button,
  children,
  headerClick,
  whiteTitle,
  largeTitle,
  ...props
}) => {
  return (
    <div {...props}>
      <div onClick={headerClick}>
        <div></div>
        {!noTitle && (
          <Row modifiers={['flexBox', 'middle']}>
            <Text
              modifiers={[
                'block',
                whiteTitle && 'white',
                'medium',
                'mediumWeight',
                largeTitle && 'heavy',
                'center',
              ]}
            >
              {title}
            </Text>
            <div style={{ marginLeft: 'auto' }}>{button}</div>
          </Row>
        )}
      </div>
      <Container modifiers={['fluid']}>{children}</Container>
    </div>
  );
};

const modifierConfig = {
  primary: ({ theme }) => `
    >div:first-child {
      background: ${theme.colors.gray1};

      >:nth-child(2) {
        color: ${theme.colors.white};
      }
    }
  `,
  fullHeight: () => `
    height: 100%;
    overflow: hidden;
    > :last-child {
      flex: 1;
    }
  `,
  overflowShow: () => `
    >:last-child {
      overflow: visible;
      > div > :last-child > * >:first-child {
        overflow: visible;
        position: relative;
        > * {
          position: absolute;
          bottom: 100%;
          width: 100%;
        }
      }
    }
  `,
};

const styles = ({ theme }) => `
  padding: 0;
  display: flex;
  flex-direction: column;

  >div:first-child {
    color: ${theme.colors.text};
    font-weight: ${theme.fontWeights.bold};
    padding: 0 ${theme.dimensions.padding_1};

    >:nth-child(2) {
      padding: ${theme.dimensions.padding_1};
      margin: 0;
    }

    >div:last-child {
      button {
        background: white;
        margin: 0;
      }
    }
  }

  > :last-child {
    overflow: hidden;
  }
`;

export const Section = buildStyledComponent(
  'Section',
  styled(Component),
  styles,
  {
    modifierConfig,
  }
);

export default Section;

import { useContext, useEffect, useState } from 'react';

import { Container } from 'common';
import { CreateCareplanContext } from '../Context';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import Main from './Main';
import PastItems from './Past';
import AddItems from './Add';

const Index = ({}) => {
  const { setFooterVisible, careplan, updateCareplan } = useContext(
    CreateCareplanContext
  );
  const { goBack } = useContext(PatientHomeContext);
  const [items, updateItems] = useState({});
  const [activeAction, updateActiveAction] = useState();

  useEffect(() => {
    setFooterVisible(false);
    return () => {
      setFooterVisible(true);
    };
  }, []);

  useEffect(() => {
    if (careplan.content?.careTeam) {
      const newMembers = {};
      careplan.content.careTeam.forEach(
        (item) => (newMembers[item.user._id] = item)
      );
      updateItems({ ...newMembers });
    }
  }, [careplan]);

  const handleAddMembers = (newItems) => {
    Object.values(newItems).forEach((member) => {
      if (items[member._id]) {
        return;
      }
      items[member._id] = { user: member, appointments: [] };
    });
    updateItems({ ...items });
  };

  const handleAdd = () => {
    updateActiveAction('add');
  };
  const handleShowPrevious = () => {
    updateActiveAction('previous');
  };

  const handleSave = () => {
    careplan.content.careTeam = Object.values(items);
    updateCareplan({ ...careplan });
    goBack();
  };

  return (
    <Container modifiers={['fluid', 'fullHeight']}>
      <Main
        items={items}
        handleAdd={handleAdd}
        handleShowPrevious={handleShowPrevious}
        handleSave={handleSave}
        handleClose={() => goBack()}
        updateItems={updateItems}
      />
      {activeAction === 'add' && (
        <AddItems
          items={items}
          handleAdd={handleAddMembers}
          handleClose={() => {
            updateActiveAction(null);
          }}
        />
      )}
      {activeAction === 'previous' && (
        <PastItems
          handleAdd={handleAddMembers}
          handleClose={() => {
            updateActiveAction(null);
          }}
        />
      )}
    </Container>
  );
};

export default Index;

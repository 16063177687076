import React, { useContext, useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import assets from 'assets';
import { startCase } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import {
  Row,
  Column,
  Text,
  ScrollView,
  HistoryItem,
  SearchBar,
  Button,
  HR,
} from 'common';

import { Container, PeriodPicker } from 'common';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import { loadingVar } from 'graphql/cache';
import { FETCH_NOTE_ITEM_PAST_RECORDS } from 'graphql/queries';
import { CreateNoteContext } from '../Context';

const SubjectiveHistory = ({
  handleEdit,
  hide,
  handleClickRemove,
  noteType,
  inputValue,
}) => {
  const { itemsPickedPeriods, setItemsPickedPeriods } =
    useContext(CreateNoteContext);
  const [
    fetchItemRecords,
    { data: { noteItemPastRecords: pastRecords } = {}, loading },
  ] = useLazyQuery(FETCH_NOTE_ITEM_PAST_RECORDS);
  const { patient } = useContext(PatientHomeContext);

  const handleSelectPeriod = (value) => {
    setItemsPickedPeriods({ ...itemsPickedPeriods, [noteType]: value });
    fetchRecords(value);
  };
  const [query, updateQuery] = useState();

  const result = useMemo(() => {
    if (!query) {
      return pastRecords;
    }
    return pastRecords.filter((record) =>
      record.content[noteType].includes(query.trim())
    );
  }, [query, pastRecords]);

  loadingVar(loading);

  const fetchRecords = (period) => {
    fetchItemRecords({
      variables: {
        type: noteType,
        period,
        patient: patient._id,
      },
    });
  };

  useEffect(() => {
    fetchRecords(itemsPickedPeriods[noteType]);
  }, []);

  return (
    <Container
      modifiers={[
        'flexBox',
        'fullHeight',
        'backgroundWhite',
        'padding_2',
        hide && 'noDisplay',
      ]}
    >
      {inputValue?.trim().length > 0 && (
        <HistoryItem modifiers={['dialog']}>
          <Row modifiers={['middle']}>
            <Column modifiers={['col_3', 'fluid']}>
              <Text modifiers={['xSmall', 'secondary']}>
                {moment().format('MM/DD/YY')}
              </Text>
            </Column>
            <Column modifiers={['col_4', 'fluid']}>
              <Button
                onClick={handleEdit}
                modifiers={['link', 'noPadding']}
                image={assets.icons.icEdit}
                width={20}
                height={10}
              >
                edit
              </Button>
            </Column>
            <Column modifiers={['col_2', 'col_offset_3', 'noVerticalPadding']}>
              <Button
                image={assets.icons.icCloseGray}
                modifiers={['icon', 'transparent']}
                onClick={handleClickRemove}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col_12']}>
              <Text modifiers={['block', 'preserveLineBreak']}>
                {inputValue}
              </Text>
            </Column>
          </Row>
        </HistoryItem>
      )}
      <Container modifiers={['flexFixed', 'fluid']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Column>
            <Text modifiers={['bold']}>Past {startCase(noteType)} Notes</Text>
          </Column>
          <Column>
            <PeriodPicker
              value={itemsPickedPeriods[noteType]}
              handleSelect={handleSelectPeriod}
              icon={assets.icons.iconFilter}
              actionTitle="Filter by time period"
            />
          </Column>
        </Row>
        <Row>
          <Column modifiers={['col_12']}>
            <SearchBar onChange={updateQuery} />
          </Column>
        </Row>
      </Container>
      <ScrollView>
        {result &&
          result.map((record, index) => (
            <HistoryItem key={index} editable={false}>
              <Row>
                <Text modifiers={['xSmall', 'muted', 'block']}>
                  {moment(record.time).format('MM/DD/YYYY • hh:mm a')}
                </Text>
              </Row>
              <Row>
                <Text modifiers={['secondary', 'block']}>
                  {record.content[noteType]}
                </Text>
              </Row>
              {index < result.length - 1 && <HR />}
            </HistoryItem>
          ))}
      </ScrollView>
    </Container>
  );
};

export default SubjectiveHistory;

import { useContext, useState } from 'react';

import { Container, Row, Column, Text, ScrollView, SearchBar } from 'common';

import { PeriodPicker } from 'common';
import MessageItem from './Item';
import { CoordinateContext } from '../Context';

export default () => {
  const {
    eFaxPeriod: period,
    updateEFaxPeriod: setPeriod,
    eFaxMessages: messages,
  } = useContext(CoordinateContext);
  const [query, updateQuery] = useState();

  const handleSelectPeriod = (value) => {
    setPeriod(value);
  };

  return (
    <Container modifiers={['fullHeight', 'flexBox']}>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <SearchBar
            value={query}
            onChange={updateQuery}
            placeholder="Search"
          />
          <Column>
            <PeriodPicker value={period} handleSelect={handleSelectPeriod} />
          </Column>
        </Row>
      </Container>
      <ScrollView>
        <Container>
          {messages?.length > 0 ? (
            messages?.map((message, i) => (
              <MessageItem message={message} key={message._id} />
            ))
          ) : (
            <Text modifiers={['muted']}>You don't have any EFax messages</Text>
          )}
        </Container>
      </ScrollView>
    </Container>
  );
};

import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';

import { AuthContext } from 'contexts/auth';
import { REGISTER_PRACTICE } from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import { Button, BackButton, Container, Column, Text } from 'common';
import { Row, FormPinInput, FormInput, FormPhoneInput } from 'common';
import { MainLayout } from 'layouts';
import schema from 'utils/schema';
import urls from 'routes/urls';

const practiceSchema = Yup.object().shape({
  name: Yup.string().required('Practice name is required.'),
  npi: Yup.string().required('NPI number is required.'),
  address: schema.address,
  fax: Yup.string(),
  email: schema.email,
  phone: schema.phone,
});

const RegisterPractice = ({ history }) => {
  const { updateAuth } = useContext(AuthContext);
  const [registerPractice] = useMutation(REGISTER_PRACTICE);

  const initialValues = {
    name: '',
    npi: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipcode: '',
    },
    fax: '',
    email: '',
    phone: '',
  };

  const handleSubmit = async (values) => {
    try {
      loadingVar(true);
      const {
        data: {
          registerPractice: { user },
        },
      } = await registerPractice({
        variables: {
          practice: values,
        },
      });
      await updateAuth(user);
      history.push(urls.DASHBOARD);
    } catch (error) {
    } finally {
      loadingVar(false);
    }
  };

  return (
    <MainLayout hasFooter={false}>
      <MainLayout.Header>
        <MainLayout.Header.Left>
          <BackButton />
        </MainLayout.Header.Left>
        <MainLayout.Header.Center>
          <Text modifiers="pageTitle">Create a Practice</Text>
        </MainLayout.Header.Center>
        <MainLayout.Header.Right />
      </MainLayout.Header>
      <MainLayout.Content>
        <Formik
          initialValues={initialValues}
          validationSchema={practiceSchema}
          onSubmit={handleSubmit}
        >
          {(isValid) => (
            <Form>
              <Container>
                <Field
                  required
                  name="name"
                  placeholder="Add Practice Name"
                  component={FormInput}
                />
              </Container>
              <Container>
                <Field
                  name="npi"
                  hasLabel
                  placeholder="10 Digit NPI Number"
                  required
                  fields={10}
                  wide
                  autoFocus={false}
                  smallPadding={true}
                  component={FormPinInput}
                />
              </Container>

              <Container>
                <Row modifiers={['fullHeight', 'borderBlack', 'roundCorner']}>
                  <Column modifiers="col_12">
                    <Text modifiers={['heavy', 'medium']}>Address</Text>
                  </Column>

                  <Column modifiers="col_12">
                    <Field
                      required
                      name="address.addressLine1"
                      hasBorderPrimaryBottom={true}
                      component={FormInput}
                    />
                    <Text modifiers="medium">Address</Text>
                  </Column>

                  <Column modifiers="col_12">
                    <Field
                      name="address.addressLine2"
                      hasBorderPrimaryBottom={true}
                      component={FormInput}
                    />
                    <Text modifiers="medium">Address Line 2</Text>
                  </Column>

                  <Column modifiers="col_6">
                    <Field
                      required
                      name="address.city"
                      hasBorderPrimaryBottom={true}
                      component={FormInput}
                    />
                    <Text modifiers="medium">City</Text>
                  </Column>

                  <Column modifiers="col_3">
                    <Field
                      required
                      name="address.state"
                      hasBorderPrimaryBottom={true}
                      component={FormInput}
                    />
                    <Text modifiers="medium">State</Text>
                  </Column>

                  <Column modifiers="col_3">
                    <Field
                      required
                      name="address.zipcode"
                      hasBorderPrimaryBottom={true}
                      component={FormInput}
                    />
                    <Text modifiers="medium">Zip</Text>
                  </Column>
                </Row>
              </Container>

              <Container>
                <Row
                  modifiers={[
                    'flexColumn',
                    'fullHeight',
                    'borderBlack',
                    'roundCorner',
                  ]}
                >
                  <Column>
                    <Text modifiers={['heavy', 'medium']}>Contact</Text>
                  </Column>

                  <Column>
                    <Field
                      name="fax"
                      component={FormInput}
                      hasBorderPrimaryBottom={true}
                    />
                    <Text modifiers="medium">Fax</Text>
                  </Column>

                  <Column>
                    <Field
                      required
                      name="email"
                      component={FormInput}
                      hasBorderPrimaryBottom={true}
                    />
                    <Text modifiers="medium">Email</Text>
                  </Column>

                  <Column>
                    <Field
                      required
                      name="phone"
                      component={FormPhoneInput}
                      hasBorderPrimaryBottom={true}
                    />
                    <Text>Phone</Text>
                  </Column>
                </Row>
              </Container>

              <Container>
                <Row modifiers={['center']}>
                  <Column modifiers={['center']}>
                    <Button type="submit" modifiers="primary">
                      Register
                    </Button>
                  </Column>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      </MainLayout.Content>
    </MainLayout>
  );
};

export default RegisterPractice;

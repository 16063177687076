import { useEffect, useState, useContext, useCallback } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash-es';

import { Container, Row, Column, BackButton, Text } from 'common';
import { Modal } from 'common';
import MessageItem from 'pages/Chat/MessageItem';
import { getChat } from 'graphql/utils';
import { formatTitleAndName } from 'utils/string';

const { Body, Header } = Modal;

const ChatItemDetails = ({ attachment, handleBackPress }) => {
  const [chat, setChat] = useState({});
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    (async () => {
      const chat = await getChat(attachment.chatId);
      setChat(chat);
    })();
  }, [attachment]);

  useEffect(() => {
    if (!isEmpty(chat)) {
      setMessages(
        attachment?.messageIds?.map((mId) =>
          chat?.messages?.find((m) => m._id === mId)
        )
      );
    }
  }, [attachment, chat]);

  const memberNames = chat?.members
    ?.map((m) => formatTitleAndName(m))
    .join(', ');

  const getMessageDateRange = useCallback(() => {
    if (messages?.length > 0) {
      const maxAndMin = [
        Math.min(
          ...messages.map((message) => moment(message?.createdAt).valueOf())
        ),
        Math.max(
          ...messages.map((message) => moment(message?.createdAt).valueOf())
        ),
      ];
      return [
        moment(maxAndMin[0]).format('MM/DD/YYYY'),
        moment(maxAndMin[1]).format('MM/DD/YYYY'),
      ];
    }
    return [];
  }, [messages]);

  const messagesDateRange = getMessageDateRange();

  return (
    <Modal open>
      <Header>
        <Container modifiers={['fluid']}>
          <Row>
            <Column modifiers={['col_2']}>
              <BackButton onClick={handleBackPress} />
            </Column>
            <Column modifiers={['col_10']}>
              <Text
                modifiers={['primary', 'subtitle', 'light', 'block', 'start']}
              >{`${messagesDateRange[0]} to ${messagesDateRange[1]}`}</Text>
              <Text
                modifiers={[
                  'inline',
                  'subtitle',
                  'darkGrey',
                  'semiBold',
                  'start',
                ]}
              >
                Participants:
                <Text modifiers={['subtitle', 'darkGrey', 'light']}>
                  {memberNames}
                </Text>
              </Text>
              <Text
                modifiers={[
                  'block',
                  'subtitle',
                  'darkGrey',
                  'semiBold',
                  'start',
                ]}
              >
                Messages
              </Text>
            </Column>
          </Row>
        </Container>
      </Header>
      <Body style={{ background: '#F3F3F4' }} modifiers={['noMargin']}>
        {messages.map((m) => (
          <MessageItem key={m?._id} message={m} />
        ))}
      </Body>
    </Modal>
  );
};

export default ChatItemDetails;

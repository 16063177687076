import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';
import { Container } from 'common';

import Title from './Title';
import Subtitle from './Subtitle';
import Icon from './Icon';

const styles = ({ theme }) => `
  border-radius: ${px2rem(6)};
  background-color: ${theme.colors.white};
  cursor: pointer;
  box-shadow: 0px 1px 2px rgba(46, 41, 78, 0.02), 0px 4px 8px rgba(46, 41, 78, 0.08);
  height: 100%;
`;

const Card = buildStyledComponent('Dashboard_Card', styled(Container), styles);
Card.Title = Title;
Card.Subtitle = Subtitle;
Card.Icon = Icon;

export default Card;

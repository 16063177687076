import { useContext, useEffect, useState, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { useLazyQuery } from '@apollo/client';

import { Row, Column, ScrollView, Text, Button, BackButton } from 'common';
import { Container, Modal, PeriodPicker } from 'common';
import MedicationItem from './Item';
import assets from 'assets';
import { FETCH_PATIENT_PAST_MEDICATIONS } from 'graphql/queries';
import { PatientHomeContext } from 'pages/PatientHome/Context';

export default ({ handleAdd, handleClose }) => {
  const [selectedMedications, updateSelectedMedications] = useState({});
  const { patient } = useContext(PatientHomeContext);

  const [selectedPeriod, updateSelectedPeriod] = useState('3m');

  const [queryCareplans, { data: { patientPastMedications: pastMeds } = {} }] =
    useLazyQuery(FETCH_PATIENT_PAST_MEDICATIONS, {
      variables: {
        patient: patient._id,
        period: selectedPeriod,
      },
      fetchPolicy: 'no-cache',
      initialFetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    });

  const toggleSelect = (medication) => {
    if (selectedMedications[medication.ndc]) {
      delete selectedMedications[medication.ndc];
    } else {
      selectedMedications[medication.ndc] = medication;
    }
    updateSelectedMedications({ ...selectedMedications });
  };

  const handleDone = () => {
    handleAdd(cloneDeep(selectedMedications));
    handleClose();
  };

  useEffect(() => {
    queryCareplans();
  }, [patient, selectedPeriod]);

  const medications = useMemo(() => {
    const newMeds = {};
    if (pastMeds?.length > 0) {
      pastMeds.forEach((medication) => {
        newMeds[medication.ndc] = medication;
      });
    }
    return newMeds;
  }, [pastMeds]);

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle']}>
          <BackButton onClick={handleClose} />
          <Text>Past Medication Plans</Text>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
          <Container modifiers={['flexFixed', 'backgroundGray', 'padding_2']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Column>
                <Text modifiers={['primary']}>Select from below list</Text>
              </Column>
              <Column>
                <PeriodPicker
                  value={selectedPeriod}
                  handleSelect={updateSelectedPeriod}
                  icon={assets.icons.iconFilter}
                  actionTitle="Filter by time period"
                />
              </Column>
            </Row>
          </Container>
          <ScrollView>
            <Container>
              {Object.keys(medications).length === 0 ? (
                <Text>No past medications for selected period found.</Text>
              ) : (
                Object.entries(medications).map(([ndc, medication]) => (
                  <MedicationItem
                    medication={medication}
                    key={ndc}
                    selected={selectedMedications[ndc]}
                    onSelect={() => toggleSelect(medication)}
                  />
                ))
              )}
            </Container>
          </ScrollView>
          <Container modifiers={['flexFixed', 'footer']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={handleClose}
              />
              <Button
                modifiers={[
                  'widthMedium',
                  'roundCorner',
                  Object.keys(selectedMedications).length === 0 && 'disabled',
                ]}
                onClick={handleDone}
              >
                Add Selected
              </Button>
            </Row>
          </Container>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

import { useState, useEffect } from 'react';

import {
  Row,
  Column,
  Checkbox,
  ScrollView,
  Text,
  Button,
  BackButton,
} from 'common';
import { Container, Modal } from 'common';
import assets from 'assets';
import { WELLNESS_ITEMS } from 'utils/constants';

const Add = ({ items, handleAdd, handleClose }) => {
  const [selectedItems, updateSelectedItems] = useState({});

  useEffect(() => {
    Object.entries(items).forEach(([key, item]) => {
      if (item.active) {
        selectedItems[key] = { frequency: null, alerts: null };
      }
    });
    updateSelectedItems({ ...selectedItems });
  }, [items]);

  const handleDone = () => {
    handleAdd(selectedItems);
    handleClose();
  };

  const toggleSelection = (item) => {
    selectedItems[item]
      ? delete selectedItems[item]
      : (selectedItems[item] = { frequency: null, alerts: null });
    updateSelectedItems({ ...selectedItems });
  };

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle']}>
          <BackButton onClick={handleClose} />
          <Text>Add CP Wellness</Text>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
          <Container modifiers={['padding_2']}>
            <Text modifiers={['bold', 'medium']}>Select CP Wellness</Text>
          </Container>
          <ScrollView>
            <Container>
              {Object.entries(WELLNESS_ITEMS).map(([key, item]) => (
                <Row
                  onClick={() => toggleSelection(key)}
                  modifiers={['spaceBetween']}
                  key={key}
                >
                  <Column modifiers={'col'}>
                    <Row modifiers={['middle']}>
                      <img src={item.icon} width={20} height={20} />
                      <Text modifiers={['block']}>{item.title}</Text>
                    </Row>
                  </Column>
                  <Checkbox
                    dark={true}
                    modifiers={['small', 'margin_1']}
                    checked={selectedItems[key]}
                    onChange={() => {}}
                  />
                </Row>
              ))}
            </Container>
          </ScrollView>
          <Container modifiers={['flexFixed', 'footer']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={handleClose}
              />
              <Button
                modifiers={[
                  'primary',
                  'widthSmall',
                  'roundCorner',
                  Object.keys(selectedItems).length === 0 && 'disabled',
                ]}
                onClick={handleDone}
              >
                Add Selected
              </Button>
            </Row>
          </Container>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default Add;

import React, { useState, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';

import assets from 'assets';
import {
  Avatar,
  Button,
  Checkbox,
  Row,
  Column,
  Modal,
  SearchBar,
  Text,
  IconButton,
} from 'common';
import { FETCH_PATIENTS } from 'graphql/queries';
import { loadingVar } from 'graphql/cache';
import { SEARCH_DEBOUNCE_TIME } from 'utils/constants';
import { getFullName } from 'utils/string';

const ReferPatient = ({ referredPatientId, onSelect }) => {
  const [selectedId, setSelectedId] = useState(referredPatientId);
  const [fetchPatients, { data: { patients } = {}, loading }] =
    useLazyQuery(FETCH_PATIENTS);
  loadingVar(loading);
  const handleSearch = useCallback(
    debounce((query) => {
      fetchPatients({
        variables: {
          query,
          inPractice: 'all',
          page: 0,
          pageSize: 100,
        },
      });
    }, SEARCH_DEBOUNCE_TIME),
    [fetchPatients]
  );

  const handleQueryChange = (value) => {
    handleSearch(value);
  };

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers="center">Please Select Patient</Row>
        <SearchBar
          onChange={handleQueryChange}
          autoFocus
          placeholder="Search Patient"
        />
      </Modal.Header>
      <Modal.Body>
        {patients?.map((patient) => (
          <Row
            key={patient._id}
            modifiers="middle"
            onClick={() => setSelectedId(patient._id)}
          >
            <Column modifiers="col_2">
              <Checkbox
                checked={selectedId === patient._id}
                onChange={() => {}}
              />
            </Column>
            <Column modifiers="col_2">
              <Avatar
                width={42}
                height={42}
                modifiers={['round']}
                user={patient}
              />
            </Column>
            <Column modifiers="col">
              <Text modifiers={['inline', 'xSmall', 'muted']}>
                {getFullName(patient)}
              </Text>
            </Column>
          </Row>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Row modifiers={['middle', 'spaceBetween']}>
          <Column>
            <IconButton onClick={() => onSelect()} icon={assets.icons.close} />
          </Column>
          <Column>
            <Button
              modifiers={['primary']}
              onClick={() => onSelect(selectedId)}
            >
              SELECT
            </Button>
          </Column>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default ReferPatient;

import { useContext, useEffect, useState } from 'react';
import { Button, Row } from 'common';
import styled from 'styled-components';

import { Container } from 'common';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import { buildStyledComponent } from 'style';
import Main from 'pages/Appointments/MainList';
import Book from 'pages/Appointments/BookAppointment';
import assets from 'assets';

const Wrapper = buildStyledComponent(
  'AppointmentsWrapper',
  styled(Container),
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    background-color: ${theme.colors.background};

    >:first-child {
      flex: 1 0;
      >:nth-child(2) {
        background: ${theme.colors.white};
        margin: 0;
        padding: ${theme.dimensions.padding_2};
      }
    }
    >:nth-child(2) {
      flex: 0;
      background: ${theme.colors.white};
      padding: ${theme.dimensions.padding_1} ${theme.dimensions.padding_2};
    }
  `
);

const Appointments = () => {
  const { goBack, setBackHandler, patient } = useContext(PatientHomeContext);
  const [showBookAppointment, updateShowBookAppointment] = useState(false);
  const [bookTime, updateBookTime] = useState();
  const [selectedDate, updateSelectedDate] = useState(new Date());
  const handleDone = (appointment) => {
    if (appointment) {
      updateBookTime(new Date());
    }
    updateShowBookAppointment(false);
  };
  useEffect(() => {
    setBackHandler('');
  }, []);

  if (!patient) {
    return null;
  }

  return (
    <Wrapper>
      <Main
        selectedDate={selectedDate}
        updateSelectedDate={updateSelectedDate}
        bookTime={bookTime}
        patient={patient}
      />
      <Container modifiers={['footer']}>
        <Row modifiers={['spaceBetween']}>
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icCloseGray}
            onClick={goBack}
          />
          <Button
            modifiers={['roundCorner']}
            onClick={() => updateShowBookAppointment(true)}
          >
            Add New
          </Button>
        </Row>
      </Container>
      {showBookAppointment && (
        <Book date={selectedDate} patient={patient} handleDone={handleDone} />
      )}
    </Wrapper>
  );
};

export default Appointments;

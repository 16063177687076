import { useContext, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

import { Container, Text } from 'common';
import { Popover } from 'common';

import { CreateCareplanContext } from '../Context';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import Main from './Main';
import PreviousMeds from './PreviousMeds';
import AddMeds from './AddMeds';
import Alerts from './Alerts';

const MedicationIndex = ({}) => {
  const { setFooterVisible, careplan, updateCareplan } = useContext(
    CreateCareplanContext
  );
  const { goBack } = useContext(PatientHomeContext);
  const [medications, updateMedications] = useState({});
  const [alerts, updateAlerts] = useState({});
  const [activeAction, updateActiveAction] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    setFooterVisible(false);
    return () => {
      setFooterVisible(true);
    };
  }, []);

  useEffect(() => {
    if (careplan.content.medication) {
      const meds = cloneDeep(careplan.content.medication);
      if (Object.values(meds).length > 0) {
        updateAlerts(cloneDeep(Object.values(meds)[0].alerts || {}));
      }
      updateMedications({ ...meds });
    }
  }, [careplan]);

  const handleAddMedications = (newMeds) => {
    updateMedications({ ...medications, ...newMeds });
  };

  const handleAdd = () => {
    updateActiveAction('add');
  };
  const handleShowPrevious = () => {
    updateActiveAction('previous');
  };
  const handleAddAlerts = () => {
    updateActiveAction('alerts');
  };

  const handleSaveAlerts = (value, type) => {
    updateAlerts({ triggerValue: value, triggerType: type });
    updateActiveAction(null);
  };

  const handleSave = () => {
    Object.values(medications).forEach(
      (medication) => (medication.alerts = alerts)
    );
    careplan.content.medication = { ...medications };
    updateCareplan({ ...careplan });
    goBack();
  };

  const handleDoneClick = () => {
    if (!alerts || Object.keys(alerts).length === 0) {
      setShowConfirmModal(true);
      return;
    }
    handleSave();
  };

  const onConfirmContinue = () => {
    setShowConfirmModal(false);
    handleSave();
  };

  return (
    <Container modifiers={['fluid', 'fullHeight']}>
      <Main
        medications={medications}
        updateMedications={updateMedications}
        handleAdd={handleAdd}
        handleShowPrevious={handleShowPrevious}
        handleAddAlerts={handleAddAlerts}
        handleSave={handleDoneClick}
        handleClose={() => goBack()}
      />
      {activeAction === 'add' && (
        <AddMeds
          handleAdd={handleAddMedications}
          handleClose={() => {
            updateActiveAction(null);
          }}
        />
      )}
      {activeAction === 'previous' && (
        <PreviousMeds
          handleAdd={handleAddMedications}
          handleClose={() => {
            updateActiveAction(null);
          }}
        />
      )}
      {activeAction === 'alerts' && (
        <Alerts
          alerts={alerts}
          handleSave={handleSaveAlerts}
          handleClose={() => updateActiveAction(null)}
        />
      )}
      <Popover
        open={showConfirmModal}
        onConfirm={onConfirmContinue}
        onClose={() => setShowConfirmModal(false)}
        footer
      >
        <Text modifiers={['bold', 'center']}>No alert added</Text>
        <Text modifiers={['medium', 'center']}>Do you wish to continue?</Text>
      </Popover>
    </Container>
  );
};

export default MedicationIndex;

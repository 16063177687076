import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import moment from 'moment';
import { useHistory, useParams } from 'react-router';
import { useLazyQuery } from '@apollo/client';

import { Text, Container, Avatar, Row, Button, BackButton } from 'common';
import { DatePicker, Tabs } from 'common';
import { MainLayout } from 'layouts';
import assets from 'assets';
import {
  FETCH_PATIENT_REPORT,
  FETCH_PATIENT_LAST_SEEN,
  FETCH_MONTHLY_PATIENT_READINGS,
  FETCH_PATIENT_RESPONSES,
} from 'graphql/queries';
import { loadingVar, reportPeriodVar } from 'graphql/cache';
import { getUser } from 'graphql/utils';
import SharePane from './SharePane';
import TimeTable from './TimeTable';
import Therapeutics from './Therapeutics';
import Physiologics from './Physiologics';
import { getFullName } from 'utils/string';
import { secondsToDuration, displayTime } from 'utils/time';
import urls from 'routes/urls';
import ReportTab from '../ReportTab';

const { Header, Content } = MainLayout;

const shareButtonStyle = {
  marginLeft: 'auto',
  paddingLeft: '12%',
  paddingRight: '12%',
  borderRadius: '1.5rem',
};

const nameStyle = {
  marginLeft: '10px',
  display: 'flex',
  flexDirection: 'column',
};

export default function Patient() {
  const history = useHistory();
  const { id } = useParams();
  const [selectedDate, updateSelectedDate] = useState(reportPeriodVar());
  const backHandler = useRef();
  const [tab, setTab] = useState(0);

  const [fetchReadings, { data: { monthlyPatientReadings } = {} }] =
    useLazyQuery(FETCH_MONTHLY_PATIENT_READINGS, {
      variables: {
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth() + 1,
        patient: id,
      },
    });
  const [fetchPatientReport, { data: { patientReport } = {}, loading }] =
    useLazyQuery(FETCH_PATIENT_REPORT, {
      variables: {
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth() + 1,
        patient: id,
      },
      fetchPolicy: 'no-cache',
      initialFetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    });
  const [fetchPatientLastSeen, { data: { patientLastSeen } = {} }] =
    useLazyQuery(FETCH_PATIENT_LAST_SEEN, {
      fetchPolicy: 'no-cache',
      initialFetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    });
  const [fetchPatientResponses, { data: { patientResponses } = {} }] =
    useLazyQuery(FETCH_PATIENT_RESPONSES);

  const [patient, setPatient] = useState({});

  useEffect(() => {
    (async () => {
      fetchPatientLastSeen({
        variables: {
          patient: id,
        },
      });
      const user = await getUser(id);
      setPatient(user);
    })();
  }, [id]);

  useEffect(() => {
    if (id) {
      const startDate = moment(
        `${
          selectedDate.getMonth() + 1
        }/01/${selectedDate.getFullYear()} 00:00:00`,
        'MM/DD/YYYY hh:mm:ss'
      ).utcOffset(0, true);
      const endDate = startDate.clone().add(1, 'month');
      fetchReadings();
      fetchPatientReport();
      fetchPatientResponses({
        variables: {
          from: startDate.toDate(),
          to: endDate.toDate(),
          patient: id,
        },
      });
    }
  }, [id, selectedDate]);

  const handleChangeDate = useCallback((date) => {
    reportPeriodVar(date);
    updateSelectedDate(date);
  }, []);

  const goBack = () => {
    if (backHandler.current) {
      backHandler.current();
      backHandler.current = null;
      return;
    } else {
      history.replace(urls.REPORTS);
    }
  };

  const careplanList = useMemo(() => {
    const list = {};
    if (patientResponses?.careplans?.length > 0) {
      patientResponses.careplans.forEach(
        (careplan) => (list[careplan._id] = careplan)
      );
    }
    return list;
  }, [patientResponses]);

  loadingVar(loading);

  return (
    <MainLayout hasFooter={false}>
      <Header modifiers="middle">
        <BackButton onClick={goBack} />
        <Avatar modifiers={['squared']} width={35} height={35} user={patient} />
        <div style={nameStyle}>
          <Text modifiers={['h2', 'darkGrey', 'heavy']}>
            {patient && getFullName(patient)}
          </Text>
        </div>
      </Header>
      <Container modifiers={['backgroundWhite']}>
        <Row modifiers={['center', 'withSmallGutters']}>
          <DatePicker
            hideDates
            handleChange={handleChangeDate}
            value={selectedDate}
            maxDate={new Date()}
          >
            <Text modifiers={['primary']}>
              Period: {displayTime(selectedDate, 'MMM YYYY')}
            </Text>
          </DatePicker>
        </Row>
        <Tabs flatTabs multiLine current={tab} onChange={setTab}>
          <ReportTab
            title="Provider Time"
            subTitle={secondsToDuration(monthlyPatientReadings?.time || 0)}
          />
          <ReportTab
            title="Therapeutic Readings"
            subTitle={monthlyPatientReadings?.therapeutic || 0}
          />
          <ReportTab
            title="Physiologic Readings"
            subTitle={monthlyPatientReadings?.physiologic || 0}
          />
        </Tabs>
      </Container>
      <Content style={{ marginBottom: 50, padding: 8 }}>
        {tab === 0 && <TimeTable reports={patientReport} />}
        {tab === 1 && (
          <Therapeutics
            responses={patientResponses?.responses}
            careplans={careplanList}
            backHandler={backHandler}
          />
        )}
        {tab === 2 && (
          <Physiologics
            responses={patientResponses?.responses}
            careplans={careplanList}
          />
        )}
      </Content>
      <SharePane modifiers={['show']} className="detail-report-padding">
        <img
          onClick={() => history.goBack()}
          src={assets.icons.icCloseGray}
          alt=""
          width="25"
          height="25"
        />
        <Button style={shareButtonStyle} modifiers={['primary', 'medium']}>
          Share
        </Button>
      </SharePane>
    </MainLayout>
  );
}

import { useState, useEffect, useContext } from 'react';
import { Row, ScrollView, Text, Button } from 'common';

import { Container } from 'common';
import { PatientHomeContext } from '../../Context';
import { displayTime } from 'utils/time';
import { filterCareplanSchedulesByItemTime } from 'utils/careplan';
import assets from 'assets';
import ListItem from '../ListItem';
import Vitals from './Vitals';
import Wellness from './Wellness';
import Medications from './Medications';
import Activity from './Activity';
import Diet from './Diet';

const CAREPLAN_ITEMS = {
  vital: { name: 'Vitals', icon: assets.icons.icHeartRate },
  medication: { name: 'Medications', icon: assets.icons.icHeartRate },
  activity: { name: 'Activity', icon: assets.icons.icHeartRate },
  wellness: { name: 'Wellness', icon: assets.icons.icHeartRate },
  diet: { name: 'Diet', icon: assets.icons.icHeartRate },
};

const Main = ({}) => {
  const { activeCareplan, setBackHandler, tasks } =
    useContext(PatientHomeContext);
  const dateString = displayTime(new Date(), 'MM/DD/YYYY');
  const timeString = displayTime(new Date(), 'dddd, hh:mm a');
  const [careplanInputs, setCareplanInputs] = useState(() => {
    const items = {};
    Object.entries(CAREPLAN_ITEMS).forEach(([key, value]) => {
      items[key] = {
        ...value,
        items: null,
      };
    });
    return items;
  });
  const [currentInputItem, setCurrentInputItem] = useState(null);

  useEffect(() => {
    const itemSchedules = filterCareplanSchedulesByItemTime(activeCareplan);
    Object.keys(careplanInputs).forEach((key) => {
      if (!!itemSchedules[key]) {
        careplanInputs[key].items = itemSchedules[key];
      }
    });
    setCareplanInputs({ ...careplanInputs });
  }, [activeCareplan]);

  const showInputForm = (item) => {
    setCurrentInputItem(item);
    setBackHandler(() => {
      setCurrentInputItem(null);
      return false;
    });
  };

  return (
    <Container modifiers={['fullHeight', 'fluid']}>
      {!currentInputItem ? (
        <Container modifiers={['fullHeight', 'flexBox', 'fluid']}>
          <Text modifiers={['small']}>{dateString}</Text>
          <Text modifiers={['small']}>{timeString}</Text>
          <ScrollView>
            {Object.entries(careplanInputs).map(([key, item]) => (
              <ListItem
                key={key}
                icon={item.icon}
                title={item.name}
                disabled={!item.items}
                onClick={() => {
                  showInputForm(key);
                }}
              />
            ))}
          </ScrollView>
        </Container>
      ) : (
        <Container modifiers={['fullHeight', 'flexBox', 'fluid']}>
          {currentInputItem === 'vital' && <Vitals />}
          {currentInputItem === 'activity' && <Activity />}
          {currentInputItem === 'medication' && <Medications />}
          {currentInputItem === 'wellness' && <Wellness />}
          {currentInputItem === 'diet' && <Diet />}
        </Container>
      )}
    </Container>
  );
};

export default Main;

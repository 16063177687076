import { useState } from 'react';
import assets from 'assets';
import { Row, Column, Text, Button } from 'common';
import { Popover, PDFViewer, ChatBubble } from 'common';
import SliderFilePreview from './SliderFilePreview';

export const AttachmentFileItem = ({
  item,
  attachments,
  handleRemoveAttachment,
}) => {
  const [showDetail, setShowDetail] = useState(false);
  const [pdfviewerIsOpen, setPDFViewerIsOpen] = useState(false);
  const filename = item.file ? item.file.name : item.originalName;
  const isPdf = item.type?.includes('pdf');

  return (
    <Row modifiers={['spaceBetween']} onClick={() => setShowDetail(true)}>
      <Column modifiers={['noPadding']}>
        <img
          src={item.type === 'file' ? assets.icons.icPdf : assets.icons.icPng}
          alt=""
          width="36"
          height="36"
        />
      </Column>

      <Column modifiers={['col', 'noPadding']}>
        <Text modifiers={['block', 'subtitle', 'darkGrey']}>{filename}</Text>

        <Text modifiers={['block', 'muted', 'small', 'light']}>
          Gazuntite-Record/records
        </Text>
      </Column>

      {handleRemoveAttachment && (
        <Button
          modifiers={['icon', 'transparent']}
          image={assets.icons.icCloseGray}
          width={30}
          height={30}
          onClick={handleRemoveAttachment}
        />
      )}

      {isPdf ? (
        <Popover
          open={showDetail}
          overlayClose
          onClose={() => setShowDetail(false)}
        >
          {pdfviewerIsOpen && (
            <PDFViewer url={item.url} setPDFViewerIsOpen={setPDFViewerIsOpen} />
          )}

          {!pdfviewerIsOpen && (
            <div
              style={{ position: 'relative' }}
              onClick={() => setPDFViewerIsOpen(true)}
            >
              <ChatBubble.PDF url={item.url} />
            </div>
          )}
        </Popover>
      ) : (
        <Popover
          open={showDetail}
          overlayClose
          onClose={() => setShowDetail(false)}
        >
          <SliderFilePreview item={item} attachments={attachments} />
        </Popover>
      )}
    </Row>
  );
};

export default AttachmentFileItem;

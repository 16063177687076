import React from 'react';
import { noop } from 'lodash';
import assets from 'assets';
import { Column, Ribbon, Row, Text } from 'common';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ReactPlayer from 'react-player';

import PDFWrapper from './PDFWrapper';
import MediaPreview from 'common/AddAttachment/MediaPreview';

export default function AttachmentPreview({
  attachment = {},
  handleRemove = noop,
  onSubmit,
}) {
  const renderPreview = () => {
    const isMediaAttachment =
      attachment.type?.includes('video') || attachment.type?.includes('image');
    if (isMediaAttachment) {
      const isVideo = attachment.type?.includes('video');
      const mediaPreviewProps = {
        src: attachment.url,
      };

      const videoPreviewProps = {
        url: attachment.url,
        width: 56,
        height: 56,
      };

      return (
        <>
          <Column modifiers={['col_2']} />
          <Column modifiers={['col_2']}>
            <Ribbon right="0px" onClick={handleRemove}>
              <img
                src={assets.icons.icCloseWhite}
                width={18}
                height={18}
                alt=""
              />
            </Ribbon>

            {isVideo ? (
              <ReactPlayer {...videoPreviewProps} />
            ) : (
              <MediaPreview
                {...mediaPreviewProps}
                isVideo={isVideo}
                width={56}
                height={56}
              />
            )}
          </Column>
        </>
      );
    }
    const isPdfFile = attachment.type === 'application/pdf';
    if (isPdfFile) {
      return (
        <Column modifiers={['col_12', 'fluid', 'noVerticalPadding']}>
          <Row modifiers={['middle', 'spaceBetween']}>
            <Column onClick={handleRemove}>
              <Text modifiers={['primary', 'h2', 'light']}>Cancel</Text>
            </Column>
            <Column>
              <Text modifiers={['h2']}>File</Text>
            </Column>
            <Column onClick={onSubmit}>
              <Text modifiers={['primary', 'h2', 'light']}>Send</Text>
            </Column>
            <Column modifiers={['col_12']}>
              <PDFWrapper>
                <Document file={attachment.file}>
                  <Page pageNumber={1} />
                </Document>
              </PDFWrapper>
            </Column>
          </Row>
        </Column>
      );
    }
    return null;
  };
  return renderPreview();
}

import { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Text } from 'common';
import { cloneDeep } from 'lodash';

import List from './List';
import OrderDetail from './Detail';
import { SimpleLayout } from 'layouts';
import { loadingVar } from 'graphql/cache';
import { FETCH_PHARMACY_ORDERS } from 'graphql/queries';
import { EVENTS, SocketContext } from 'contexts/socket';

const PharmacyOrders = ({ history }) => {
  const [fetchPharmacyOrders, { data: { pharmacyOrders } = {}, loading }] =
    useLazyQuery(FETCH_PHARMACY_ORDERS);
  const [filteredOrders, updateFilteredOrders] = useState({
    orders: [],
    processing: [],
    status: [],
  });
  const [selectedOrder, updateSelectedOrder] = useState();
  const [selectedPeriod, updateSelectedPeriod] = useState('3m');
  const { subscribe } = useContext(SocketContext);
  const orderSubscription = useRef();

  useEffect(() => {
    if (!pharmacyOrders) {
      updateFilteredOrders({
        orders: [],
        processing: [],
        status: [],
      });
      return;
    }
    const orders = [],
      processing = [],
      status = [];
    pharmacyOrders.forEach((medicationOrder) => {
      if (medicationOrder.status === 'placed') {
        orders.push(cloneDeep(medicationOrder));
      } else if (medicationOrder.status === 'sent') {
        processing.push(cloneDeep(medicationOrder));
      } else {
        status.push(cloneDeep(medicationOrder));
      }
    });
    updateFilteredOrders({ orders, processing, status });
  }, [pharmacyOrders]);

  loadingVar(loading);

  const fetchOrders = (period) => {
    fetchPharmacyOrders({
      variables: {
        period,
      },
    });
  };

  const ordersUpdateEventHandler = useCallback(() => {
    fetchOrders(selectedPeriod);
  }, [selectedPeriod]);

  useEffect(() => {
    orderSubscription.current?.unsubscribe();
    orderSubscription.current = subscribe(
      EVENTS.MEDICATION_ORDERS,
      ordersUpdateEventHandler
    );
    return () => {
      orderSubscription.current?.unsubscribe();
    };
  }, [selectedPeriod]);

  useEffect(() => {
    fetchOrders(selectedPeriod);
  }, []);

  const handleOrderClick = (order) => {
    updateSelectedOrder(order);
  };

  const goBack = () => {
    if (selectedOrder) {
      updateSelectedOrder(null);
    } else {
      history.replace('/');
    }
  };

  const handleFinishUpdateOrder = () => {
    fetchOrders(selectedPeriod);
  };

  return (
    <SimpleLayout>
      <SimpleLayout.Header
        handleBack={goBack}
        content={
          selectedOrder ? (
            <Text modifiers={['center']}>
              Order{' '}
              <Text modifiers={['primary']}>
                #{selectedOrder.orderNumber.toUpperCase()}
              </Text>
            </Text>
          ) : (
            'Medication Orders'
          )
        }
      />
      <SimpleLayout.Content>
        <List
          allOrders={filteredOrders}
          handleOrderClick={handleOrderClick}
          handleSelectPeriod={fetchOrders}
          selectedPeriod={selectedPeriod}
          updateSelectedPeriod={updateSelectedPeriod}
          hide={!!selectedOrder}
        />
        {selectedOrder && (
          <OrderDetail
            order={selectedOrder}
            allOrders={filteredOrders}
            updateOrders={updateFilteredOrders}
            handleBack={goBack}
            handleAfterUpdate={handleFinishUpdateOrder}
          />
        )}
      </SimpleLayout.Content>
    </SimpleLayout>
  );
};

export default PharmacyOrders;

import { useState, useEffect, useCallback, useRef, useContext } from 'react';
import styled from 'styled-components';
import { Column, Button, VR, Avatar } from 'common';
import { useMutation } from '@apollo/client';
import moment from 'moment';

import { AuthContext } from 'contexts/auth';
import AppointmentDetail from './AppointmentDetail';
import Reschedule from './Reschedule';
import { Container, Row, Text } from 'common';
import { displayTime } from 'utils/time';
import { buildStyledComponent, px2rem } from 'style';
import { CallServiceContext, CALL_TYPES } from 'contexts/call';
import { loadingVar } from 'graphql/cache';
import { RESCHEDULE_APPOINTMENT } from 'graphql/mutations';

const Appointment = ({
  appointment,
  handleCancelAppointment,
  handleDoneReschedule,
  handleGoToChart,
  ...props
}) => {
  const [canStart, updateCanStart] = useState(false);
  const timeoutRef = useRef();
  const { startCall, hasOngoingCall, callRef } = useContext(CallServiceContext);
  const [showDetail, setShowDetail] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const { me } = useContext(AuthContext);

  const [rescheduleAppointment] = useMutation(RESCHEDULE_APPOINTMENT);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (appointment.status === 'active') {
      updateCanStart(true);
      return;
    }
    if (appointment.status === 'scheduled') {
      const startTime = moment(appointment.time).subtract(1, 'm');
      const secondsToStart = startTime.diff(moment(), 'seconds');
      if (secondsToStart <= 0) {
        updateCanStart(true);
      } else {
        timeoutRef.current = setTimeout(() => {
          updateCanStart(true);
          timeoutRef.current = null;
        }, secondsToStart * 1000);
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [appointment]);

  const joinCall = useCallback(
    (event) => {
      event.stopPropagation();
      setShowDetail(false);
      startCall(CALL_TYPES.SCHEDULED, appointment.patient, null, {
        appointment,
      });
    },
    [appointment, startCall]
  );

  const handleClose = (event) => {
    event.stopPropagation();
    setShowDetail(false);
  };

  const clickCancel = (event) => {
    event.stopPropagation();
    setShowDetail(false);
    handleCancelAppointment(appointment);
  };

  const clickReschedule = (event) => {
    event.stopPropagation();
    setShowReschedule(true);
  };

  const handleReschedule = async (reason, selectedSlot) => {
    loadingVar(true);
    await rescheduleAppointment({
      variables: {
        appointment: appointment._id,
        newAppointment: {
          provider: appointment.provider._id,
          patient: appointment.patient._id,
          reason,
          time: selectedSlot.time,
        },
      },
    });
    loadingVar(false);
    setShowReschedule(false);
    setShowDetail(false);
    handleDoneReschedule();
  };

  const clickGoToChart = (event) => {
    event.stopPropagation();
    setShowDetail(false);
    handleGoToChart(appointment);
  };

  return (
    <Container
      modifiers={['card']}
      {...props}
      onClick={() => setShowDetail(true)}
    >
      <Container>
        <Text modifiers={['medium', 'primary']}>
          {displayTime(appointment.time, 'hh:mm a')}
        </Text>
        <VR modifiers={['height_95%']} />
      </Container>
      <Container>
        <Avatar
          width={30}
          height={30}
          modifiers={['round']}
          user={appointment.patient}
        />
        <Column modifiers={['col', 'noPadding']}>
          <Text modifiers={['medium', 'block']}>
            {appointment.patient.firstName}
          </Text>
          <Text modifiers={['medium', 'block']}>
            {appointment.patient.lastName}
          </Text>
        </Column>
      </Container>
      {showDetail && (
        <>
          <AppointmentDetail
            appointment={appointment}
            clickCancel={clickCancel}
            handleClose={handleClose}
            clickReschedule={clickReschedule}
            clickGoToChart={clickGoToChart}
            canStart={canStart}
            joinCall={joinCall}
            isOngoingCall={
              hasOngoingCall && callRef.current?.appointment === appointment._id
            }
          />
          <Reschedule
            appointment={appointment}
            isOpen={showReschedule}
            handleClose={() => setShowReschedule(false)}
            handleReschedule={handleReschedule}
          />
        </>
      )}
      <Container>
        <Row modifiers={['end']}>
          {hasOngoingCall &&
          callRef.current?.appointment === appointment._id ? (
            <Button
              modifiers={['small', 'info', 'roundCorner', 'widthXSmall']}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              On Call
            </Button>
          ) : (
            <>
              {appointment.status === 'scheduled' && canStart && (
                <Button
                  modifiers={['small', 'success', 'roundCorner', 'widthXSmall']}
                  onClick={joinCall}
                >
                  Start
                </Button>
              )}
              {appointment.status === 'active' && (
                <Button
                  modifiers={['small', 'primary', 'roundCorner', 'widthXSmall']}
                  onClick={joinCall}
                >
                  {appointment.joined?.includes(me._id) ? 'Rejoin' : 'Join'}
                </Button>
              )}
              {appointment.status === 'cancelled' && (
                <Text modifiers={['danger', 'medium']}>Cancelled</Text>
              )}
              {appointment.status === 'rescheduled' && (
                <Text modifiers={['success', 'medium']}>Rescheduled</Text>
              )}
            </>
          )}
        </Row>
      </Container>
    </Container>
  );
};

export default buildStyledComponent(
  'AppointmentsItem',
  styled(Appointment),
  ({ theme }) => `
    display: flex;
    flex-direction: row;
    padding: 0 ${px2rem(10)};
    align-items: center;

    > :first-child {
      padding: ${px2rem(5)} 0;
      width: ${px2rem(80)};
      flex: 0 0 auto;
    }

    > :nth-child(2) {
      display: flex;
      flex-direction: row;
      align-items: center;

      > :last-child {
        margin-left: ${px2rem(8)};
      }
    }

    >:nth-child(3):not(:last-child) {
      span {
        font-size: ${theme.dimensions.fontSizeMedium};
      }
    }

    > :last-child {
      width: ${px2rem(120)};
      flex: 0 0 auto;
    }
  `
);

import { HistoryItem } from 'common';
import { Container, Row, Text, Modal, AttachmentFileItem } from 'common';
import ChatItem from './ChatItem';
import NoteItem from './NoteItem';
import { displayTime } from 'utils/time';

const AddedItems = ({ attachments, updateAttachments }) => {
  const onRemove = (index) => {
    Modal.confirm({
      onOk: () => {
        attachments.splice(index, 1);
        updateAttachments([...attachments]);
      },
      title: 'Delete Attachment Note',
      text: 'Are you sure want to delete this attachment?',
      okText: 'Delete',
    });
  };
  return (
    <HistoryItem modifiers={['dialog']}>
      <Container>
        <Row modifiers={['spaceBetween', 'bottomGutters_1']}>
          <Text modifiers={['medium', 'secondary', 'semiBold']}>
            Recently Added
          </Text>
          <Text modifiers={['small']}>
            {displayTime(new Date(), 'MM/DD/YYYY')}
          </Text>
        </Row>
        {attachments.map((attachment, index) => {
          const Comp =
            attachment?.category === 'chat'
              ? ChatItem
              : attachment?.category === 'note'
              ? NoteItem
              : AttachmentFileItem;
          return (
            <Comp
              key={index}
              item={attachment}
              // handleBackPress={() => setShowItem(false)}
              handleRemoveAttachment={() => onRemove(index)}
              attachments={attachments}
            />
          );
        })}
      </Container>
    </HistoryItem>
  );
};

export default AddedItems;

import { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import moment from 'moment';

import { Formik, Field, Form } from 'formik';
import { Row, Column, VR, Button } from 'common';
import { buildStyledComponent } from 'style';

import {
  Container,
  Text,
  FormPicker,
  FormTimePicker,
  FormDatePicker,
  Modal,
} from 'common';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import ProviderSelect from './ProviderSelect';
import { formatTitleAndName } from 'utils/string';
import { ADD_MANUAL_RECORD } from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import { hourToDate } from 'utils/time';
import { AuthContext } from 'contexts/auth';

const DurationMins = [
  { id: '5', name: '5 mins' },
  { id: '10', name: '10 mins' },
  { id: '15', name: '15 mins' },
  { id: '30', name: '30 mins' },
  { id: '45', name: '45 mins' },
  { id: '60', name: '60 mins' },
];

const Tasks = [
  { id: 'chat', name: 'Message' },
  { id: 'audio', name: 'Audio PSTN' },
  { id: 'video', name: 'Video Call' },
  { id: 'note', name: 'Create Note' },
  { id: 'careplan', name: 'Create Careplan' },
  { id: 'orderMeds', name: 'Order Meds' },
  { id: 'review', name: 'Chart Review' },
];

const initialValues = {
  date: new Date(),
  time: '12:00 am',
  duration: '5',
  task: null,
};

const Component = ({}) => {
  const { me } = useContext(AuthContext);
  const [showReportProviderSearchModal, setShowReportProviderSearchModal] =
    useState(false);
  const [selectedReportProvider, updateSelectedReportProvider] = useState(me);
  const [showReferredProviderSelection, setShowReferredProviderSelection] =
    useState(false);
  const [showReferredProviderSearchModal, setShowReferredProviderSearchModal] =
    useState(false);
  const [selectedReferredProvider, updateSelectedReferredProvider] = useState();
  const { patient, goBack } = useContext(PatientHomeContext);
  const [addManualRecord] = useMutation(ADD_MANUAL_RECORD);

  const handleTaskChange = (task) => {
    if (task === 'chat' || task === 'video') {
      setShowReferredProviderSelection(true);
    } else {
      setShowReferredProviderSelection(false);
      updateSelectedReferredProvider(null);
    }
  };

  const onReferredProviderSelect = () => {
    setShowReferredProviderSearchModal(true);
  };

  const handleSelectReferredProvider = (provider) => {
    updateSelectedReferredProvider(provider);
  };

  const onReportProviderSelect = () => {
    setShowReportProviderSearchModal(true);
  };

  const handleSelectReportProvider = (provider) => {
    updateSelectedReportProvider(provider);
  };

  const handleSubmit = async (data) => {
    if (!data.task) {
      Modal.info({ text: 'Please select the task.' });
    } else {
      const reportDate = moment(data.date);
      const reportTime = hourToDate(data.time, reportDate);
      const reportTimeDateObject = reportTime.toDate();
      loadingVar(true);
      await addManualRecord({
        variables: {
          patient: patient._id,
          from: reportTimeDateObject,
          duration: parseInt(data.duration),
          task: data.task,
          provider: selectedReferredProvider?._id,
          reporter: selectedReportProvider?._id,
        },
      });
      loadingVar(false);
      goBack();
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        <Container modifiers={['fullHeight', 'flexBox', 'fluid']}>
          <Container modifiers={['flexOne']}>
            <Container modifiers={['flexFixed']}>
              <Row>
                <Column modifiers={['col_4']}>
                  <Field
                    hasLabel
                    labelLightWeight
                    asInfo
                    noBorder
                    name="date"
                    title="Select date"
                    placeholder="Date"
                    maxDate={Date()}
                    dateFormat="MM/DD/YY"
                    component={FormDatePicker}
                  />
                  <VR modifiers={['padding_2']} />
                </Column>
                <Column modifiers={['col_4']}>
                  <Field
                    hasLabel
                    labelLightWeight
                    asInfo
                    noBorder
                    name="time"
                    placeholder="Time"
                    component={FormTimePicker}
                  />
                  <VR modifiers={['padding_2']} />
                </Column>
                <Column modifiers={['col_4']}>
                  <Field
                    hasLabel
                    labelLightWeight
                    asInfo
                    noBorder
                    name="duration"
                    placeholder="Duration"
                    options={DurationMins}
                    component={FormPicker}
                  />
                </Column>
              </Row>
            </Container>
            <Container>
              <Row modifiers={['middle']}>
                <Text>Task</Text>
                <Field
                  labelLightWeight
                  asInfo
                  noBorder
                  name="task"
                  placeholder="Select Task"
                  options={Tasks}
                  handleChange={handleTaskChange}
                  component={FormPicker}
                />
              </Row>
            </Container>
            {showReferredProviderSelection && (
              <Container>
                <Row modifiers={['middle']}>
                  <Text>Referred Provider</Text>
                  <Column modifiers={'col_4'}>
                    <Text
                      onClick={onReferredProviderSelect}
                      modifiers={['blue']}
                    >
                      {selectedReferredProvider
                        ? formatTitleAndName(selectedReferredProvider)
                        : 'None'}{' '}
                    </Text>
                  </Column>
                </Row>
              </Container>
            )}
            <Container>
              <Row modifiers={['middle']}>
                <Text>Provider</Text>
                <Column modifiers={'col_4'}>
                  <Text onClick={onReportProviderSelect} modifiers={['blue']}>
                    {formatTitleAndName(selectedReportProvider)}
                  </Text>
                </Column>
              </Row>
            </Container>
          </Container>
          <Container modifiers={['flexFixed', 'footer']}>
            <Row modifiers={['spaceAround']}>
              <Button modifiers={['widthSmall', 'roundCorner']} type="submit">
                Save
              </Button>
              <Button
                modifiers={['widthSmall', 'outlinePrimary', 'roundCorner']}
                onClick={goBack}
              >
                Cancel
              </Button>
            </Row>
          </Container>
        </Container>
        {showReferredProviderSearchModal && (
          <Container
            modifiers={[!showReferredProviderSearchModal && 'noDisplay']}
          >
            <ProviderSelect
              handleClose={() => setShowReferredProviderSearchModal(false)}
              handleSelect={handleSelectReferredProvider}
            />
          </Container>
        )}
        {showReportProviderSearchModal && (
          <Container
            modifiers={[!showReportProviderSearchModal && 'noDisplay']}
          >
            <ProviderSelect
              handleClose={() => setShowReportProviderSearchModal(false)}
              handleSelect={handleSelectReportProvider}
              showMe={true}
            />
          </Container>
        )}
      </Form>
    </Formik>
  );
};

const ActiveCareplanAddTimeSection = buildStyledComponent(
  'ActiveCareplanAddTimeSection',
  styled(Component),
  ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeMedium};
    text-align: center;
  `
);

export default ActiveCareplanAddTimeSection;

import { useContext, useEffect, useMemo, useState } from 'react';

import { Text, ContentCard, Row, Column } from 'common';
import { useSwipeable } from 'react-swipeable';
import moment from 'moment';

import {
  Container,
  SimpleTable,
  PieChart,
  DetailedList,
  ScrollView,
} from 'common';
import {
  getMeasureProgress,
  filterPeriodResponses,
  filterCareplanSchedulesByItemTime,
} from 'utils/careplan';
import { PROGRESS_ITEMS } from 'utils/constants';
import { displayTime, toLocalTime } from 'utils/time';
import SwitchSection from './SwitchSection';
import theme from 'style/theme';
import { ProgressContext } from './Context';
import { PatientHomeContext } from 'pages/PatientHome/Context';

const COLUMNS = [
  {
    key: 'day',
    label: 'Day',
  },
  { key: 'value', label: 'Value' },
  {
    key: 'time',
    label: 'Time',
  },
];

export default ({ type, successColor }) => {
  const [selectedDate, updateSelectedDate] = useState(new Date());
  const { progress, fetchProgress } = useContext(PatientHomeContext);
  const { responses, pastCPs } = useContext(ProgressContext);
  const measureProgress = useMemo(() => {
    if (!!progress) {
      return getMeasureProgress(progress, type);
    }
    return null;
  }, [progress]);
  const isNA = measureProgress === null;
  const [period, updatePeriod] = useState(0);
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    if (progress === null || progress === undefined) {
      fetchProgress();
    }
  }, []);

  const periodData = useMemo(() => {
    return filterPeriodResponses(responses, period, selectedDate);
  }, [responses, period, selectedDate]);

  const tableData = useMemo(() => {
    const datas = [];
    const careplanData = {};
    periodData.forEach((dailyResponse) => {
      if (type === 'medication') {
        if (!careplanData[dailyResponse.careplan]) {
          const careplan = pastCPs[dailyResponse.careplan];
          if (!careplan) {
            return;
          }
          careplanData[dailyResponse.careplan] = {
            careplan,
            dailySchedule: {},
          };
        }
        const dayString = displayTime(
          toLocalTime(dailyResponse.date),
          'MM/DD/YY'
        );
        const dailySchedules = filterCareplanSchedulesByItemTime(
          careplanData[dailyResponse.careplan].careplan,
          ['medication'],
          toLocalTime(dailyResponse.date)
        );
        careplanData[dailyResponse.careplan].dailySchedule[dayString] =
          dailySchedules.medication;
      }
      dailyResponse.responses
        .filter((response) => response.measure === type)
        .sort((a, b) => {
          if (new Date(a.time) > new Date(b.time)) {
            return -1;
          }
          return 1;
        })
        .forEach((response) => {
          const data = {
            day: displayTime(toLocalTime(dailyResponse.date), 'D MMM'),
            value:
              response.isPositive === true
                ? { title: 'YES', modifiers: ['bold', 'success'] }
                : response.isPositive === false
                ? { title: 'NO', modifiers: ['bold', 'danger'] }
                : { title: 'N/A', modifiers: ['bold', 'secondaryColor'] },
            time:
              type === 'medication'
                ? displayTime(
                    response.addedTime
                      ? response.addedTime
                      : toLocalTime(response.time),
                    'MM/DD/YY @hh:mm A'
                  )
                : {
                    title: displayTime(
                      response.addedTime
                        ? response.addedTime
                        : toLocalTime(response.time),
                      'hh:mm A'
                    ),
                    modifiers: ['secondaryColor'],
                  },
          };
          if (type === 'medication') {
            data.items = careplanData[dailyResponse.careplan].dailySchedule[
              displayTime(toLocalTime(dailyResponse.date), 'MM/DD/YY')
            ][displayTime(toLocalTime(response.time), 'hh:mm A')]?.map(
              (medication) => medication.name
            );
          }
          datas.push(data);
        });
    });
    return datas;
  }, [periodData, pastCPs]);

  const graphData = useMemo(() => {
    let totalCount = 0,
      positiveCount = 0,
      progress;
    tableData.forEach((data) => {
      if (data.value.title === 'YES' || data.value.title === 'NO') {
        totalCount++;
      }
      if (data.value.title === 'YES') {
        positiveCount++;
      }
    });
    if (totalCount === 0) {
      return [
        { name: 'N/A', value: 100, color: theme.colors.gray1 },
        { name: '', value: 0, color: theme.colors.graphGray },
      ];
    }
    progress = (positiveCount * 100) / totalCount;
    return [
      {
        name: `${Math.round(progress)}%`,
        value: progress,
        color: progress > 60 ? successColor : theme.colors.danger,
      },
      { name: '', value: 100 - progress, color: theme.colors.graphGray },
    ];
  }, [tableData]);

  const handleSwipe = (direction) => {
    const newDate = moment(selectedDate);
    const today = moment();
    switch (period) {
      case 0:
        newDate.add(direction, 'd');
        if (newDate.isAfter(today)) {
          return;
        }
        break;
      case 1:
        newDate.add(direction, 'w');
        if (newDate.isAfter(today) && newDate.weeks() > today.weeks()) {
          return;
        }
        break;
      case 2:
        newDate.add(direction, 'M');
        if (newDate.isAfter(today) && newDate.month() > today.month()) {
          return;
        }
        break;
      case 3:
        newDate.add(direction, 'y');
        if (newDate.isAfter(today) && newDate.year() > today.year()) {
          return;
        }
        break;
    }
    updateSelectedDate(newDate.toDate());
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      handleSwipe(1);
    },
    onSwipedRight: () => {
      handleSwipe(-1);
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
      <Container modifiers={['flexFixed']}>
        <ContentCard modifiers={['withGutters', 'fluid']}>
          <Row modifiers={['middle']}>
            <Column modifiers={['col_9', 'flexBox', 'verticalContentCenter']}>
              <img src={PROGRESS_ITEMS[type].icon} />
              <Text modifiers={['darkGrey', 'subtitle', 'semiBold']}>
                {PROGRESS_ITEMS[type].title}
              </Text>
            </Column>
            <Column modifiers={['col_3']}>
              <Row modifiers={['middle', 'spaceBetween']}>
                <Text
                  modifiers={[
                    isNA
                      ? 'muted'
                      : measureProgress > 60
                      ? 'success'
                      : 'danger',
                    'subtitle',
                    'semiBold',
                  ]}
                >
                  {!isNA ? `${Math.round(measureProgress)}%` : 'N/A'}
                </Text>
              </Row>
            </Column>
          </Row>
        </ContentCard>
      </Container>
      <Container
        modifiers={['fluid', 'flexBox', 'fullHeight', 'backgroundWhite']}
      >
        {showTable ? (
          <>
            <Container modifiers={['flexFixed', 'fluid']}>
              <SwitchSection
                date={selectedDate}
                updateDate={updateSelectedDate}
                period={period}
                updatePeriod={updatePeriod}
                showTable={showTable}
                setShowTable={setShowTable}
              />
            </Container>
            <Container modifiers={['flexOne', 'fullHeight']}>
              <Container modifiers={['padding_2', 'fullHeight']}>
                {type === 'medication' ? (
                  <DetailedList data={tableData} />
                ) : (
                  <SimpleTable columns={COLUMNS} data={tableData} />
                )}
              </Container>
            </Container>
          </>
        ) : (
          <ScrollView>
            <Container modifiers={['flexFixed', 'fluid']}>
              <SwitchSection
                date={selectedDate}
                updateDate={updateSelectedDate}
                period={period}
                updatePeriod={updatePeriod}
                showTable={showTable}
                setShowTable={setShowTable}
              />
            </Container>
            <Container modifiers={['flexOne', 'fullHeight']} {...swipeHandlers}>
              <PieChart data={graphData} />
            </Container>
          </ScrollView>
        )}
      </Container>
    </Container>
  );
};

import { Container, Text } from 'common';
import styled from 'styled-components';

import { Row } from 'common';
import MeasureItem from './MeasureItem';
import { buildStyledComponent } from 'style';
import { frequencyTitle, alertsTitle } from 'utils/helper';
import assets from 'assets';

const Activity = buildStyledComponent(
  'CareplanActivity',
  styled(({ activity, ...props }) => {
    const activityDetail = { Schedule: frequencyTitle(activity.frequency) };
    if (activity.duration) {
      activityDetail.Duration = activity.frequency.duration;
    }
    if (activity.alerts) {
      activityDetail.Triggers = activity.alerts?.triggerValue
        ? `${activity.alerts.triggerValue} missed`
        : '';
      activityDetail.Allowed = alertsTitle(activity.alerts);
      activityDetail.Action = 'Book Appointments';
    }
    return (
      <Container {...props}>
        <Text modifiers={['bold']}>ACTIVITY</Text>
        <Row modifiers={['middle', 'smallGutters']}>
          <img src={assets.icons.icActivity} />
          <Text>{activity.activity}</Text>
        </Row>
        <MeasureItem details={activityDetail} />
      </Container>
    );
  }),
  ({ theme }) => `
    >:not(:first-child) {
      margin-left: ${theme.dimensions.padding_2};
    }
  `
);

export default Activity;

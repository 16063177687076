import styled from 'styled-components';
import { Container } from 'common';

import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  background-color: ${theme.colors.white};
  box-shadow: ${theme.dimensions.boxShadow_2};
  border-radius: 12px;
`;

export default buildStyledComponent(
  'ContactProfile_CardContainer',
  styled(Container),
  styles
);

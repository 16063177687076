import {
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useLazyQuery } from '@apollo/client';

import { FETCH_PATIENT_CAREPLANS } from 'graphql/queries';
import { Row, Column, Text, ScrollView } from 'common';

import { Container, Tabs, Loader, PeriodPicker } from 'common';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import { EVENTS, SocketContext } from 'contexts/socket';
import Item from './Item';

const Careplans = ({ handleViewCareplan }) => {
  const { patient, records, setRecords } = useContext(PatientHomeContext);
  const [fetchCareplans, { loading }] = useLazyQuery(FETCH_PATIENT_CAREPLANS, {
    onCompleted: ({ patientCareplans: careplans }) => {
      const newCPs = records.careplans;
      newCPs.list = careplans;
      setRecords({ ...records, careplans: newCPs });
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });
  const [tab, setTab] = useState(0);
  const { subscribe } = useContext(SocketContext);
  const careplanListUpdateSubscription = useRef();

  const fetchPastCPs = (period = null) => {
    fetchCareplans({
      variables: {
        patient: patient._id,
        period: period || records.careplans.period,
        status: 'all',
      },
    });
  };

  const handleSelectPeriod = (value) => {
    const careplans = records.careplans;
    if (value !== careplans.period) {
      careplans.period = value;
      careplans.list = [];
      setRecords({ ...records, careplans });
      if (!!patient) {
        fetchPastCPs(value);
      }
    }
  };

  useEffect(() => {
    if (!patient) {
      return;
    }
    fetchPastCPs();
  }, [patient]);

  const activeCP = useMemo(() => {
    if (records?.careplans?.list?.length > 0) {
      return records.careplans.list.filter((careplan) => careplan.isActive);
    }
    return [];
  }, [records]);

  const draftCPs = useMemo(() => {
    if (records?.careplans?.list?.length > 0) {
      return records.careplans.list.filter((careplan) => careplan.isDraft);
    }
    return [];
  }, [records]);

  const expiredCPs = useMemo(() => {
    if (records?.careplans?.list?.length > 0) {
      return records.careplans.list.filter(
        (careplan) => !careplan.isDraft && !careplan.isActive
      );
    }
    return [];
  }, [records]);

  useEffect(() => {
    careplanListUpdateSubscription.current?.unsubscribe();
    careplanListUpdateSubscription.current = subscribe(
      EVENTS.PATIENT_CAREPLANS,
      (payload) => {
        if (payload?.patient === patient?._id) {
          fetchPastCPs();
        }
      }
    );
    return () => {
      careplanListUpdateSubscription.current?.unsubscribe();
    };
  }, [fetchPastCPs, patient]);

  const careplans = tab === 0 ? activeCP : tab === 1 ? expiredCPs : draftCPs;

  return (
    <Container modifiers={['flexBox', 'fullHeight']}>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Text modifiers={['medium', 'bold']}>Care Plans List</Text>
          <Column>
            <PeriodPicker
              value={records.notes.period}
              handleSelect={handleSelectPeriod}
            />
          </Column>
        </Row>
        <Tabs current={tab} onChange={setTab} flatTabs>
          <Tabs.Tab title="Active" />
          <Tabs.Tab title="Expired" />
          <Tabs.Tab title="Drafts" />
        </Tabs>
      </Container>
      <ScrollView>
        {loading ? (
          <Row modifiers={['center', 'withGutters']}>
            <Loader.Icon />
          </Row>
        ) : careplans.length > 0 ? (
          <Container>
            {careplans.map((careplan, index) => (
              <Item
                key={index}
                careplan={careplan}
                handleClick={() => handleViewCareplan(careplan)}
              />
            ))}
          </Container>
        ) : (
          <Text>No care plans found.</Text>
        )}
      </ScrollView>
    </Container>
  );
};

export default Careplans;

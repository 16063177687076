import { useState } from 'react';

import { Checkbox, Column, Button } from 'common';

import { Container, Row, SlidingPane, Text } from 'common';
import { frequencyTitle } from 'utils/helper';
import { DIET_ITEMS } from 'utils/constants';
import assets from 'assets';

const GroupSelectionModal = ({
  isVisible,
  frequency,
  items,
  onConfirm,
  onClose,
}) => {
  const [selectedItems, setSelectedItems] = useState({});
  const frequencyTitles = frequencyTitle(frequency, true);

  const toggleSelection = (item) => {
    selectedItems[item.id]
      ? delete selectedItems[item.id]
      : (selectedItems[item.id] = { id: item.id });
    setSelectedItems({ ...selectedItems });
  };

  const handleConfirm = () => {
    onConfirm(Object.keys(selectedItems));
    onClose();
  };

  return (
    <SlidingPane
      isOpen={isVisible}
      handleClose={onClose}
      modifiers={['fillHeight']}
    >
      <SlidingPane.Header modifiers={['hasBorder']}>
        <Text modifiers={['h2', 'bold', 'darkGrey', 'center', 'block']}>
          GROUP DIETS
        </Text>
      </SlidingPane.Header>
      <SlidingPane.Content>
        <Row modifiers={['smallGutters']}>
          <Text modifiers={['medium', 'semiBold']}>Frequency Detail</Text>
        </Row>
        <Row modifiers={['smallGutters']}>
          <Text modifiers={['medium']}>Freq: </Text>
          <Text modifiers={['primary', 'medium']}>
            {frequencyTitles?.frequency}
          </Text>
        </Row>
        <Row modifiers={['smallGutters']}>
          <Text modifiers={['medium']}>On: </Text>
          <Text modifiers={['primary', 'medium']}>{frequencyTitles?.on}</Text>
        </Row>
        <Row modifiers={['smallGutters']}>
          <Text modifiers={['medium']}>@: </Text>
          <Text modifiers={['primary', 'medium']}>{frequencyTitles?.at}</Text>
        </Row>
        <Row modifiers={['smallGutters']}>
          <Text modifiers={['medium']}>
            Select the diet you would like to have this same frequency
          </Text>
        </Row>
        {Object.entries(items).map(([key, item]) => (
          <Row key={key} onClick={() => toggleSelection(item)}>
            <Checkbox
              dark={true}
              modifiers={['small', 'margin_1']}
              checked={!!selectedItems[key]}
              onChange={() => {}}
            />
            <Column modifiers={['col']}>
              <Text modifiers={['block']}>{DIET_ITEMS[key].title}</Text>
            </Column>
          </Row>
        ))}
        <Row modifiers={['withGutters']}>
          <Text modifiers={['block', 'success', 'medium']}>
            You have selected {Object.keys(selectedItems).length} diet with the
            above frequency.
          </Text>
        </Row>
      </SlidingPane.Content>
      <Container modifiers={['flexBox', 'footer']}>
        <Row modifiers={['spaceBetween']}>
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icCloseGray}
            onClick={onClose}
          />
          <Button
            modifiers={[
              'primary',
              'roundCorner',
              'widthMedium',
              Object.keys(selectedItems).length === 0 && 'disabled',
            ]}
            onClick={handleConfirm}
          >
            <Text modifiers={['subtitle', 'semiBold', 'white']}>
              Group Frequency{' '}
              {Object.keys(selectedItems).length > 0
                ? `(${Object.keys(selectedItems).length})`
                : ''}
            </Text>
          </Button>
        </Row>
      </Container>
    </SlidingPane>
  );
};

export default GroupSelectionModal;

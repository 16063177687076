import styled from 'styled-components';
import { Container } from 'common';
import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  background-color: ${theme.colors.blueBrand};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default buildStyledComponent(
  'AuthLayout_Wrapper',
  styled(Container),
  styles
);

import { get } from 'lodash';
import React, { useState } from 'react';
import assets from 'assets';

import Input from 'common/Input';
import FormLabel from '../FormLabel';
import InputContainer from './InputContainer';
import FormError from '../FormError';

export const FormInput = (props) => {
  const [focused, setFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const {
    field: { name, onBlur, onChange, value },
    form: { errors, touched, setFieldTouched },
    hasLabel,
    hasBorderBottom,
    hasBorderPrimaryBottom,
    asInfo,
    asGrayInfo,
    labelLightWeight,
    dark,
    smallFeedback,
    placeholder,
    required,
    innerRef,
    noBorder,
    noPadding,
    fullWidth,
    widthSmall,
    useLabel,
    onClick,
    type,
    hidden,
    fitContent,
    label,
    modifiers = [],
    largeLabel,
    maxLength,
    selectedDropdownIcon,
    disabled,
    flex = false,
    ...inputProps
  } = props;

  const hasError = get(errors, name) && get(touched, name);
  const valid = !get(errors, name) && value;

  const handleChange = (e) => {
    let event = e;
    if (maxLength) {
      event = {
        ...e,
        target: {
          ...e.target,
          value: e.target.value.slice(0, maxLength),
        },
      };
    }

    onChange(name)(event);
  };

  const onGetModifier = () => {
    if (!selectedDropdownIcon) return;

    return `drop_down_icon_${selectedDropdownIcon}`;
  };

  return (
    <InputContainer modifiers={[onGetModifier(), flex && 'flex']}>
      {(hasLabel || useLabel) && (
        <FormLabel
          modifiers={[
            dark && 'dark',
            labelLightWeight && 'labelLightWeight',
            useLabel && 'inline',
            useLabel && 'info',
            largeLabel && 'large',
            largeLabel && 'bottomSpacing',
          ]}
          onClick={useLabel && onClick}
        >
          {useLabel ? value : label || placeholder}{' '}
          {!useLabel && required && <span>*</span>}
        </FormLabel>
      )}

      <Input
        modifiers={[
          dark && 'dark',
          hasError && 'error',
          hasBorderBottom && 'borderBottomOnly',
          asInfo && 'asInfo',
          asGrayInfo && 'asGrayInfo',
          (valid || focused) && 'valid',
          noBorder && 'noBorder',
          noPadding && 'noPadding',
          widthSmall && 'widthSmall',
          hidden && 'hidden',
          fitContent && 'fitContent',
          disabled && 'asGrayInfo',
          ...modifiers,
        ]}
        placeholder={placeholder || ''}
        value={value}
        onChange={handleChange}
        onBlur={() => {
          setFieldTouched(name);
          onBlur(name);
          setFocused(false);
        }}
        onClick={onClick}
        onFocus={() => {
          setFocused(true);
        }}
        ref={innerRef}
        type={useLabel ? 'hidden' : type || 'text'}
        disabled={disabled}
        {...inputProps}
      />

      {type === 'password' && (
        <button
          className="toggle-password-button"
          onClick={() => setShowPassword(!showPassword)}
          style={hasError ? { top: '55%' } : { top: '67%' }}
        >
          <img
            src={
              showPassword
                ? assets.icons.iShowPassword
                : assets.icons.iHidePassword
            }
            alt={showPassword ? 'show-password' : 'hide-password'}
          />
        </button>
      )}

      {hasError && (
        <FormError modifiers={[smallFeedback && 'small']}>
          {get(errors, name)}
        </FormError>
      )}
    </InputContainer>
  );
};

export default FormInput;

import React from 'react';
import { Text, Avatar } from 'common';

import { getFullName } from 'utils/string';

import Wrapper from './Wrapper';

const ReferredPatient = ({ referredPatient }) => {
  return (
    referredPatient && (
      <Wrapper>
        <Text modifiers={['subtitle', 'semiBold', 'keepWord']}>Ref: </Text>
        <Avatar
          user={referredPatient}
          width={30}
          height={30}
          modifiers={['round']}
        />
        <Text modifiers={['subtitle', 'semiBold', 'block']}>
          {getFullName(referredPatient)}
        </Text>
      </Wrapper>
    )
  );
};

export default ReferredPatient;

import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { isEmpty } from 'lodash';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import assets from 'assets';
import ReactPlayer from 'react-player';

import { Container, Row, Column, BackButton, Text, Button } from 'common';
import { FileInput, Modal, PDFViewer } from 'common';

import MediaPreview from './MediaPreview';

export const AddAttachment = forwardRef(({ onAddAttachment }, ref) => {
  const fileInputRef = useRef(null);
  const [attachment, setAttachment] = useState(null);
  const [pdfviewerIsOpen, setPDFViewerIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    openInput: () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    },
  }));

  const renderBody = () => {
    const wasFileChosen = !isEmpty(attachment);
    if (wasFileChosen) {
      const isMediaAttachment =
        attachment.type?.includes('video') ||
        attachment.type?.includes('image');
      const isVideo = attachment.type?.includes('video');

      if (isMediaAttachment) {
        const mediaPreviewProps = {
          src: attachment.url,
        };

        return (
          <Modal.Body modifiers={['centering']}>
            <Container>
              <Row>
                <Column
                  modifiers={[
                    'col_12',
                    'flexBox',
                    'verticalContentCenter',
                    'center',
                  ]}
                >
                  {isVideo ? (
                    <ReactPlayer url={attachment.url} controls />
                  ) : (
                    <MediaPreview {...mediaPreviewProps} isVideo={isVideo} />
                  )}
                </Column>
              </Row>
            </Container>
          </Modal.Body>
        );
      }

      if (attachment.type.includes('pdf')) {
        return (
          <Modal.Body modifiers={['centering', 'inlineBlock']}>
            <Container>
              <Row>
                <Column
                  modifiers={['col_12', 'flexBox', 'verticalContentCenter']}
                >
                  {pdfviewerIsOpen ? (
                    <PDFViewer
                      url={attachment.file}
                      setPDFViewerIsOpen={setPDFViewerIsOpen}
                    />
                  ) : (
                    <div
                      style={{ display: 'flex', width: '100%' }}
                      onClick={() => setPDFViewerIsOpen(true)}
                    >
                      <Document
                        file={attachment.file}
                        className="custom_pdf_viewer_margin"
                      >
                        <Page pageNumber={1} />
                      </Document>
                    </div>
                  )}
                </Column>
              </Row>
            </Container>
          </Modal.Body>
        );
      }
    }

    return null;
  };

  const onClickClose = (e) => {
    e.preventDefault();
    if (!isEmpty(attachment)) {
      setAttachment(null);
      fileInputRef.current.value = null;
    }
    fileInputRef.current.blur();
  };

  const handleAdd = () => {
    onAddAttachment(attachment);
    if (!isEmpty(attachment)) {
      setAttachment(null);
      fileInputRef.current.value = null;
    }
    fileInputRef.current.blur();
  };

  return (
    <>
      <FileInput
        isAttach={true}
        attachRef={fileInputRef}
        onChangeAttachFile={(attachFile) => setAttachment(attachFile)}
      />
      <Modal open={!!attachment}>
        <Modal.Header
          style={{ boxShadow: '0px -2px 10px rgba(46, 41, 78, 0.08)' }}
        >
          <Container modifiers={['fluid']}>
            <Row modifiers={['middle', 'spaceBetween']}>
              <Column modifiers={['col_7', 'flexBox', 'verticalContentCenter']}>
                <BackButton onClick={onClickClose} />
                <Text modifiers={['subtitle', 'semiBold']}>
                  Add Attachments
                </Text>
              </Column>
            </Row>
          </Container>
        </Modal.Header>

        {renderBody()}

        <Modal.Footer
          style={{
            boxShadow:
              '0px -1px 2px rgba(46, 41, 78, 0.02), 0px -4px 8px rgba(46, 41, 78, 0.08)',
          }}
        >
          <Container>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                image={assets.icons.icCloseGrey}
                modifiers={['icon', 'transparent']}
                width={22}
                height={22}
                onClick={onClickClose}
              />
              <Button
                disabled={isEmpty(attachment)}
                modifiers={[
                  'roundCorner',
                  'widthSmall',
                  isEmpty(attachment) && 'disabled',
                ]}
                onClick={handleAdd}
              >
                Add
              </Button>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default AddAttachment;

import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';
import assets from 'assets';
import { Column, Button, Checkbox, HR, Avatar } from 'common';
import { Row, Container, Text } from 'common';
import { alertsTitle, frequencyTitle, medicationModsTitle } from 'utils/helper';
import { displayTime } from 'utils/time';

const EntryItem = ({
  user,
  icon,
  text,
  preInfos,
  appointments,
  frequency,
  alerts,
  mods,
  quantity,
  alertsType,
  postInfos,
  selected,
  onSelect,
  onRemove,
  onEdit,
  noAlerts,
  forCareTeam,
  handleRemoveSubItem,
  ...props
}) => {
  const frequencyTitles = frequencyTitle(frequency, true);
  return (
    <Row modifiers={['spaceBetween']} {...props}>
      {onSelect && (
        <Column onClick={onSelect}>
          <Checkbox dark={true} checked={selected} onChange={() => {}} />
        </Column>
      )}
      <Column modifiers={['col']}>
        <Container modifiers={['card']}>
          <Row modifiers={['spaceBetween', 'middle']}>
            {user ? (
              <Avatar
                modifiers={['squared']}
                user={user}
                width={40}
                height={40}
              />
            ) : (
              <img src={icon} width={20} height={20} />
            )}
            <Column modifiers={['col']}>
              <Text modifiers={['medium', 'preserveLineBreak', 'block']}>
                {text}
              </Text>
            </Column>
            {onRemove && (
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={onRemove}
              />
            )}
          </Row>
          <HR />
          {preInfos &&
            preInfos.map((info, index) => (
              <Row modifiers={['smallGutters']} key={index}>
                <Text modifiers={[!info.value && 'muted', 'medium']}>
                  {info.title}:{' '}
                </Text>
                {info.value ? (
                  <Text modifiers={['primary', 'medium']}>{info.value}</Text>
                ) : (
                  <Text modifiers={['muted', 'medium']}>-</Text>
                )}
              </Row>
            ))}
          {!forCareTeam && (
            <Row modifiers={['smallGutters']}>
              <Text modifiers={[!frequency && 'muted', 'medium']}>Freq: </Text>
              {frequencyTitles?.frequency ? (
                <Text modifiers={['primary', 'medium']}>
                  {frequencyTitles.frequency}
                </Text>
              ) : (
                <Text modifiers={['muted', 'medium']}>-</Text>
              )}
            </Row>
          )}
          {!forCareTeam && mods && (
            <Row modifiers={['smallGutters']}>
              <Text modifiers={['medium']}>Mods: </Text>
              <Text modifiers={['primary', 'medium']}>
                {medicationModsTitle(mods)}
              </Text>
            </Row>
          )}
          {!forCareTeam && (
            <Row modifiers={['smallGutters']}>
              <Text modifiers={[!frequency && 'muted', 'medium']}>On: </Text>
              {frequencyTitles?.on ? (
                <Text modifiers={['primary', 'medium']}>
                  {frequencyTitles.on}
                </Text>
              ) : (
                <Text modifiers={['muted', 'medium']}>-</Text>
              )}
            </Row>
          )}
          {!forCareTeam && (
            <Row modifiers={['smallGutters']}>
              <Text modifiers={[!frequency && 'muted', 'medium']}>@: </Text>
              {frequencyTitles?.at ? (
                <Text modifiers="primary">{frequencyTitles.at}</Text>
              ) : (
                <Text modifiers={['muted', 'medium']}>-</Text>
              )}
            </Row>
          )}
          {!forCareTeam && quantity && (
            <Row modifiers={['smallGutters']}>
              <Text modifiers={['medium']}>Qty: </Text>
              <Text modifiers={['primary', 'medium']}>{quantity}</Text>
            </Row>
          )}
          {appointments?.map((appointment, index) => (
            <Row
              modifiers={['smallGutters', 'spaceBetween', 'middle']}
              key={index}
            >
              <Text modifiers={['medium']}>
                Appointment:{' '}
                <Text modifiers={['blue', 'medium']}>
                  {displayTime(appointment.time, 'MM/DD/YY @hh:mm A')}
                </Text>
              </Text>
              <Button
                modifiers={['icon', 'transparent']}
                width={20}
                height={20}
                image={assets.icons.icCloseWhite}
                onClick={() => handleRemoveSubItem(user, index)}
              />
            </Row>
          ))}
          {!forCareTeam && (
            <Row
              modifiers={['smallGutters', noAlerts ? 'end' : 'spaceBetween']}
            >
              {!noAlerts && (
                <Row>
                  <Text modifiers={[!alerts && 'muted', 'medium']}>
                    Alerts:{' '}
                  </Text>
                  <Column modifiers={['col', 'noPadding']}>
                    {alerts ? (
                      <Text
                        modifiers={['primary', 'medium', 'preserveLineBreak']}
                      >
                        {alertsTitle(alerts, alertsType)}
                      </Text>
                    ) : (
                      <Text modifiers={['muted', 'medium']}>-</Text>
                    )}
                  </Column>
                </Row>
              )}
              {onEdit && !postInfos && (
                <Button
                  modifiers={['transparent', 'noShadow']}
                  onClick={onEdit}
                >
                  <Text modifiers={['medium', 'primary']}>
                    {forCareTeam
                      ? 'Add Follow Up Appointments >'
                      : 'Add instructions'}
                  </Text>
                </Button>
              )}
            </Row>
          )}
          {forCareTeam && (
            <Container modifiers={['fluid', 'topGutters_1']}>
              {alerts && Object.keys(alerts).length > 0 && (
                <Row>
                  <Text modifiers={[!alerts && 'muted', 'medium']}>
                    Alerts:{' '}
                  </Text>
                  <Column modifiers={['col', 'noPadding']}>
                    <Text
                      modifiers={['primary', 'medium', 'preserveLineBreak']}
                    >
                      {alertsTitle(alerts, alertsType)}
                    </Text>
                  </Column>
                </Row>
              )}
              {onEdit && (
                <Row modifiers={['center']}>
                  <Button
                    modifiers={['transparent', 'noShadow']}
                    onClick={onEdit}
                  >
                    <Text modifiers={['medium', 'primary']}>
                      Add Follow Up Appointments &gt;
                    </Text>
                  </Button>
                </Row>
              )}
            </Container>
          )}
          {postInfos &&
            postInfos.map((info, index) => (
              <Row modifiers={['smallGutters', 'spaceBetween']} key={index}>
                <Column modifiers={'noPadding'}>
                  <Row>
                    <Text modifiers={[!info.value && 'muted', 'medium']}>
                      {info.title}:{' '}
                    </Text>
                    {info.value ? (
                      <Text modifiers={['primary', 'medium']}>
                        {info.value}
                      </Text>
                    ) : (
                      <Text modifiers={['muted', 'medium']}>-</Text>
                    )}
                  </Row>
                </Column>
                {onEdit && index === postInfos.length - 1 && (
                  <Button
                    modifiers={['transparent', 'noShadow']}
                    onClick={onEdit}
                  >
                    <Text modifiers={['medium', 'primary']}>
                      {forCareTeam
                        ? 'Add Follow Up Appointments >'
                        : 'Add instructions'}
                    </Text>
                  </Button>
                )}
              </Row>
            ))}
        </Container>
      </Column>
    </Row>
  );
};

export default buildStyledComponent(
  'EntryItem',
  styled(EntryItem),
  () => `
    > :not(:last-child) {
      margin-top: ${px2rem(25)};
    }
  `
);

import { useState, createRef } from 'react';

import { Container } from 'common';
import HeartRateAlerts from './HeartRate';
import BloodPressureAlerts from './BloodPressure';
import WeightAlerts from './Weight';
import GlucoseAlerts from './Glucose';
import BreathRateAlerts from './BreathRate';
import BloodOxygenAlerts from './BloodOxygen';
import TemperatureAlerts from './Temperature';

const VitalAlerts = ({ vital, getRef }) => {
  const [initialValues] = useState(
    Object.keys(vital.alerts).length > 0 && vital.alerts
  );
  const formRef = createRef();

  getRef.current = () => {
    return formRef.current.values;
  };
  return (
    <Container modifiers={['fullHeight', 'flexBox']}>
      {vital.id === 'heartRate' && (
        <HeartRateAlerts initialValues={initialValues} formRef={formRef} />
      )}
      {vital.id === 'bloodPressure' && (
        <BloodPressureAlerts initialValues={initialValues} formRef={formRef} />
      )}
      {vital.id === 'weight' && (
        <WeightAlerts initialValues={initialValues} formRef={formRef} />
      )}
      {vital.id === 'glucose' && (
        <GlucoseAlerts initialValues={initialValues} formRef={formRef} />
      )}
      {vital.id === 'respiratory' && (
        <BreathRateAlerts initialValues={initialValues} formRef={formRef} />
      )}
      {vital.id === 'bloodOxygen' && (
        <BloodOxygenAlerts initialValues={initialValues} formRef={formRef} />
      )}
      {vital.id === 'temperature' && (
        <TemperatureAlerts initialValues={initialValues} formRef={formRef} />
      )}
    </Container>
  );
};

export default VitalAlerts;

import React from 'react';
import styled from 'styled-components';
// import { useHistory } from 'react-router-dom';

import { buildStyledComponent, px2rem } from 'style';
import {
  Container,
  Row,
  Column,
  // Button,
  BackButton,
  Text,
} from 'common';
import { Button } from 'common';
import assets from 'assets';
import Timer from 'compositions/Timer';

export const HEADER_HEIGHT = 60;

const wrapperStyles = ({ theme }) => `
  box-shadow: 0px -2px 10px rgba(46, 41, 78, 0.08);;
  background-color: ${theme.colors.white};
  height: ${px2rem(HEADER_HEIGHT)};
`;

const HeaderWrapper = buildStyledComponent(
  'Header_Wrapper',
  styled.div,
  wrapperStyles
);

export default function Header({
  showTimer,
  children,
  handleMeet,
  isAddingToNote,
  stopAddToNote,
  handleClickBack,
}) {
  // const history = useHistory();
  // const memberInfo = members?.slice(0, 3).map((member) => ({
  //   avatar: member.photo?.url || assets.images.emptyAvatar,
  //   fullName: `${member.firstName}`,
  // }));
  // const hasMoreMembers = members?.length > 3;
  return (
    <HeaderWrapper>
      <Container modifiers={['fluid', 'noPadding']}>
        <Row modifiers={['middle']}>
          <Column modifiers={['flexBox', 'verticalContentCenter']}>
            <BackButton onClick={handleClickBack} />
          </Column>
          <Column modifiers={['col', 'fluid', 'noPadding']}>{children}</Column>
          {showTimer && (
            <Column
              modifiers={[
                'flexBox',
                'center',
                'verticalContentCenter',
                'fluid',
              ]}
            >
              <Timer />
            </Column>
          )}
          <Column modifiers={['col_3']}>
            {isAddingToNote && (
              <Button
                onClick={stopAddToNote}
                image={assets.icons.icCloseGray}
                modifiers={['icon', 'transparent']}
                style={{ marginLeft: 'auto' }}
              />
            )}
            {!isAddingToNote && (
              <Button
                onClick={handleMeet}
                modifiers={['success_2', 'roundCorner', 'fullWidth']}
              >
                <Text modifiers={['subtitle', 'white']}>Call</Text>
              </Button>
            )}
          </Column>
          {/* {showTimer && (
            <Column modifiers={['col_12', 'fluid', 'noVerticalPadding']}>
              {children}
            </Column>
          )} */}
        </Row>
      </Container>
    </HeaderWrapper>
  );
}

import { useContext } from 'react';

import { CallServiceContext } from 'contexts/call';
import { Container, Text } from 'common';
import { Button, Row } from 'common';
import { formatTitleAndName } from 'utils/string';
import assets from 'assets';

const Footer = ({ handleMinimize }) => {
  const {
    muted,
    videoMuted,
    referredPatient,
    participants,
    disconnect,
    handleMute,
    handleVideoMute,
    hasOngoingCall,
  } = useContext(CallServiceContext);

  const handleEndCall = () => {
    disconnect();
  };

  return (
    <Container modifiers={['flexBox', 'padding_2']}>
      <Row>
        <Text modifiers={['block', 'white', 'semiBold', 'medium']}>
          {formatTitleAndName(participants[0])}
        </Text>
      </Row>
      {referredPatient && (
        <Text modifiers={['white', 'block', 'medium']}>
          Ref: {formatTitleAndName(referredPatient)}
        </Text>
      )}
      <Row modifiers={['spaceBetween', 'smallGutters']}>
        <Button
          modifiers={['icon', 'transparent']}
          image={assets.icons.icCallMinimize}
          width={40}
          height={40}
          onClick={handleMinimize}
        />
        <Button
          modifiers={['icon', 'transparent']}
          image={muted ? assets.icons.icCallMicMuted : assets.icons.icCallMic}
          width={40}
          height={40}
          onClick={handleMute}
        />
        <Button
          modifiers={[
            'icon',
            'transparent',
            hasOngoingCall === 'PSTN' && 'hidden',
          ]}
          image={
            videoMuted
              ? assets.icons.icCallCameraMuted
              : assets.icons.icCallCamera
          }
          width={40}
          height={40}
          onClick={handleVideoMute}
        />
        <Button modifiers={['roundCorner', 'danger']} onClick={handleEndCall}>
          End Call
        </Button>
      </Row>
    </Container>
  );
};

export default Footer;

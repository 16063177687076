import { useState } from 'react';

import { Row, Column, ScrollView, Text, Button } from 'common';

import { Container, Tabs, Popover } from 'common';
import ActivityItem from './Item';
import assets from 'assets';

const ActivityMain = ({
  activity,
  updateActivity,
  handleAdd,
  handleEdit,
  handleShowPrevious,
  handleSave,
  handleClose,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const onRemove = () => {
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setShowDeleteModal(false);
    updateActivity(null);
  };

  const selectTab = (tab) => {
    if (tab === 1) {
      handleShowPrevious();
    }
  };

  return (
    <Container modifiers={['fluid', 'flexBox', 'fullHeight']}>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Column modifiers={['col']}>
            <Text modifiers={['bold', 'block']}>Activity</Text>
            <Text modifiers={['muted', 'medium', 'block']}>
              Create activity care plan (CP)
            </Text>
          </Column>
          <Button
            modifiers={['success', 'roundCorner', 'widthXSmall', 'noShadow']}
            onClick={handleAdd}
          >
            + ADD
          </Button>
        </Row>
      </Container>
      <Container
        modifiers={[
          'flexBox',
          'flexOne',
          'fullHeight',
          'backgroundWhite',
          'fluid',
        ]}
      >
        <Container modifiers={['flexFixed', 'topGutters_1']}>
          <Tabs current={activeTab} onChange={selectTab} noSeperator>
            <Tabs.Tab
              title="Recently added"
              modifiers={['xSmallPadding', 'noTab']}
            />
            <Tabs.Tab title="Previous activity" modifiers={['xSmallPadding']} />
          </Tabs>
        </Container>
        <ScrollView>
          {activity ? (
            <ActivityItem
              activity={activity}
              onRemove={onRemove}
              onEdit={handleEdit}
            />
          ) : (
            <Row modifiers={['fullHeight', 'middle', 'center']}>
              <Container>
                <img src={assets.icons.icNoActivity} />
                <Row modifiers={['withGutters']}>
                  <Text>
                    There are no care plan activity tasks. Please select from
                    previous ativity plans or press add to create a new activity
                    plan.
                  </Text>
                </Row>
              </Container>
            </Row>
          )}
        </ScrollView>
      </Container>
      <Container modifiers={['flexFixed', 'footer', 'backgroundWhite']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Button
            image={assets.icons.icCloseGray}
            modifiers={['icon', 'transparent']}
            onClick={handleClose}
          />
          <Button
            modifiers={[
              !activity?.frequency && 'disabled',
              'widthMedium',
              'roundCorner',
            ]}
            disabled={!activity?.frequency}
            onClick={handleSave}
          >
            Save
          </Button>
        </Row>
      </Container>
      <Popover
        open={showDeleteModal}
        onConfirm={onConfirmDelete}
        onClose={() => setShowDeleteModal(false)}
        footer
      >
        <Text modifiers={['block', 'center']}>
          Are you sure want to delete this activity?
        </Text>
      </Popover>
    </Container>
  );
};

export default ActivityMain;

import { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { startCase } from 'lodash';

import { ScrollView, Row, Column, Text, Button } from 'common';
import assets from 'assets';
import { Container, TimeLabel } from 'common';
import Rating from './Rating';
import Tabs from '../Tabs';
import IconWrapper from '../IconWrapper';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import { checkTaskDue, hourToDate, toLocalTime } from 'utils/time';
import { WELLNESS_ITEMS, DIET_ITEMS } from 'utils/constants';
import { loadingVar } from 'graphql/cache';

const RATING_OPTIONS = {
  badGood: ['veryBad', 'bad', 'medium', 'good', 'veryGood'],
  lowHigh: ['veryLow', 'low', 'medium', 'high', 'veryHigh'],
  highLow: ['veryHigh', 'high', 'medium', 'low', 'veryLow'],
};

export default ({ type }) => {
  const [tab, setTab] = useState(null);
  const { tasks, addResponse, responses, goBack } =
    useContext(PatientHomeContext);
  const hours = tasks ? Object.keys(tasks[type]) : [];
  const [inputs, updateInputs] = useState({});
  const isTaskDue =
    tab !== null && hours.length > 0 ? checkTaskDue(hours[tab]) : null;
  const questions =
    tab !== null && tasks && hours.length > 0 ? tasks[type][hours[tab]] : [];
  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    if (!tasks || tab !== null) {
      return;
    }
    setTab(0);
  }, [tasks]);

  const addAnswer = async () => {
    if (!tasks) {
      return;
    }

    loadingVar(true);
    await addResponse(type, hourToDate(hours[tab]), inputs);
    loadingVar(false);
    goBack();
  };

  useEffect(() => {
    if (!tasks || !responses || questions.length === 0) {
      updateInputs({});
      return;
    }
    const hour = new Date(hourToDate(hours[tab])).getTime() / 1000;
    const inputs = {};
    let hasResponse = false;
    questions.forEach((question) => {
      const questionResponse = responses.find(
        (response) =>
          response.measure === type &&
          toLocalTime(response.time).unix() === hour &&
          response.response.type === question.id
      );
      if (!questionResponse) {
        return;
      }
      inputs[questionResponse.response.type] = questionResponse.response.value;
      if (!!questionResponse.response.value) {
        hasResponse = true;
      }
    });
    updateInputs({ ...inputs });
    if (isTaskDue && !hasResponse) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
  }, [responses, tab, tasks]);

  const handleSelect = (id, value) => {
    inputs[id] = value;
    updateInputs({ ...inputs });
  };

  const handleEdit = () => {
    setCanEdit(true);
  };

  return (
    <Container modifiers={['flexBox', 'fullHeight', 'fluid']}>
      <Container modifiers={['flexOne', 'fluid', 'fullHeight', 'flexBox']}>
        <Container modifiers={['flexFixed']}>
          <Tabs>
            <Tabs.Header>
              <Text modifiers={['white', 'subtitle']}>{startCase(type)}</Text>
              <Text modifiers={['primary', 'subtitle', 'semiBold']}>
                {isTaskDue === false ? (
                  <Text modifiers={['danger', 'subtitle', 'semiBold']}>
                    Not Due - {hours[tab]}, {moment().format('MM/DD/YYYY')}
                  </Text>
                ) : (
                  <TimeLabel />
                )}
              </Text>
            </Tabs.Header>
            <Row>
              {hours.map((hour, index) => (
                <Tabs.Tab
                  key={index}
                  onClick={() => setTab(index)}
                  modifiers={[tab === index && 'active']}
                >
                  {hour}
                </Tabs.Tab>
              ))}
            </Row>
          </Tabs>
        </Container>
        <ScrollView>
          {questions.map((question, i) => {
            const questionItem =
              type === 'wellness'
                ? WELLNESS_ITEMS[question.id]
                : DIET_ITEMS[question.id];
            return (
              <Column modifiers={['col_12']} key={i}>
                <Row modifiers={['middle']}>
                  <Column modifiers={['col_2', 'flexBox', 'center']}>
                    <IconWrapper>
                      <img
                        src={questionItem.icon}
                        alt=""
                        width="18"
                        height="18"
                      />
                    </IconWrapper>
                  </Column>
                  <Column modifiers={['col_10', 'fluid']}>
                    <Text modifiers={['start', 'subtitle', 'block']}>
                      {questionItem.title}
                    </Text>
                  </Column>
                  <Column modifiers={['fluid', 'col_12']}>
                    <Rating
                      options={RATING_OPTIONS[questionItem.type || 'badGood']}
                      value={inputs[question.id]}
                      disabled={!isTaskDue || !canEdit}
                      handleSelect={(value) => handleSelect(question.id, value)}
                    />
                  </Column>
                </Row>
              </Column>
            );
          })}
        </ScrollView>
      </Container>
      <Container modifiers={['footer', 'flexFixed', 'fluid']}>
        <Row modifiers={['spaceBetween']}>
          <Column modifiers={['col_6', 'flexBox', 'verticalContentCenter']}>
            <Button
              image={assets.icons.icCloseGrey}
              width={22}
              height={22}
              modifiers={['icon', 'transparent']}
              onClick={() => goBack()}
            />
          </Column>
          <Column modifiers={['col_5', 'col_offset_1', 'flexBox', 'center']}>
            <Button
              modifiers={[
                !isTaskDue && 'disabled',
                'widthSmall',
                'roundCorner',
              ]}
              onClick={isTaskDue && canEdit ? addAnswer : handleEdit}
            >
              <Text modifiers={['subtitle', 'white', 'semiBold']}>
                {!isTaskDue || canEdit ? 'Done' : 'Edit'}
              </Text>
            </Button>
          </Column>
        </Row>
      </Container>
    </Container>
  );
};

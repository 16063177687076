import { useState, useEffect } from 'react';

import {
  Row,
  Column,
  Checkbox,
  ScrollView,
  Text,
  Button,
  BackButton,
} from 'common';
import { Container, Modal } from 'common';
import assets from 'assets';
import { VITALS } from 'utils/constants';
import { getVitalTypeAttr } from 'utils/helper';

const AddVitals = ({ vitals, handleAdd, handleClose }) => {
  const [selectedVitals, updateSelectedVitals] = useState({});

  useEffect(() => {
    Object.entries(vitals).forEach(([key, vital]) => {
      if (vital.active) {
        selectedVitals[key] = { frequency: null, alerts: null };
      }
    });
    updateSelectedVitals({ ...selectedVitals });
  }, [vitals]);

  const handleDone = () => {
    handleAdd(selectedVitals);
    handleClose();
  };

  const toggleVitalSelection = (vital) => {
    selectedVitals[vital]
      ? delete selectedVitals[vital]
      : (selectedVitals[vital] = { frequency: null, alerts: null });
    updateSelectedVitals({ ...selectedVitals });
  };

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle']}>
          <BackButton onClick={handleClose} />
          <Text>Add CP Vitals</Text>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
          <Container modifiers={['padding_2']}>
            <Text modifiers={['bold', 'medium']}>Select CP Vitals</Text>
          </Container>
          <ScrollView>
            <Container>
              {Object.entries(VITALS).map(([key, name]) => (
                <Row
                  onClick={() => toggleVitalSelection(key)}
                  modifiers={['spaceBetween']}
                >
                  <Column modifiers={'col'}>
                    <Row>
                      <img src={getVitalTypeAttr(key).icon} />
                      <Text modifiers={'block'}>{name}</Text>
                    </Row>
                  </Column>
                  <Checkbox
                    dark={true}
                    modifiers={['small', 'margin_1']}
                    checked={selectedVitals[key]}
                    onChange={() => {}}
                  />
                </Row>
              ))}
            </Container>
          </ScrollView>
          <Container modifiers={['flexFixed', 'footer']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={handleClose}
              />
              <Button
                modifiers={[
                  'primary',
                  'widthSmall',
                  'roundCorner',
                  Object.keys(selectedVitals).length === 0 && 'disabled',
                ]}
                onClick={handleDone}
              >
                Add Selected
              </Button>
            </Row>
          </Container>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default AddVitals;

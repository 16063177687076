import { useContext, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

import { Container } from 'common';
import { CreateCareplanContext } from '../Context';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import Main from './Main';
import PreviousVitals from './PreviousVitals';
import AddVitals from './AddVitals';
import { VITALS } from 'utils/constants';

const VitalIndex = ({}) => {
  const { setFooterVisible, careplan, updateCareplan } = useContext(
    CreateCareplanContext
  );
  const { goBack } = useContext(PatientHomeContext);
  const [vitals, updateVitals] = useState(() => {
    const vitalObject = {};
    Object.keys(VITALS).forEach((vital) => {
      vitalObject[vital] = {
        id: vital,
        active: false,
        frequency: null,
        alerts: null,
      };
    });
    return vitalObject;
  });
  const [activeAction, updateActiveAction] = useState();

  useEffect(() => {
    setFooterVisible(false);
    return () => {
      setFooterVisible(true);
    };
  }, []);

  useEffect(() => {
    if (careplan.content.vital) {
      Object.entries(careplan.content.vital).forEach(([key, vital]) => {
        vitals[key].active = true;
        vitals[key].frequency = cloneDeep(vital.frequency);
        vitals[key].alerts = cloneDeep(vital.alerts);
      });
      updateVitals({ ...vitals });
    }
  }, [careplan]);

  const handleAddVitals = (newVitals) => {
    Object.entries(newVitals).forEach(([key, vital]) => {
      if (vitals[key].active && !vital.frequency) {
        return;
      }
      vitals[key].active = true;
      vitals[key].frequency = vital.frequency;
      vitals[key].alerts = vital.alerts;
    });
    updateVitals({ ...vitals });
  };

  const handleAdd = () => {
    updateActiveAction('add');
  };
  const handleShowPrevious = () => {
    updateActiveAction('previous');
  };

  const handleSave = () => {
    const activeVitals = {};
    Object.keys(vitals).forEach((vitalId) => {
      if (!vitals[vitalId].active) {
        return;
      }
      activeVitals[vitalId] = {
        frequency: vitals[vitalId].frequency,
        alerts: vitals[vitalId].alerts,
      };
    });
    careplan.content.vital = activeVitals;
    updateCareplan({ ...careplan });
    goBack();
  };

  return (
    <Container modifiers={['fluid', 'fullHeight']}>
      <Main
        vitals={vitals}
        handleAdd={handleAdd}
        handleShowPrevious={handleShowPrevious}
        handleSave={handleSave}
        handleClose={() => goBack()}
        updateVitals={updateVitals}
      />
      {activeAction === 'add' && (
        <AddVitals
          vitals={vitals}
          handleAdd={handleAddVitals}
          handleClose={() => {
            updateActiveAction(null);
          }}
        />
      )}
      {activeAction === 'previous' && (
        <PreviousVitals
          handleAdd={handleAddVitals}
          handleClose={() => {
            updateActiveAction(null);
          }}
        />
      )}
    </Container>
  );
};

export default VitalIndex;

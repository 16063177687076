import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Tabs, Button, Row, Column, Text, ScrollView, Container } from 'common';

import { PeriodPicker } from 'common';
import { ListItem } from './Item';
import assets from 'assets';
import { buildStyledComponent, px2rem } from 'style';

const OrdersList = ({
  allOrders,
  handleOrderClick,
  handleSelectPeriod,
  hide,
  selectedPeriod,
  updateSelectedPeriod,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const orders = useMemo(() => {
    switch (activeTab) {
      case 0:
        return allOrders.orders;
      case 1:
        return allOrders.processing;
      case 2:
        return allOrders.status;
    }
    return [];
  }, [activeTab, allOrders]);

  const handleUpdateTab = (tab) => {
    setActiveTab(tab);
  };

  const handlePeriod = (value) => {
    updateSelectedPeriod(value);
    handleSelectPeriod(value);
  };

  return (
    <Container
      modifiers={['fluid', 'flexBox', 'fullHeight', hide && 'noDisplay']}
      {...props}
    >
      <Tabs current={activeTab} onChange={handleUpdateTab}>
        <Tabs.Tab title={`Orders(${allOrders.orders.length})`} />
        <Tabs.Tab title={`Processing(${allOrders.processing.length})`} />
        <Tabs.Tab title={`Status(${allOrders.status.length})`} />
      </Tabs>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Column modifiers={['col']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Text modifiers={['bold']}>Ordering Provider</Text>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icSort}
                imageWidth={30}
                imageHeight={30}
              />
            </Row>
          </Column>
          <Column modifiers={['noPadding']}>
            <PeriodPicker
              value={selectedPeriod}
              handleSelect={handlePeriod}
              icon={assets.icons.iconFilter}
            />
          </Column>
        </Row>
      </Container>
      <ScrollView>
        <Container>
          {orders.length > 0 ? (
            orders.map((order, index) => (
              <ListItem
                key={index}
                order={order}
                handleClick={() => handleOrderClick(order)}
              />
            ))
          ) : (
            <Text modifiers={['center', 'block']}>No orders</Text>
          )}
        </Container>
      </ScrollView>
    </Container>
  );
};

export default buildStyledComponent(
  'OrdersList',
  styled(OrdersList),
  ({ theme }) => `
    > :last-child {
      > :first-child {
        background: ${theme.colors.white};
        box-shadow: ${theme.dimensions.widgetBoxShadow};
        border-radius: ${theme.dimensions.borderRadius_2};
        padding: ${theme.dimensions.padding_1};
        margin: 0;

        >div:last-child >:last-child {
          display: none;
        }
      }
    }
  `
);

import React, { useContext, useState, useRef } from 'react';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import { Tabs, Row, Button, Container } from 'common';
import { UPDATE_PATIENT } from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';

import { PatientHomeContext } from '../Context';
import Info from './Info';
import Devices from './Devices';
import Contact from './Contact';

const Profile = () => {
  const { patient } = useContext(PatientHomeContext);
  const [updatePatient, { loading }] = useMutation(UPDATE_PATIENT);
  const [tab, setTab] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const profileRef = useRef();
  const devicesRef = useRef();
  const contactRef = useRef();

  loadingVar(loading);

  const handleClick = () => {
    if (isEditing) {
      update();
    } else {
      setIsEditing(true);
    }
  };

  const handleTabChange = (newTab) => {
    setTab(newTab);
  };

  const update = async () => {
    const refs = [profileRef, devicesRef, contactRef];
    let formData = {};

    for (let i = 0; i < 3; i++) {
      await refs[i].current.submitForm();
      if (!refs[i].current.isValid) {
        setTab(i);
        return;
      }

      formData = {
        ...formData,
        ...refs[i].current.values,
      };
    }

    const userKeys = [
      'firstName',
      'lastName',
      'middleName',
      'email',
      'phones',
      'photo',
      'address',
    ];
    updatePatient({
      variables: {
        id: patient._id,
        patient: {
          ..._.omit(formData, userKeys),
          user: _.pick(formData, userKeys),
        },
      },
    });

    setIsEditing(false);
  };

  if (!patient) {
    return null;
  }

  return (
    <>
      <Tabs current={tab} onChange={handleTabChange}>
        <Tabs.Tab title="Profile" />
        <Tabs.Tab title="Devices" />
        <Tabs.Tab title="Contact" />
      </Tabs>
      <Container modifiers="flexOne" style={{ overflowY: 'auto' }}>
        <Info
          ref={profileRef}
          patient={patient}
          isEditing={isEditing}
          show={tab === 0}
        />
        <Devices
          ref={devicesRef}
          patient={patient}
          isEditing={isEditing}
          show={tab === 1}
        />
        <Contact
          ref={contactRef}
          patient={patient}
          isEditing={isEditing}
          show={tab === 2}
        />
      </Container>
      <Container modifiers={['flexFixed', 'shadow']}>
        <Row modifiers={['spaceAround']}>
          <Button
            onClick={handleClick}
            modifiers={['primary', isEditing && 'outlinePrimary', 'noShadow']}
          >
            {isEditing ? 'Save' : 'Edit'}
          </Button>
        </Row>
      </Container>
    </>
  );
};

export default Profile;

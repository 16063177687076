import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';
import { BackButton, Text } from 'common';

const styles = ({ theme }) => `
  background-color: ${theme.colors.white};
  padding: ${theme.dimensions.padding_1};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 0 -2px 10px rgba(46, 41, 78, 0.08);
  margin-bottom: 2px;

  >:first-child, >:last-child {
    min-width: ${px2rem(45)};
  }

  >:nth-child(2) {
    flex: 1;
  }
`;

export default buildStyledComponent(
  'SimpleLayout_Header',
  styled(({ handleBack, content, rightComponent, ...props }) => (
    <div {...props}>
      <BackButton onClick={handleBack} />
      {typeof content === 'string' ? (
        <Text modifiers={['center', 'medium', 'bold']}>{content}</Text>
      ) : (
        content
      )}
      <div>{rightComponent}</div>
    </div>
  )),
  styles
);

import React, { useContext } from 'react';

import { BackButton } from 'common';

import { PatientHomeContext } from './Context';

export default () => {
  const { goBack } = useContext(PatientHomeContext);

  return <BackButton onClick={goBack} />;
};

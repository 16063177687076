import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router';
import { noop } from 'lodash-es';
import { Row, Column, Text, Avatar } from 'common';

import { formatMemberDesignation, getFullName } from 'utils/string';
import { FormInput } from 'common';
import { buildStyledComponent } from 'style';
import assets from 'assets';

import AvatarWrapper from './AvatarWrapper';

const modifierConfig = {
  open: () => `
    transform: translateY(60%);
    transition: 0.3s;
  `,
};

const styles = () => `
  transform: translateY(-60%);
  background-color: red;
  transition: 0.3s;
  position: absolute;
  z-index: 1;
  padding: 10px;
  padding-top: 15px;
  height: 200px;
  width: 250px;
  background-color: white;
  overflow: auto;
  box-shadow: 0px 1px 4px rgba(46, 41, 78, 0.02), 0px 8px 12px rgba(46, 41, 78, 0.08);
`;

const GroupDetails = buildStyledComponent(
  'Participant_GroupDetails',
  styled(Column),
  styles,
  { modifierConfig }
);

const Participants = ({ members }) => {
  const history = useHistory();
  const [openDetails, setOpenDetails] = useState(false);

  const handleClickMember = (member) => {
    if (member.role === 'provider') {
      /* not sure if its the correct approach when clicking a provider being a provider */
      history.push(`/contacts/${member._id}`);
    } else {
      history.push(`/patients/${member._id}`);
    }
  };
  return (
    <Row
      style={{
        backgroundColor: openDetails ? '#F3F3F4' : 'white',
      }}
      modifiers={['middle']}
      onClick={members.length > 1 ? () => setOpenDetails(!openDetails) : noop}
    >
      {openDetails && (
        <GroupDetails modifiers={[openDetails && 'open']}>
          {members.map((m, i) => (
            <Row
              modifiers="middle"
              key={`member-details-${i}`}
              onClick={() => handleClickMember(m)}
            >
              <Column>
                <Avatar user={m} width={36} height={36} modifiers={['round']} />
              </Column>
              <Column modifiers={['col']}>
                <Text modifiers={['subtitle', 'light', 'noPadding']}>
                  {m.firstName}
                  <br />
                  {m.lastName}
                  {m.role === 'provider' &&
                    `, ${formatMemberDesignation(m.memberDesignation)}`}
                </Text>
              </Column>
              <Column
                modifiers={[
                  'col_2',
                  'flexBox',
                  'center',
                  'verticalContentCenter',
                ]}
              >
                <img
                  alt=""
                  src={assets.icons.icChevronRight}
                  width="16"
                  height="16"
                />
              </Column>
            </Row>
          ))}
          <div style={{ paddingTop: 5 }}>
            <Formik>
              {() => (
                <Form>
                  <Field
                    hasLabel
                    largeLabel
                    label="Group Name"
                    placeholder="Enter Group Name"
                    modifiers={['squared']}
                    component={FormInput}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </GroupDetails>
      )}
      <AvatarWrapper>
        {members.map((m, i) => (
          <AvatarWrapper.Content
            membersAmount={members.length}
            key={`messy-code-${i}`}
          >
            <Avatar user={m} width={30} height={30} modifiers={['round']} />
            {members.length === 1 && (
              <Column modifiers={['col', 'noPadding']}>
                <Text
                  modifiers={[
                    'subtitle',
                    'semiBold',
                    'noPadding',
                    'block',
                    'start',
                  ]}
                >
                  {m.firstName}
                  <br />
                  {m.lastName}
                  {m.role === 'provider' &&
                    `, ${formatMemberDesignation(m.memberDesignation)}`}
                </Text>
              </Column>
            )}
          </AvatarWrapper.Content>
        ))}
      </AvatarWrapper>
    </Row>
  );
};

export default Participants;

import React from 'react';
import { useHistory } from 'react-router';

import { Row, Column, HR, Avatar } from 'common';
import { Container, Text } from 'common';
import { formatTitleAndName } from 'utils/string';
import { displayTime } from 'utils/time';

export default ({ careplan }) => {
  const { push, location } = useHistory();
  return (
    <Container
      onClick={() => push(`${location.pathname}/${careplan._id}`)}
      modifiers={['card', 'withGutters', 'padding_2']}
    >
      <Row modifiers={['middle', 'spaceBetween']}>
        <Avatar
          modifiers={['round']}
          user={careplan.creator.user}
          width={50}
          height={50}
        />
        <Column modifiers={['col']}>
          <Row>
            <Text
              modifiers={[
                !careplan.isSeen && !careplan.isDraft && 'bold',
                'block',
              ]}
            >
              {formatTitleAndName(careplan.creator.user)}
            </Text>
          </Row>
          <Row>
            <Text
              modifiers={[
                'block',
                !careplan.isSeen && !careplan.isDraft && 'bold',
              ]}
            >
              {displayTime(careplan.signDate, 'hh:mm a, MM/DD/yy')}
            </Text>
          </Row>
        </Column>
      </Row>
      <HR modifiers={['gray']} />
      <Row modifiers={['topGutters_1']}>
        <Text modifiers={[!careplan.isDraft && !careplan.isSeen && 'bold']}>
          Reference:{' '}
          <Text modifiers={['primary']}>
            {formatTitleAndName(careplan.user)}
          </Text>
        </Text>
      </Row>
    </Container>
  );
};

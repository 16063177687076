import React, { useEffect, useCallback, useState } from 'react';
import { debounce, noop } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import {
  Container,
  Row,
  Text,
  SearchBar,
  Avatar,
  Button,
  // Checkbox,
  Ribbon,
} from 'common';
import { SlidingPane, Checkbox, Column as Col, Modal } from 'common';

import { FETCH_CONTACTS } from 'graphql/queries';
import { SEARCH_DEBOUNCE_TIME } from 'utils/constants';
import assets from 'assets';

import SelectedParticipantCard from './SelectedParticipantCard';

export default function AddParticipant({
  existingMemberIds,
  hasPatient,
  handleBack,
  handleDone = noop,
}) {
  const [fetchContacts, { data: { contacts = [] } = {}, loading }] =
    useLazyQuery(FETCH_CONTACTS);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const memberLeads = contacts.filter(
    (c) => !existingMemberIds.includes(c._id)
  );

  const handleSearch = useCallback(
    debounce((query) => {
      fetchContacts({
        variables: {
          query,
          category: hasPatient ? 'providers' : 'providersPatients',
        },
      });
    }, SEARCH_DEBOUNCE_TIME),
    [fetchContacts]
  );

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const handleClickItem = (record) => {
    if (selectedParticipants.find((selected) => selected._id === record._id)) {
      setSelectedParticipants((alreadySelected) => {
        const newState = alreadySelected.filter(
          (selected) => selected._id !== record._id
        );
        return newState;
      });
    } else {
      const hasPatientAlready = selectedParticipants.some(
        (p) => p.role === 'patient'
      );
      if (hasPatientAlready && record.role === 'patient') {
        Modal.info({
          render: () => (
            <Modal.Body>
              <Row modifiers={['middle', 'center']}>
                <Col modifiers={['col_12']}>
                  <Text modifiers={['secondaryColor', 'bold']}>
                    It is not allowed to talk to multiple patients at the same
                    time.
                  </Text>
                </Col>
                <Col modifiers={['col_12', 'flexBox', 'center']}>
                  <Button
                    image={assets.icons.icCloseGray}
                    modifiers={['icon', 'transparent']}
                  />
                </Col>
              </Row>
            </Modal.Body>
          ),
        });
        return;
      }
      setSelectedParticipants((alreadySelected) => [
        ...alreadySelected,
        record,
      ]);
    }
  };

  const handleAdd = () => {
    if (selectedParticipants.length > 0) {
      handleDone(selectedParticipants);
    }
  };

  const isChecked = (participant) => {
    const findParticipant = selectedParticipants.findIndex(
      (selected) => selected._id === participant._id
    );
    return findParticipant > -1;
  };

  return (
    <SlidingPane
      isOpen
      handleClose={handleBack}
      modifiers={['noPadding', 'fillHeight']}
    >
      <SlidingPane.Header
        style={{ background: '#F0F0F1' }}
        modifiers={['noPadding']}
      >
        <Button onClick={handleBack} modifiers={['link']}>
          <Text modifiers={['primary', 'light', 'noPadding']}>Cancel</Text>
        </Button>
        <Col>
          <Text modifiers={['semiBold', 'block']}>Select Contact</Text>
          <Text modifiers={['light', 'block', 'center']}>
            {selectedParticipants.length}/{memberLeads.length}
          </Text>
        </Col>
        <Button onClick={handleAdd} modifiers={['link']}>
          <Text modifiers={['primary', 'light', 'noPadding']}>Done</Text>
        </Button>
      </SlidingPane.Header>
      <Col style={{ background: '#F0F0F1' }} modifiers={['col_12']}>
        <SearchBar onChange={handleSearch} />
      </Col>
      <SlidingPane.Content modifiers={['noPadding', 'overflow']}>
        {selectedParticipants.length > 0 && (
          <Container>
            <Row>
              {selectedParticipants.map((participant) => (
                <Col
                  modifiers={[
                    'col_3',
                    'flexBox',
                    'flexColumn',
                    'center',
                    'verticalContentCenter',
                  ]}
                  key={participant._id}
                  onClick={() => handleClickItem(participant)}
                >
                  <Ribbon top="5px" right="15px">
                    <img
                      alt=""
                      src={assets.icons.icCloseWhite}
                      width="16"
                      height="16"
                    />
                  </Ribbon>
                  <Avatar
                    user={participant}
                    width={42}
                    height={42}
                    modifiers={['round']}
                  />
                  <Text modifiers={['center', 'subtitle', 'darkGrey', 'block']}>
                    {`${participant.firstName} ${participant.lastName}`}
                  </Text>
                </Col>
              ))}
            </Row>
          </Container>
        )}
        <Container style={{ background: '#D2D2D2' }}>
          <Row>
            <Text modifiers={['h3', 'darkGrey']}>Search Results</Text>
          </Row>
        </Container>
        <Container style={{ maxHeight: '350px', overflowY: 'auto' }}>
          {memberLeads.map((contact, i) => (
            <Row
              key={`${contact._id}-${i}`}
              onClick={() => handleClickItem(contact)}
            >
              <Col modifiers={['col_2']}>
                <Avatar
                  user={contact}
                  width={42}
                  height={42}
                  modifiers={['round']}
                />
              </Col>
              <Col modifiers={['col_8', 'flexBox', 'verticalContentCenter']}>
                <Text
                  modifiers={['subtitle', 'darkGrey']}
                >{`${contact.firstName} ${contact.lastName}`}</Text>
              </Col>
              <Col modifiers={['col_2', 'flexBox', 'verticalContentCenter']}>
                <Checkbox
                  checked={isChecked(contact)}
                  onChange={noop}
                  modifiers={['radioStyle', 'widthSmall']}
                />
              </Col>
            </Row>
          ))}
        </Container>
      </SlidingPane.Content>
    </SlidingPane>
  );
}

import React from 'react';
import Card from './Card';
import Wrapper from './Wrapper';
import Badge from './Badge';
import { Text } from 'common';

export const Widget = ({ icon, title, hasBadge, badgeCount, ...props }) => (
  <Wrapper {...props}>
    <Card>
      {hasBadge && <Badge>{badgeCount}</Badge>}
      <img
        style={{ objectFit: 'contain', maxHeight: '100%', maxWidth: '100%' }}
        src={icon}
        alt=""
      />
    </Card>
    <Text modifiers={['small']}>{title}</Text>
  </Wrapper>
);

import React, { useState, useContext } from 'react';

import { Container } from 'common';
import { MainLayout } from 'layouts';
import { AuthContext } from 'contexts/auth';
import urls from 'routes/urls';

import Header from './Header';
import Content from './Content';

export default function Onboard({ history }) {
  const { updateAuth } = useContext(AuthContext);
  const [step, setStep] = useState(0);

  const goNext = () => setStep(step + 1);

  const goBack = () => setStep(step - 1);

  const resetStep = async () => {
    await updateAuth();

    history.replace(urls.AUTH);
  };

  return (
    <MainLayout>
      <MainLayout.Content style={{ backgroundColor: 'white' }}>
        <Container modifiers="fluid">
          <Header step={step} goBack={goBack} resetStep={resetStep} />
          <Content step={step} goNext={goNext} />
        </Container>
      </MainLayout.Content>
    </MainLayout>
  );
}

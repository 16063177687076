import { useState, useMemo, useContext } from 'react';
import { ScrollView, Row, Button } from 'common';

import VitalInput from './VitalInput';
import { VITALS } from 'utils/constants';
import { Container, Text } from 'common';
import assets from 'assets';
import { CreateNoteContext } from '../Context';

export default ({ vitals, updateVitals }) => {
  const { goBack } = useContext(CreateNoteContext);
  const [inputs, updateInputs] = useState(() => {
    const values = {};
    Object.keys(VITALS).forEach((type) => {
      values[type] = vitals[type] || 0;
    });
    values.bloodPressure2 = vitals.bloodPressure2 || 0;
    return values;
  });

  const handleRemoveVital = (vitalType) => {
    inputs[vitalType] = 0;
    if (vitalType === 'bloodPressure') {
      inputs['bloodPressure2'] = 0;
    }
    updateInputs({ ...inputs });
  };

  const handleSaveVital = (type, value, value2) => {
    inputs[type] = value;
    if (type === 'bloodPressure') {
      inputs['bloodPressure2'] = value2;
    }
    updateInputs({ ...inputs });
  };

  const handleDone = () => {
    const newVitals = {};
    Object.entries(inputs).forEach(([type, value]) => {
      const refinedValue = parseInt(value, 10) > 0 ? parseInt(value, 10) : null;
      newVitals[type] = refinedValue;
    });
    updateVitals(inputs);
    goBack();
  };

  return (
    <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
      <Container style={{ background: '#F3F3F4' }} modifiers={['padding_2']}>
        <Text modifiers={['bold', 'secondary']}>Add New vital</Text>
      </Container>
      <ScrollView>
        <Container>
          {Object.keys(VITALS).map((vitalType) => (
            <VitalInput
              vitalType={vitalType}
              editable
              value={inputs[vitalType]}
              value2={vitalType === 'bloodPressure' && inputs.bloodPressure2}
              handleSave={handleSaveVital}
              handleRemove={handleRemoveVital}
            />
          ))}
        </Container>
      </ScrollView>
      <Container modifiers={['footer']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icCloseGray}
            onClick={goBack}
          />
          <Button
            modifiers={['widthSmall', 'roundCorner']}
            onClick={handleDone}
          >
            Save
          </Button>
        </Row>
      </Container>
    </Container>
  );
};

import React, { useState, useRef, forwardRef } from 'react';
import { Formik, Field, Form } from 'formik';

import { FormInput, Row, Column, Text, HR, Button, QRCodeReader } from 'common';
import assets from 'assets';

const Devices = forwardRef(({ patient, isEditing, show }, ref) => {
  const initialized = useRef(false);
  if (show) initialized.current = true;

  const [qrCodeName, setQrCodeName] = useState('');
  const initialValues = {
    bpmIMEI: patient.bpmIMEI || '',
    scaleIMEI: patient.scaleIMEI || '',
  };

  const handleScan = (data, setFieldValue) => {
    setFieldValue(qrCodeName, data);
    setQrCodeName('');
  };

  const renderRow = ({ title, name, image }) => {
    return (
      <>
        <Row>
          <Column modifiers="col_12">
            <Text modifiers={['medium', 'bold']}>{title}</Text>
          </Column>
        </Row>
        <Row modifiers="middle">
          <Column modifiers="col_3">
            <img src={image} style={{ width: '100%' }} alt={title} />
          </Column>
          <Column modifiers={['col_6', 'center']}>
            <Field
              disabled={!isEditing}
              hasLabel
              name={name}
              placeholder="IMEI"
              component={FormInput}
            />
          </Column>
          <Column modifiers={['col_3', 'center']}>
            <Button
              disabled={!isEditing}
              modifiers={['icon', 'transparent', 'block']}
              image={assets.icons.qrCode}
              imageWidth="40"
              imageHeight="40"
              onClick={() => setQrCodeName(name)}
            />
          </Column>
        </Row>
      </>
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      innerRef={ref}
      onSubmit={() => {}}
    >
      {({ setFieldValue }) =>
        initialized.current && (
          <Form style={{ display: show ? null : 'none' }}>
            {renderRow({
              title: 'Blood Pressure Monitor',
              name: 'bpmIMEI',
              image: assets.images.bloodPressure2,
              setFieldValue,
            })}
            <HR />
            {renderRow({
              title: 'Weight Scale Monitor',
              name: 'scaleIMEI',
              image: assets.images.weightScale2,
              setFieldValue,
            })}
            <QRCodeReader
              visible={!!qrCodeName}
              onScan={(data) => handleScan(data, setFieldValue)}
              onError={() => {}}
              onClose={() => setQrCodeName('')}
            />
          </Form>
        )
      }
    </Formik>
  );
});

export default Devices;

import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const Wrapper = (props) => {
  return (
    <div {...props}>
      <div></div>
    </div>
  );
};

const modifierConfig = {
  'width_95%': ({ theme }) => `
    >div {
      width: 95%;
    }
  `,
  padding_1: ({ theme }) => `
    >div {
      width: calc(100% - ${theme.dimensions.padding_1} * 2);
    }
  `,
  padding_2: ({ theme }) => `
    >div {
      width: calc(100% - ${theme.dimensions.padding_2} * 2);
    }
  `,
  primary: ({ theme }) => `
    >div {
      border-bottom-color: ${theme.colors.primary};
    }
  `,
  blue: ({ theme }) => `
    >div {
      border-bottom-color: ${theme.colors.blue};
    }
  `,
  colored: () => `
    >div {
      border-bottom-color: #B8C3DE;
    }
  `,
  muted: () => `
    > div {
      border-bottom-color: #F3F3F4;
    }
  `,
  gray: ({ theme }) => `
    >div {
      border-bottom-color: ${theme.colors.white_1}
    }
  `,
  height_3: () => `
    line-height: ${px2rem(5)};
    >div {
      border-bottom-width: ${px2rem(3)};
    }
  `,
};

const styles = ({ theme }) => `
  text-align: center;
  line-height: ${px2rem(2)};
  flex: 0;

  >div {
    display: inline-block;
    border-bottom: solid 1px ${theme.colors.gray7};
    width: 100%;
  }
`;

export const HR = buildStyledComponent('HR', styled(Wrapper), styles, {
  modifierConfig,
});

export default HR;

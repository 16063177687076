import React from 'react';
import styled from 'styled-components';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { buildStyledComponent } from 'style';
import assets from 'assets';

const chatBubbleLegModifiers = {
  left: () => `
    left: -3px;
    right: unset;
  `,
};

const chatBubbleLegStyles = () => `
  position: absolute;
  right: -3px;
  bottom: -3px;
`;

const ChatBubbleLeg = buildStyledComponent(
  'ChatBubble_Leg',
  styled.img,
  chatBubbleLegStyles,
  { modifierConfig: chatBubbleLegModifiers }
);

const TextComp = ({ isMe, children, ...props }) => (
  <div {...props}>
    <ChatBubbleLeg
      src={isMe ? assets.icons.shapeChatBubbleR : assets.icons.shapeChatBubbleL}
      alt=""
      width="17"
      height="17"
      modifiers={[!isMe && 'left']}
    />
    {children}
  </div>
);

const modifierConfigText = {
  isMe: ({ theme }) => `
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
  `,
  attachment: () => `
    background-color: #438F9F;
  `,
};

const textStyles = ({ theme }) => `
  border-radius: ${theme.dimensions.borderRadius_2};
  box-shadow: ${theme.dimensions.boxShadow_4};
  padding: ${theme.dimensions.padding_1};
  background-color: ${theme.colors.white};
  color: ${theme.colors.secondary};
  font-size: ${theme.dimensions.fontSize};
  font-family: ${theme.fonts.secondary} !important;
  max-width: 100%;
  word-wrap: break-word;
  position: relative;
  text-align: left;
`;

export const Text = buildStyledComponent(
  'ChatBubble_Text',
  styled(TextComp),
  textStyles,
  { modifierConfig: modifierConfigText }
);

const modifierConfigImage = {};

const imageStyles = ({ theme }) => `
  object-fit: contain;
  max-width: 100%;
`;

const Image = buildStyledComponent(
  'ChatBubble_Image',
  styled.img,
  imageStyles,
  { modifierConfig: modifierConfigImage }
);

const modifierConfigVideo = {};

const videoStyles = ({ theme }) => `
  object-fit: contain;
  max-width: 100%;
`;

const Video = buildStyledComponent(
  'ChatBubble_Video',
  styled.video,
  videoStyles,
  { modifierConfig: modifierConfigVideo }
);

const PDF = buildStyledComponent(
  'ChatBubble_PDF',
  styled(({ url, ...props }) => (
    <div {...props}>
      <Document file={url}>
        <Page pageNumber={1} />
      </Document>
    </div>
  )),
  () => `
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  `
);

export const ChatBubble = {
  Text,
  Image,
  Video,
  PDF,
};

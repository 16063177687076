import React from 'react';
import { useHistory } from 'react-router-dom';

import assets from 'assets';
import { Button } from 'common';

export const BackButton = ({ onClick }) => {
  const history = useHistory();

  return (
    <Button
      modifiers={['icon', 'transparent']}
      image={assets.icons.icBack}
      onClick={onClick || history.goBack}
    />
  );
};

export default BackButton;

import styled from 'styled-components';

import { buildStyledComponent } from 'style';
import { Container } from 'common';

const styles = ({ theme }) => `
  background-color: ${theme.colors.white};
  padding: ${theme.dimensions.padding_1};
  box-shadow: ${theme.dimensions.footerBoxShadow};
  flex: 0 1;
`;

export default buildStyledComponent(
  'SimpleLayout_Footer',
  styled(({ children, ...props }) => (
    <Container {...props}>{children}</Container>
  )),
  styles
);

import styled from 'styled-components';
import { Button, Column, Row, Text } from 'common';

import { buildStyledComponent, px2rem } from 'style';
import assets from 'assets';

/*
const Bullet = buildStyledComponent(
  'Bullet',
  styled(Text),
  ({ theme }) => `
    position: relative;

    ::after {
      content: '';
      position: absolute;
      width: ${px2rem(4)};
      height: ${px2rem(4)};
      top: calc(50% - ${px2rem(2)});
      left: 0;
      background: ${theme.colors.text};
    }
  `,
  {
    modifierConfig: {
      editable: ({ theme }) => `
        ::after {
          background: ${theme.colors.info};
        }
      `,
    },
  }
); */

const modifierConfig = {
  dialog: ({ theme }) => `
    background: #F2F7FF;
    border: 1px solid #D8D8F6;
    border-radius: 4px;
  `,
};

const styles = ({ theme, ...props }) => `
  > :last-child {
    width: ${px2rem(40)};
  }
`;

export const HistoryItem = buildStyledComponent(
  'HistoryItem',
  styled(
    ({
      children,
      content,
      leftContent,
      editable,
      handleEdit,
      modifiers,
      ...props
    }) => (
      <Row {...props}>
        {leftContent ? (
          <Column modifiers={[leftContent && 'col_2']}>{leftContent}</Column>
        ) : null}
        <Column modifiers={['col_12', 'noPadding']}>
          {content ? (
            <Text
              modifiers={[
                editable && 'info',
                'block',
                'noMargin',
                'preserveLineBreak',
              ]}
            >
              {content}
            </Text>
          ) : (
            children
          )}
        </Column>
        {editable && (
          <Column>
            <Button
              image={assets.icons.iconNote}
              width={40}
              height={40}
              onClick={handleEdit}
              modifiers={['icon', 'white']}
            />
          </Column>
        )}
      </Row>
    )
  ),
  styles,
  { modifierConfig }
);

export default HistoryItem;

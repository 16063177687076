import React from 'react';
import styled from 'styled-components';
import { Row } from 'common';

import Title from './Title';
import MenuButton from './MenuButton';
import Left from './Left';
import Center from './Center';
import Right from './Right';
import Bottom from './Bottom';
import Wrapper from './Wrapper';
import { buildStyledComponent } from 'style';

const Header = ({ children, ...props }) => {
  return (
    <Wrapper {...props}>
      <Row modifiers="middle">{children}</Row>
    </Wrapper>
  );
};

Header.MenuButton = MenuButton;
Header.Title = Title;
Header.Left = Left;
Header.Center = Center;
Header.Right = Right;
Header.Bottom = Bottom;

export default buildStyledComponent(
  'Header',
  styled(Header),
  ({ theme }) => `
    background: ${theme.colors.white};

  `
);

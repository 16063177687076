import styled from 'styled-components';
import { Container } from 'common';
import { buildStyledComponent, px2rem } from 'style';

const styles = () => `
  background: #FFF;
`;

export default buildStyledComponent(
  'Header_Wrapper',
  styled(Container),
  styles
);

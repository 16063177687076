import React, { useContext } from 'react';
import { Row, Column, Text, Avatar } from 'common';

import { getFullName } from 'utils/string';

import { PatientHomeContext } from './Context';

export const ProfileHeader = () => {
  const { patient, goPage } = useContext(PatientHomeContext);

  const showProfile = () => {
    goPage('profile', true);
  };

  return (
    <Column modifiers={['col_12']}>
      <Row modifiers={['middle']} onClick={showProfile}>
        <Avatar modifiers={['squared']} user={patient} width={40} height={40} />
        <Text modifiers={['block', 'start']}>{getFullName(patient)}</Text>
      </Row>
    </Column>
  );
};

import { Container, Text } from 'common';
import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const MeasureItem = buildStyledComponent(
  'CareplanMeasureItem',
  styled(({ details, ...props }) => (
    <Container {...props}>
      {Object.entries(details).map(
        ([key, value]) =>
          value?.trim().length > 0 && (
            <Text modifiers={['block']}>
              {key}:{' '}
              <Text modifiers={['primary', 'preserveLineBreak', 'inline']}>
                {value}
              </Text>
            </Text>
          )
      )}
    </Container>
  )),
  ({ theme }) => `
    padding-top: 0;
    > * {
      margin-left: ${theme.dimensions.padding_1};
      > * {
        margin-left: ${theme.dimensions.padding_1};
        vertical-align: top;
      }
    }
  `
);

export default MeasureItem;

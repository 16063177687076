import { useContext, useState, useMemo } from 'react';

import { Row, Column, ScrollView, Text, Button, BackButton } from 'common';
import { Container, Modal, PeriodPicker } from 'common';
import { CreateCareplanContext } from '../Context';
import Item from './Item';
import { endDateOfCareplan } from 'utils/careplan';
import { toLocalTime, getStartDate } from 'utils/time';
import assets from 'assets';

export default ({ handleAdd, handleClose }) => {
  const { careplanHistorySigned: cpHistory } = useContext(
    CreateCareplanContext
  );
  const [selectedItems, updateSelectedItems] = useState({});

  const [selectedPeriod, updateSelectedPeriod] = useState('3m');

  const pastItems = useMemo(() => {
    if (!cpHistory) {
      return {};
    }
    const items = {};
    const filterStartDateTimestamp = getStartDate(selectedPeriod).getTime();
    for (let cp of cpHistory) {
      const endTimestamp = toLocalTime(endDateOfCareplan(cp))
        .toDate()
        .getTime();
      if (filterStartDateTimestamp > endTimestamp) {
        break;
      }
      if (!cp.content?.diet) {
        continue;
      }
      Object.entries(cp.content.diet).forEach(([type, question]) => {
        if (items[type]) {
          return;
        }
        items[type] = { ...question, id: type };
      });
    }
    return items;
  }, [cpHistory, selectedPeriod]);

  const toggleSelect = (type, item) => {
    if (selectedItems[type]) {
      delete selectedItems[type];
    } else {
      selectedItems[type] = item;
    }
    updateSelectedItems({ ...selectedItems });
  };

  const handleDone = () => {
    handleAdd(selectedItems);
    handleClose();
  };

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle']}>
          <BackButton onClick={handleClose} />
          <Text>Past Diet Plans</Text>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
          <Container modifiers={['flexFixed', 'backgroundGray', 'padding_2']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Column>
                <Text modifiers={['primary']}>Select from below list</Text>
              </Column>
              <Column>
                <PeriodPicker
                  value={selectedPeriod}
                  handleSelect={updateSelectedPeriod}
                  icon={assets.icons.iconFilter}
                  actionTitle="Filter by time period"
                />
              </Column>
            </Row>
          </Container>
          <ScrollView>
            <Container>
              {Object.keys(pastItems).length === 0 ? (
                <Text>No past diet plans for selected period found.</Text>
              ) : (
                Object.entries(pastItems).map(([type, item]) => (
                  <Item
                    item={item}
                    key={type}
                    selected={selectedItems[type]}
                    onSelect={() => toggleSelect(type, item)}
                  />
                ))
              )}
            </Container>
          </ScrollView>
          <Container modifiers={['flexFixed', 'footer']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={handleClose}
              />
              <Button
                modifiers={[
                  'widthMedium',
                  'roundCorner',
                  Object.keys(selectedItems).length === 0 && 'disabled',
                ]}
                onClick={handleDone}
              >
                Add Selected
              </Button>
            </Row>
          </Container>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

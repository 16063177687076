import { useState, useCallback } from 'react';

import { Button, Row, Column, Text, ScrollView } from 'common';
import { Container, PeriodPicker } from 'common';
import { AlertsCategoryItem } from './Item';
import Book from './BookAppointment';
import assets from 'assets';

export default ({
  handleBook,
  handleCategoryClick,
  categories,
  goBack,
  me,
  patient,
  period,
  setPeriod,
}) => {
  const [showBookModal, setShowBookModal] = useState(false);

  return (
    <>
      <Container
        modifiers={['fluid', 'fullHeight', 'flexBox', 'backgroundWhite']}
      >
        <Container modifiers={['backgroundGray']}>
          <Row modifiers={['spaceBetween', 'middle']}>
            <Text modifiers={['large', 'bold']}>Alerts</Text>
            <Column>
              <PeriodPicker value={period} handleSelect={setPeriod} />
            </Column>
          </Row>
        </Container>
        <ScrollView>
          <Container>
            {categories.map((category) => (
              <AlertsCategoryItem
                category={category}
                handleClick={() => handleCategoryClick(category)}
              />
            ))}
          </Container>
        </ScrollView>
        <Container modifiers={['flexFixed', 'footer']}>
          <Row modifiers={['spaceBetween']}>
            <Button
              modifiers={['icon', 'transparent']}
              image={assets.icons.icCloseGray}
              onClick={goBack}
            />
            <Button
              modifiers={['primary', 'roundCorner']}
              onClick={() => setShowBookModal(true)}
            >
              Book Appointment
            </Button>
          </Row>
        </Container>
      </Container>
      {showBookModal && (
        <Book
          provider={me}
          patient={patient}
          handleClose={() => setShowBookModal(false)}
          handleDone={handleBook}
        />
      )}
    </>
  );
};

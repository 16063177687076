import styled from 'styled-components';
import { Container } from 'common';
import { buildStyledComponent } from 'style';

const styles = () => `
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
`;

export const ContentCard = buildStyledComponent(
  'Contacts_ListWrapper',
  styled(Container),
  styles
);

import { useEffect, useState, useContext, createRef, useCallback } from 'react';
import { BackButton, ScrollView, Column, Text, Button } from 'common';
import { useMutation, useLazyQuery } from '@apollo/client';
import moment from 'moment';

import { Container, DatePicker, TextArea, Row, Popover } from 'common';
import Wrapper, { ProfileRow } from './BookWrapper';
import assets from 'assets';
import { AuthContext } from 'contexts/auth';
import { formatTitleAndName } from 'utils/string';
import ProviderSelect from './ProviderSelect';
import PatientSelect from './PatientSelect';
import { displayTime } from 'utils/time';
import { loadingVar } from 'graphql/cache';
import { FETCH_AVAILABLE_TIMES } from 'graphql/queries';
import { BOOK_APPOINTMENT } from 'graphql/mutations';

export default function BookAppointment({
  patient,
  date: initDate,
  handleDone,
}) {
  const reasonRef = createRef();
  const { me } = useContext(AuthContext);
  const [selectedProvider, setProvider] = useState(me);
  const [selectedPatient, setPatient] = useState(!!patient ? patient : null);
  const [selectedDate, setSelectedDate] = useState(initDate || new Date());
  const [showProviderSelect, setShowProviderSelect] = useState(null);
  const [showPatientSelect, setShowPatientSelect] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState();
  const [alertMessage, updateAlertMessage] = useState();
  const [showConfirm, updateShowConfirm] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);

  const handleSelectProvider = useCallback((provider) => {
    setProvider(provider);
  }, []);

  const handleChangeDate = useCallback((date) => {
    setSelectedDate(date);
  }, []);

  const [fetchAvailableSlots] = useLazyQuery(FETCH_AVAILABLE_TIMES, {
    onCompleted: ({ availableTimes }) => {
      if (!availableTimes) {
        return;
      }
      const slots = availableTimes.map((range) => {
        const start = new Date(range.start);
        const end = new Date(range.end);
        return {
          time: start,
          end,
          text: displayTime(start, 'hh:mm a'),
        };
      });
      setTimeSlots(slots);
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  useEffect(() => {
    async function fetchData() {
      setSelectedSlot(null);
      setTimeSlots([]);
      if (!selectedProvider || !selectedDate) {
        return;
      }
      loadingVar(true);
      const midnightTimestamp = new Date(
        displayTime(selectedDate, 'date')
      ).getTime();
      const from = new Date(Math.max(midnightTimestamp, new Date().getTime()));
      const to = new Date(midnightTimestamp + 24 * 3600 * 1000);
      const offset = moment().utcOffset();
      await fetchAvailableSlots({
        variables: { provider: selectedProvider._id, from, to, offset },
      });
      loadingVar(false);
    }
    fetchData();
  }, [selectedProvider, selectedDate, fetchAvailableSlots]);

  const [bookAppointment] = useMutation(BOOK_APPOINTMENT, {
    onCompleted: (appointment) => {
      handleDone(appointment);
    },
    onError: (error) => {
      updateAlertMessage('Unsuccessful');
    },
  });

  const handleAdd = () => {
    if (!selectedPatient) {
      updateAlertMessage('Select a patient');
      return;
    }
    if (!selectedSlot) {
      updateAlertMessage('Select the appointment time');
      return;
    }
    updateShowConfirm(true);
  };

  const handleConfirm = async () => {
    loadingVar(true);
    await bookAppointment({
      variables: {
        appointment: {
          provider: selectedProvider._id,
          patient: selectedPatient._id,
          reason: reasonRef.current.value,
          time: selectedSlot.time,
        },
      },
    });
    loadingVar(false);
  };

  return (
    <Wrapper>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['middle']}>
          <BackButton onClick={() => handleDone()} />
          <Text modifiers={['center', 'large']}>Book Appointment</Text>
        </Row>
      </Container>
      <Container modifiers={['flexOne', 'fullHeight', 'flexBox']}>
        <Container modifiers={['flexFixed', 'fluid']}>
          <Row>
            <Text modifiers={['block', 'h2']}>Select Patient</Text>
          </Row>
          <ProfileRow onClick={() => !patient && setShowPatientSelect(true)}>
            <Row modifiers={['spaceBetween']}>
              {selectedPatient ? (
                <Text modifiers={['primary', 'medium']}>
                  {formatTitleAndName(selectedPatient)}
                </Text>
              ) : (
                <Text modifiers={['dark', 'medium']}>
                  Press to select patient
                </Text>
              )}
              {!patient && <img src={assets.icons.icChevronDownGray} />}
            </Row>
          </ProfileRow>
        </Container>
        <Container modifiers={['flexFixed', 'fluid']}>
          <Row>
            <Text modifiers={['block', 'h2']}>Select Provider</Text>
          </Row>
          <ProfileRow onClick={() => setShowProviderSelect(true)}>
            <Row modifiers={['spaceBetween']}>
              {selectedProvider ? (
                <Text modifiers={['primary', 'medium']}>
                  {formatTitleAndName(selectedProvider)}
                </Text>
              ) : (
                <Text modifiers={['dark', 'medium']}>
                  Press to select provider
                </Text>
              )}
              <img src={assets.icons.icChevronDownGray} />
            </Row>
          </ProfileRow>
        </Container>
        <Container modifiers={['fluid', 'flexFixed']}>
          <Row>
            <Text modifiers={['block', 'h2']}>Reason</Text>
          </Row>
          <TextArea innerRef={reasonRef} />
        </Container>
        <Container modifiers={['flexFixed', 'fluid']}>
          <Row>
            <Text modifiers={['block', 'h2']}>Select Date and Time</Text>
          </Row>
          <DatePicker
            handleChange={handleChangeDate}
            value={selectedDate}
            minDate={new Date()}
            className="book-appointment-date-picker"
          >
            <Row>
              <Column modifiers={['col_4', 'noPadding']}>
                <Text modifiers={['block', 'medium', 'center']}>Day</Text>
              </Column>
              <Column modifiers={['col_4', 'noPadding']}>
                <Text modifiers={['block', 'medium', 'center']}>Date</Text>
              </Column>
              <Column modifiers={['col_4', 'noPadding']}>
                <Text modifiers={['block', 'medium', 'center']}>Start</Text>
              </Column>
            </Row>
            <Row modifiers={['smallGutters', 'borderGray', 'roundCorner']}>
              <Column modifiers={['col_4']}>
                <Text modifiers={['block', 'medium', 'center']}>
                  {displayTime(selectedDate, 'dddd')}
                </Text>
              </Column>
              <Column modifiers={['col_4']}>
                <Text modifiers={['block', 'medium', 'center', 'primary']}>
                  {displayTime(selectedDate, 'MM/DD/YY')}
                </Text>
              </Column>
              <Column modifiers={['col_4']}>
                <Text modifiers={['block', 'medium', 'center']}>
                  {selectedSlot
                    ? displayTime(selectedSlot.time, 'hh:mm a')
                    : '-- --'}
                </Text>
              </Column>
            </Row>
          </DatePicker>
        </Container>
        <Container modifiers={['flexFixed', 'fluid']}>
          <Text modifiers={['block', 'h2']}>Available time slots</Text>
        </Container>
        <ScrollView modifiers={['noPadding']}>
          <Row>
            {timeSlots?.length > 0 ? (
              timeSlots.map((slot, index) => (
                <Column modifiers={['col_4', 'noPadding']} key={index}>
                  <Row modifiers={['topGutters_1', 'center']}>
                    <Button
                      modifiers={[
                        slot.time === selectedSlot?.time
                          ? null
                          : 'outlinePrimary',
                        'roundCorner',
                        'small',
                      ]}
                      onClick={() => setSelectedSlot(slot)}
                      key={slot.time}
                    >
                      {slot.text}
                    </Button>
                  </Row>
                </Column>
              ))
            ) : (
              <Text modifiers={['medium']}>No appointment available</Text>
            )}
          </Row>
        </ScrollView>
      </Container>
      <Container modifiers={['flexFixed', 'footer']}>
        <Row modifiers={['spaceBetween']}>
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icCloseGray}
            onClick={() => handleDone()}
          />
          <Button
            modifiers={[
              'widthSmall',
              'primary',
              'roundCorner',
              'small',
              (!selectedProvider || !selectedPatient || !selectedSlot) &&
                'disabled',
            ]}
            onClick={handleAdd}
          >
            Book Appointment
          </Button>
        </Row>
      </Container>
      {showProviderSelect !== null && (
        <Container modifiers={[!showProviderSelect && 'noDisplay']}>
          <ProviderSelect
            handleClose={() => setShowProviderSelect(false)}
            handleSelect={handleSelectProvider}
          />
        </Container>
      )}
      {showPatientSelect !== null && (
        <Container modifiers={[!showPatientSelect && 'noDisplay']}>
          <PatientSelect
            handleClose={() => setShowPatientSelect(false)}
            handleSelect={(patient) => setPatient(patient)}
          />
        </Container>
      )}
      <Popover
        open={!!alertMessage}
        footer
        onClose={() => updateAlertMessage(null)}
      >
        <Text modifiers="center">{alertMessage}</Text>
      </Popover>
      {showConfirm && (
        <Popover
          open
          footer
          onClose={() => updateShowConfirm(false)}
          onConfirm={handleConfirm}
          title="Are you sure want to book appointment"
        >
          <Text modifiers={['center', 'primary']}>
            {formatTitleAndName(selectedPatient)}
          </Text>
          <Text modifiers={['center', 'primary']}>
            {displayTime(selectedSlot.time, 'ddd MM/DD/YYYY hh:mm a')}
          </Text>
        </Popover>
      )}
    </Wrapper>
  );
}

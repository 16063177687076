import { useState, useRef, useEffect } from 'react';
import { cloneDeep } from 'lodash';

import { Row, Text, Button, BackButton } from 'common';
import { Container, Modal, Tabs, Popover } from 'common';
import Alerts from '../Alerts';
import Frequency from '../Frequency';
import GroupFrequencyModal from './GroupFrequencyModal';
import assets from 'assets';
import { DIET_ITEMS } from 'utils/constants';

const Instruction = ({
  item,
  items,
  handleDone,
  handleClose,
  handleGrouping,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [frequency, updateFrequency] = useState(
    cloneDeep(item?.frequency || {})
  );
  const [alerts, updateAlerts] = useState(cloneDeep(item?.alerts || {}));
  const [otherItems, setOtherItems] = useState([]);
  const [showGroupingModal, setShowGroupingModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const frequencyRef = useRef();
  const alertsRef = useRef();

  useEffect(() => {
    const others = {};
    Object.keys(items).forEach((elem) => {
      if (elem !== item.id) {
        others[elem] = { id: elem };
      }
    });
    setOtherItems(others);
  }, []);

  const handleDoneClick = () => {
    if (activeTab === 0 && Object.keys(alerts).length === 0) {
      setShowConfirmModal(true);
      return;
    }
    handleSave();
  };

  const handleSave = () => {
    let frequencyEdit, alertsEdit;
    if (activeTab === 0) {
      updateFrequency(frequencyRef.current());
      frequencyEdit = frequencyRef.current();
      alertsEdit = alerts;
    } else {
      updateAlerts(alertsRef.current());
      frequencyEdit = frequency;
      alertsEdit = alertsRef.current();
    }

    handleDone(item, frequencyEdit, alertsEdit);
    handleClose();
  };

  const onConfirmContinue = () => {
    setShowConfirmModal(false);
    handleSave();
  };

  const handleGroupClick = () => {
    const newFrequency = frequencyRef.current();
    updateFrequency(newFrequency);
    setShowGroupingModal(true);
  };

  const handleFinishGrouping = (selectedItems) => {
    handleGrouping(item, selectedItems, frequency);
  };

  const changeTab = (tab) => {
    setActiveTab(tab);
    if (tab === 1) {
      updateFrequency(frequencyRef.current());
    } else {
      updateAlerts(alertsRef.current());
    }
  };

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle']}>
          <BackButton onClick={handleClose} />
          <Text>Diet Instructions</Text>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        <Container
          modifiers={['flexBox', 'fluid', 'fullHeight', 'backgroundGray']}
        >
          <Container modifiers={['flexFixed', 'padding_2']}>
            <Text modifiers={['medium']}>{DIET_ITEMS[item.id].title}</Text>
          </Container>
          <Container
            modifiers={['flexOne', 'fullHeight', 'flexBox', 'backgroundWhite']}
          >
            <Tabs current={activeTab} onChange={changeTab} noSeperator>
              <Tabs.Tab
                title="Frequency"
                modifiers={['xSmallPadding', 'noTab']}
              />
              <Tabs.Tab title="Alerts" modifiers={['xSmallPadding']} />
            </Tabs>
            <Container modifiers={['fluid', 'fullHeight', 'withGutters']}>
              {activeTab === 0 ? (
                <Frequency
                  frequency={frequency}
                  getRef={frequencyRef}
                  icon={assets.icons.icDiet}
                />
              ) : (
                <Alerts
                  item={{ id: item.id, alerts: alerts }}
                  getRef={alertsRef}
                  type="diet"
                />
              )}
            </Container>
          </Container>
          <Container modifiers={['flexFixed', 'footer']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={handleClose}
              />
              {activeTab === 0 && Object.keys(otherItems).length > 0 && (
                <Button modifiers={['roundCorner']} onClick={handleGroupClick}>
                  Group Frequency
                </Button>
              )}
              <Button
                modifiers={['primary', 'widthSmall', 'roundCorner']}
                onClick={handleDoneClick}
              >
                Done
              </Button>
            </Row>
          </Container>
          <GroupFrequencyModal
            isVisible={showGroupingModal}
            frequency={frequency}
            items={otherItems}
            onConfirm={handleFinishGrouping}
            onClose={() => setShowGroupingModal(false)}
          />
        </Container>
        <Popover
          open={showConfirmModal}
          onConfirm={onConfirmContinue}
          onClose={() => setShowConfirmModal(false)}
          footer
        >
          <Text modifiers={['bold', 'center']}>No alert added</Text>
          <Text modifiers={['medium', 'center']}>Do you wish to continue?</Text>
        </Popover>
      </Modal.Body>
    </Modal>
  );
};

export default Instruction;

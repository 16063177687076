import styled from 'styled-components';

import { Row, Text, Tabs } from 'common';
import { buildStyledComponent, px2rem } from 'style';

const Comp = (props) => {
  const { title, subTitle, ...otherProps } = props;

  return (
    <Tabs.Tab {...otherProps}>
      <Row modifiers={['spaceBetween', 'middle']}>
        <Text modifiers={['block', 'center', 'medium', 'mediumWeight', 'dark']}>
          {title}
        </Text>
        <Text modifiers={['center', 'large']}>{subTitle}</Text>
      </Row>
    </Tabs.Tab>
  );
};

const styles = ({}) => `
  & > div {
    flex-direction: column;
    align-items: center;

    > :first-child {
      flex-grow: 1;
      align-content: center;
    }
  }
`;

export const Tab = buildStyledComponent('Reports_Tab', styled(Comp), styles);

export default Tab;

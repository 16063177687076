import React, { useState, useEffect } from 'react';

import { Row, Column } from 'common';
import FormLabel from '../FormLabel';

import Error from '../FormError';
import Wrapper from './Wrapper';

const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
};

export const FormPinInput = (props) => {
  const {
    field: { name, onBlur, onChange, value },
    form: { errors, touched, setFieldTouched },
    hasLabel,
    hasBorderBottom,
    dark,
    placeholder,
    required,
    fields = 5,
    autoFocus = true,
    fitWidth = false,
    wide,
    largeLabel,
    smallPadding = false,
    ...inputProps
  } = props;

  const hasError = errors[name] && touched[name];

  const [values, setValues] = useState(Array(fields).fill(''));
  const [autoFocusIndex, setAutoFocusIndex] = useState(0);

  let iRefs = [];
  for (let i = 0; i < fields; i++) {
    iRefs.push(React.createRef());
  }

  useEffect(() => {
    let vals;
    let index = 0;
    if (value && value.length) {
      vals = [];
      for (let i = 0; i < fields; i++) {
        vals.push(value[i] || '');
      }
      index = value.length >= fields ? 0 : value.length;
      setAutoFocusIndex(index);
    } else {
      vals = Array(fields).fill('');
    }

    setValues(vals);
  }, [value, fields]);

  const handleFocus = (e) => {
    e.target.select(e);
  };

  const handleChange = (e) => {
    const index = parseInt(e.target.dataset.id);
    e.target.value = e.target.value.replace(/\W/g, '').toUpperCase();
    if (e.target.value === '') return;

    let next;
    const val = e.target.value;
    let vals = Object.assign([], values);
    if (val.length > 1) {
      let nextIndex = val.length + index - 1;
      if (nextIndex >= fields) {
        nextIndex = fields - 1;
      }
      next = iRefs[nextIndex];
      const split = val.split('');
      split.forEach((item, i) => {
        const cursor = index + i;
        if (cursor < fields) {
          vals[cursor] = item;
        }
      });
      setValues(vals);
    } else {
      next = iRefs[index + 1];
      vals[index] = val;
      setValues(vals);
    }

    if (next) {
      next.current.focus();
      next.current.select();
    }

    onChange(name)(vals.join(''));
  };

  const handleKeyDown = (e) => {
    const index = parseInt(e.target.dataset.id);
    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prev = iRefs[prevIndex];
    const next = iRefs[nextIndex];
    switch (e.keyCode) {
      case KEY_CODE.backspace:
        e.preventDefault();
        const vals = [...values];
        if (values[index]) {
          vals[index] = '';
        } else if (prev) {
          vals[prevIndex] = '';
          prev.current.focus();
        }
        setValues(vals);
        onChange(name)(vals.join(''));
        break;
      case KEY_CODE.left:
        e.preventDefault();
        if (prev) {
          prev.current.focus();
        }
        break;
      case KEY_CODE.right:
        e.preventDefault();
        if (next) {
          next.current.focus();
        }
        break;
      case KEY_CODE.up:
      case KEY_CODE.down:
        e.preventDefault();
        break;
      default:
        if (e.target.value === e.key && next) {
          e.preventDefault();
          next.current.focus();
          next.current.select();
        }
        break;
    }
  };

  return (
    <>
      {hasLabel && (
        <FormLabel
          modifiers={[
            dark && 'dark',
            largeLabel && 'large',
            largeLabel && 'bottomSpacing',
          ]}
        >
          {placeholder} {required && <span>*</span>}
        </FormLabel>
      )}
      <Row modifiers={['middle', 'noWrap']}>
        {values.map((value, index) => (
          <Column
            modifiers={[
              'col',
              'center',
              fitWidth && 'fluid',
              smallPadding && 'padding_3',
            ]}
            key={`${name}-${index}`}
          >
            <Wrapper
              modifiers={[wide && 'fullWidth']}
              value={value}
              onChange={handleChange}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              onBlur={() => {
                setFieldTouched(name);
                onBlur(name);
              }}
              onClick={handleFocus}
              pattern="[0-9]*"
              type="number"
              inputmode="decimal"
              autoFocus={autoFocus && index === autoFocusIndex}
              autoComplete={index === 0 ? 'one-time-code' : 'off'}
              data-id={index}
              ref={iRefs[index]}
              {...inputProps}
            />
          </Column>
        ))}
      </Row>
      {hasError && <Error>{errors[name]}</Error>}
    </>
  );
};

export default FormPinInput;

import { Text, ContentCard, Row, Column, ScrollView } from 'common';

import { Container } from 'common';
import { PROGRESS_ITEMS } from 'utils/constants';
import { toLocalTime } from 'utils/time';
import Item from './Item';

export default ({ type, progress, finalResponses, handleSelectItem }) => {
  const isNA = progress === null;

  return (
    <Container modifiers={['fluid', 'fullHeight', 'flexBox']}>
      <Container modifiers={['flexFixed']}>
        <ContentCard modifiers={['withGutters', 'fluid']}>
          <Row modifiers={['middle']}>
            <Column modifiers={['col_9', 'flexBox', 'verticalContentCenter']}>
              <img src={PROGRESS_ITEMS[type].icon} />
              <Text modifiers={['darkGrey', 'subtitle', 'semiBold']}>
                {PROGRESS_ITEMS[type].title}
              </Text>
            </Column>
            <Column modifiers={['col_3']}>
              <Row modifiers={['middle', 'spaceBetween']}>
                <Text
                  modifiers={[
                    isNA ? 'muted' : progress > 60 ? 'success' : 'danger',
                    'subtitle',
                    'semiBold',
                  ]}
                >
                  {!isNA ? `${Math.round(progress)}%` : 'N/A'}
                </Text>
              </Row>
            </Column>
          </Row>
        </ContentCard>
      </Container>
      <Container
        modifiers={['fluid', 'flexBox', 'fullHeight', 'backgroundWhite']}
      >
        <ScrollView>
          <Container>
            {Object.entries(finalResponses).map(([itemType, response]) => (
              <Item
                handleSelectItem={handleSelectItem}
                measurement={{
                  time: !response
                    ? null
                    : response.addedTime
                    ? response.addedTime
                    : toLocalTime(response.time),
                  value: response?.response.value,
                  success: response?.isPositive === true,
                  danger: response?.isPositive === false,
                  muted:
                    response?.isPositive === undefined ||
                    response?.isPositive === null,
                }}
                type={type}
                itemType={itemType}
                key={itemType}
              />
            ))}
          </Container>
        </ScrollView>
      </Container>
    </Container>
  );
};

import { Column, Button, HistoryItem } from 'common';
import { Container, Row, Text, Modal } from 'common';
import assets from 'assets';
import { displayTime } from 'utils/time';

export default ({ diagnoses, updateDiagnoses }) => {
  const onRemove = (diagnosis) => {
    Modal.confirm({
      onOk: () => {
        delete diagnoses[diagnosis.code];
        updateDiagnoses({ ...diagnoses });
      },
      title: 'Delete Diagnosis Note',
      text: 'Are you sure want to delete this diagnosis?',
      okText: 'Delete',
    });
  };
  return (
    <HistoryItem modifiers={['dialog']}>
      <Container>
        <Row modifiers={['spaceBetween', 'bottomGutters_1']}>
          <Text modifiers={['medium', 'secondary', 'semiBold']}>
            Recently Added
          </Text>
          <Text modifiers={['small']}>
            {displayTime(new Date(), 'MM/DD/YYYY')}
          </Text>
        </Row>
        {Object.values(diagnoses).map((diagnosis) => (
          <Row modifiers={['spaceBetween']} key={diagnosis.code}>
            <Column modifiers={['noPadding', 'col']}>
              <Text modifiers={['medium', 'secondary', 'block']}>
                {diagnosis.code}: {diagnosis.description}
              </Text>
            </Column>
            <Button
              modifiers={['icon', 'transparent']}
              image={assets.icons.icCloseGray}
              width={30}
              height={30}
              onClick={() => onRemove(diagnosis)}
            />
          </Row>
        ))}
      </Container>
    </HistoryItem>
  );
};

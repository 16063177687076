import styled from 'styled-components';

import { Column, Container, Row, Text } from 'common';
import { buildStyledComponent } from 'style';

export const NOTE_ITEMS = {
  subjective: 'Subjective',
  objective: 'Objective',
  assessment: 'Assessment',
  plan: 'Plan',
  diagnosis: 'Qualifying Diagnosis',
  attachments: 'Attachments',
};

export const NoteItemWrapper = buildStyledComponent(
  'NoteItemWrapper',
  styled(Container),
  ({ theme }) => `
    border-radius: ${theme.dimensions.borderRadius_1};
    margin-bottom: ${theme.dimensions.padding_1};
    margin-top: ${theme.dimensions.padding_1};
    background: ${theme.colors.white};
    padding: 0;
  `,
  {
    modifierConfig: {
      round: ({ theme }) => `
        border-radius: 6px;
        border: 1px solid #E7E7E9;
      `,
      active: ({ theme }) => `
        background: ${theme.colors.secondary};
        >:first-child {
          >:first-child {
            > * {
              color: ${theme.colors.white};
            }
          }
          >:last-child {
            > * {
              color: ${theme.colors.white};
              background-color: ${theme.colors.gray1};
            }
          }
        }
      `,
    },
  }
);

const NoteItem = ({ itemKey, note, ...props }) => {
  return (
    <NoteItemWrapper {...props} modifiers={['fluid']}>
      <Row modifiers="middle">
        <Column modifiers={['col', 'flexBox', 'verticalContentCenter']}>
          <Text modifiers={['subtitle', 'white', 'semiBold']}>
            {NOTE_ITEMS[itemKey]}
          </Text>
        </Column>
      </Row>
    </NoteItemWrapper>
  );
};

export default NoteItem;

import React from 'react';

import FormLabel from './FormLabel';
import Select from 'common/Select';

import Error from './FormError';

export const FormSelect = (props) => {
  const {
    field: { name, value },
    form: { errors, touched, setFieldValue },
    required,
    light,
    hasLabel,
    label,
    largeLabel,
    borderClassName,
    disabled,
    hasValueField,
    options,
    ...restProps
  } = props;

  const hasError = errors[name] && touched[name];
  const getItem = (value) => {
    return options.find((s) => s.value === value);
  };

  return (
    <div>
      {hasLabel && (
        <FormLabel
          modifiers={[largeLabel && 'large', largeLabel && 'bottomSpacing']}
        >
          {label}
          {required && <span>*</span>}
        </FormLabel>
      )}
      <Select
        hasError={hasError}
        light={light}
        value={getItem(value)}
        disabled={disabled}
        onChange={(newItem) => {
          setFieldValue(name, newItem.value);
        }}
        className={borderClassName || ''}
        options={options}
        {...restProps}
      />

      {hasError && <Error>{errors[name]}</Error>}
    </div>
  );
};

export default FormSelect;

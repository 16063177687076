import React from 'react';
import { noop } from 'lodash';

import { Row, Column, Text, Button } from 'common';
import assets from 'assets';

import Wrapper from './Wrapper';

const titles = ['User Registration', 'Security', 'Professional'];

export default function Header({ step = 0, goBack = noop, resetStep = noop }) {
  return (
    <Wrapper modifiers={['fluid']}>
      <Row>
        <Column modifiers={['col_1']}>
          {step > 0 && (
            <Button
              image={assets.icons.icBack}
              modifiers={['icon', 'transparent']}
              onClick={goBack}
            />
          )}
        </Column>
        <Column
          modifiers={[
            'col_6',
            'col_offset_2',
            'center',
            'verticalContentCenter',
            'flexBox',
          ]}
        >
          <Text modifiers={['h3', 'bold']}>{titles[step]}</Text>
        </Column>
        <Column modifiers={['verticalContentCenter', 'flexBox']}>
          <Button
            onClick={resetStep}
            style={{ color: '#FF0000' }}
            modifiers={['link', 'large', 'noPadding']}
          >
            Cancel
          </Button>
        </Column>
      </Row>
    </Wrapper>
  );
}

import { useContext, useState } from 'react';
import { Row, Column, Button, Text } from 'common';

import { Container } from 'common';
import { CreateNoteContext } from '../Context';
import DiagnosisHistory from './History';
import DiagnosisInput from './Input';
import AddedItems from './AddedItems';
import assets from 'assets';

const DiagnosisIndex = ({}) => {
  const { note, updateNote, goBack, setBackHandler } =
    useContext(CreateNoteContext);
  const [currentDiagnoses, setCurrentDiagnoses] = useState(() => {
    if (!note.content?.diagnosis) {
      return [];
    }
    const content = note.content;
    const values = {};
    content.diagnosis.forEach((diagnosis) => {
      values[diagnosis.code] = diagnosis;
    });
    return values;
  });
  const [showInput, setShowInput] = useState(false);
  const [selectFromPast, setSelectFromPast] = useState(false);

  const handleShowInput = (showStatus = null) => {
    setShowInput(showStatus !== null ? showStatus : !showInput);
  };

  const handleAdd = (diagnoses) => {
    setShowInput(false);
    setCurrentDiagnoses({ ...currentDiagnoses, ...diagnoses });
  };

  const handleAddFromPast = () => {
    setSelectFromPast(true);
    setBackHandler(() => setSelectFromPast(false));
  };

  const handleDone = () => {
    const content = note.content;
    content.diagnosis = Object.values(currentDiagnoses);
    updateNote({ ...note, content });
    goBack();
  };

  return (
    <Container modifiers={['fluid', 'flexBox', 'fullHeight']}>
      <Container style={{ background: '#F3F3F4' }} modifiers={['flexFixed']}>
        <Row modifiers={['middle', 'spaceBetween']}>
          <Column modifiers={['noPadding']}>
            <Text modifiers={['bold', 'block']}>Diagnosis</Text>
            <Text modifiers={['block', 'muted', 'medium']}>
              Add diagnosis to note
            </Text>
          </Column>
          <Column modifiers={['noPadding']}>
            <Button
              modifiers={[
                showInput || selectFromPast ? 'disabled' : 'success',
                'roundCorner',
              ]}
              onClick={() => {
                handleShowInput();
              }}
            >
              <Text modifiers={['subtitle', 'white', 'bold']}>+ ADD</Text>
            </Button>
          </Column>
        </Row>
      </Container>
      {showInput && (
        <DiagnosisInput
          value={note?.content?.diagnosis || []}
          handleSave={handleAdd}
          handleCancel={() => {
            handleShowInput(false);
          }}
        />
      )}
      {!selectFromPast && Object.keys(currentDiagnoses).length > 0 && (
        <Container modifiers={['flexFixed', 'padding_2']}>
          <AddedItems
            diagnoses={currentDiagnoses}
            updateDiagnoses={setCurrentDiagnoses}
          />
        </Container>
      )}
      <Container modifiers={['flexOne', 'fluid', 'fullHeight']}>
        <DiagnosisHistory
          selectable={selectFromPast}
          handleAddFromPast={handleAddFromPast}
          handleAdd={handleAdd}
        />
      </Container>
      {!selectFromPast && (
        <Container modifiers={['footer']}>
          <Row modifiers={['spaceBetween', 'middle']}>
            <Button
              modifiers={['icon', 'transparent']}
              image={assets.icons.icCloseGray}
              onClick={goBack}
            />
            <Button
              modifiers={['widthSmall', 'roundCorner']}
              onClick={handleDone}
            >
              Done
            </Button>
          </Row>
        </Container>
      )}
    </Container>
  );
};

export default DiagnosisIndex;

import { useHistory } from 'react-router';
import assets from 'assets';
import { Row, Column, Text } from 'common';
import Card from './Card';
import urls from 'routes/urls';

const Call = () => {
  const history = useHistory();

  return (
    <Card modifiers={['fluid']} onClick={() => history.push(urls.CALL_HISTORY)}>
      <Row modifiers={['fullHeight', 'middle']}>
        <Column modifiers={['col_3', 'flexBox', 'verticalContentCenter']}>
          <Card.Icon src={assets.icons.icCallGreen} />
        </Column>
        <Column modifiers={['col_9']}>
          <Text modifiers={['block', 'darkGrey', 'subtitle', 'semiBold']}>
            Call
          </Text>
          {/* <Text modifiers={['block', 'darkGrey', 'small', 'body']}>20 New</Text> */}
        </Column>
      </Row>
    </Card>
  );
};

export default Call;

import React from 'react';
import { MainLayout } from 'layouts';
import { BackButton, Text, Container } from 'common';

const { Header, Content } = MainLayout;

const SecurityPrivacyStatement = () => {
  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton />
        </Header.Left>
      </Header>
      <Content>
        <Container modifiers={['padding_2']}>
          <h2>Gazuntite HIPAA Security & Privacy Statements</h2>
          <Text modifiers={['block']}>
            As an organization that provides electronic medical referral and
            telehealth services, Gazuntite acknowledges the imperative to
            safeguard patient information and ensure the privacy and security of
            Protected Health Information (PHI).
          </Text>
          <Text modifiers={['block']}>
            To safeguard Protected Health Information (PHI), Gazuntite has
            implemented the following measures to handle and protect all PHI in
            accordance with the Health Insurance Portability and Accountability
            Act (HIPAA).
          </Text>
          <h3>HIPAA Security Statement</h3>
          <ol type="1">
            <li>
              Gazuntite has instituted a set of formal corporate policies
              designed to safeguard the confidentiality and integrity of
              protected health information (PHI). These policies outline the
              appropriate protocols for accessing, handling, and implementing
              security measures for PHI stored within Gazuntite's cloud
              infrastructure or accessed by its employees while providing
              services to customers.
            </li>
            <li>
              Gazuntite does not use, access, or disclose Protected Health
              Information (PHI) unless it is deemed essential to provide
              services to its customers and clients in a manner that aligns with
              its contractual obligations or as mandated or permitted by
              applicable laws.
            </li>
            <li>Gazuntite does not sell PHI.</li>
            <li>
              Gazuntite has implemented comprehensive safeguards designed to
              uphold the security and integrity of Protected Health Information
              (PHI) in electronic format under its purview. These safeguards
              meet or exceed the mandatory implementation specifications
              outlined within the Health Insurance Portability and
              Accountability Act (HIPAA) Security Rule.
            </li>
            <li>
              Gazuntite encrypts Protected Health Information (PHI) data in
              transit and at rest using industry standard algorithms and
              protocols.
            </li>
            <li>
              Gazuntite maintains secure backups of all PHI and has a disaster
              recovery plan in place to ensure data availability.
            </li>
            <li>
              Gazuntite maintains logs of access and activity on systems
              processing PHI and regularly reviews these logs to detect
              unauthorized access.
            </li>
            <li>
              Gazuntite requires all subcontractors that may access PHI to sign
              Subcontractor Business Associate Agreements, ensuring they adhere
              to the same HIPAA security standards.
            </li>
            <li>
              In the event of a breach involving PHI, Gazuntite will comply with
              HIPAA’s Breach Notification Rule, including notifying clients
              promptly so they can take appropriate action. Gazuntite will
              assist in any necessary investigations and provide required
              details about the breach, including the scope and impact.
            </li>
            <li>
              Gazuntite's policies and procedures regarding the handling and
              access of Protected Health Information (PHI) are communicated to
              all employees who may encounter PHI. Gazuntite requires all
              employees to sign confidentiality agreements. Gazuntite employees
              are subject to sanctions in the event they violate these policies,
              procedures, or agreements.
            </li>
          </ol>
          <h3>HIPAA Privacy Statement</h3>
          <ol type="1">
            <li>
              Gazuntite only collects, uses, and discloses PHI as necessary to
              provide the aforementioned services. This may include storing,
              processing, or transmitting PHI on behalf of clients or patients.
              Gazuntite will not use or disclose PHI for any purposes not
              permitted under our Business Associate Agreements (BAA) or
              required by law.
            </li>
            <li>
              Gazuntite has implemented appropriate administrative, physical and
              technical safeguards to ensure the confidentiality, integrity, and
              availability of PHI that is processed. These safeguards include:
              <ul>
                <li>
                  Encryption: Encryption protocols for PHI both in transit and
                  at rest.
                </li>
                <li>
                  Access Control: Access to PHI is limited to authorized
                  personnel only and based on the minimum necessary rule.
                </li>
                <li>
                  Monitoring and Auditing: Systems are regularly monitored for
                  unauthorized access or disclosure and audits are performed to
                  ensure compliance.
                </li>
                <li>
                  Employee Training: All employees undergo HIPAA-specific
                  privacy and security training to understand their
                  responsibilities in protecting PHI.
                </li>
              </ul>
            </li>
            <li>
              Gazuntite enters into Business Associate Agreements (BAAs) with
              all clients and subcontractors who may handle PHI on our behalf.
              These agreements outline our obligations to protect PHI in
              accordance with HIPAA, including using or disclosing PHI only for
              the agreed-upon purposes and implementing safeguards to protect
              it.
            </li>
            <li>
              Patient’s rights are determined by the Covered Entity that
              collects the information. Gazuntite works with clients to ensure
              compliance with HIPAA, including facilitating the rights of
              patients to access, amend, or receive an accounting of disclosures
              of their PHI.
            </li>
          </ol>
          <Text modifiers={['block']}>
            Gazuntite is committed to maintaining compliance with the Health
            Insurance Portability and Accountability Act (HIPAA) and will modify
            the aforementioned policies and procedures as legislation impacting
            Protected Health Information (PHI) evolves.
          </Text>
        </Container>
      </Content>
    </MainLayout>
  );
};

export default SecurityPrivacyStatement;

import React, { useContext, useEffect, useState, useMemo, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';

import { FETCH_PATIENT_DIAGNOSES } from 'graphql/queries';
import { Row, Column, Text, ScrollView } from 'common';

import { Container, Loader, PeriodPicker } from 'common';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import { EVENTS, SocketContext } from 'contexts/socket';
import Item from './Item';
import { displayTime } from 'utils/time';

const Diagnosis = () => {
  const { patient, records, setRecords } = useContext(PatientHomeContext);
  const [fetchDiagnoses, { loading }] = useLazyQuery(FETCH_PATIENT_DIAGNOSES, {
    onCompleted: ({ patientDiagnoses }) => {
      const newDiagnosis = records.diagnosis;
      newDiagnosis.list = patientDiagnoses;
      setRecords({ ...records, diagnosis: newDiagnosis });
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });
  const { subscribe } = useContext(SocketContext);
  const notesListUpdateSubscription = useRef();

  const fetchPastDiagnoses = (period = null) => {
    fetchDiagnoses({
      variables: {
        patient: patient._id,
        period: period || records.diagnosis.period,
      },
    });
  };

  const handleSelectPeriod = (value) => {
    const diagnosis = records.diagnosis;
    if (value !== diagnosis.period) {
      diagnosis.period = value;
      diagnosis.list = [];
      setRecords({ ...records, diagnosis });
      if (!!patient) {
        fetchPastDiagnoses(value);
      }
    }
  };

  useEffect(() => {
    if (!patient) {
      return;
    }
    fetchPastDiagnoses();
  }, [patient]);

  const diagnoses = useMemo(() => {
    const diagnoses = {};
    records.diagnosis?.list?.forEach((diagnosis) => {
      const date = displayTime(diagnosis.date, 'MM/DD/yyyy');
      if (!diagnoses[date]) {
        diagnoses[date] = [];
      }
      diagnoses[date].push({
        value: diagnosis,
        code: diagnosis.code,
      });
    });
    return diagnoses;
  }, [records]);

  useEffect(() => {
    notesListUpdateSubscription.current?.unsubscribe();
    notesListUpdateSubscription.current = subscribe(
      EVENTS.PATIENT_NOTES,
      (payload) => {
        if (payload?.patient === patient?._id) {
          fetchPastDiagnoses();
        }
      }
    );
    return () => {
      notesListUpdateSubscription.current?.unsubscribe();
    };
  }, [fetchPastDiagnoses, patient]);

  return (
    <Container modifiers={['flexBox', 'fullHeight']}>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Text modifiers={['medium', 'bold']}>Diagnosis List</Text>
          <Column>
            <PeriodPicker
              value={records.notes.period}
              handleSelect={handleSelectPeriod}
            />
          </Column>
        </Row>
      </Container>
      <ScrollView>
        {loading ? (
          <Row modifiers={['center', 'withGutters']}>
            <Loader.Icon />
          </Row>
        ) : Object.values(diagnoses).length > 0 ? (
          <Container modifiers={['fluid']}>
            {Object.keys(diagnoses).map((date) => (
              <Item key={date} diagnoses={diagnoses[date]} date={date} />
            ))}
          </Container>
        ) : (
          <Text>No diagnosis found.</Text>
        )}
      </ScrollView>
    </Container>
  );
};

export default Diagnosis;

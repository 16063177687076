import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';

import { MainLayout } from 'layouts';
import assets from 'assets';
import {
  Button,
  BirthdayPicker,
  Row,
  Column,
  ListView,
  BackButton,
  SearchBar,
} from 'common';
import { FETCH_PATIENTS } from 'graphql/queries';
import { loadingVar } from 'graphql/cache';
import { SEARCH_DEBOUNCE_TIME } from 'utils/constants';
import urls from 'routes/urls';

const Filter = styled(Row)`
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`;

const Patients = ({ history }) => {
  const [birthDate, setBirthDate] = useState();
  const [query, setQuery] = useState('');
  const [showType, setShowType] = useState('list');
  const [fetchPatients, { data: { patients } = {}, loading }] =
    useLazyQuery(FETCH_PATIENTS);
  loadingVar(loading);

  const handleSearch = useCallback(
    debounce((query, birthDate) => {
      fetchPatients({
        variables: {
          query,
          birthday: birthDate,
          inPractice: 'yes',
          page: 0,
          pageSize: 100,
        },
      });
    }, SEARCH_DEBOUNCE_TIME),
    [fetchPatients]
  );

  const handleQueryChange = (value) => {
    setQuery(value);
    handleSearch(value, birthDate);
  };

  const handleClickPatient = (patient) => {
    history.replace(`/patients/${patient._id}`);
  };

  return (
    <MainLayout>
      <MainLayout.Header>
        <MainLayout.Header.Left>
          <BackButton />
        </MainLayout.Header.Left>
        <MainLayout.Header.Center>
          <SearchBar
            onChange={handleQueryChange}
            autoFocus
            placeholder={'Patient Name/SS#'}
          />
        </MainLayout.Header.Center>
        <MainLayout.Header.Right>
          <Button
            modifiers={['transparent', 'icon']}
            onClick={() => history.push(urls.ADD_PATIENT)}
            image={assets.icons.iconPlusMain}
          >
            patient
          </Button>
        </MainLayout.Header.Right>
      </MainLayout.Header>
      <MainLayout.Content>
        <Filter modifiers={['middle']}>
          <Column modifiers={['col']}>
            <BirthdayPicker
              label="Select DOB"
              onChange={setBirthDate}
              onClose={(date) => handleSearch(query, date)}
            />
          </Column>
          <Column>
            {/* <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.searchBox}
            imageDisabled={assets.icons.searchBoxDisabled}
            // title={'search'}
            disabled={!birthDate && true}
            onClick={() => handleSearch(query, birthDate)}
            imageWidth={40}
            imageHeight={40}
          /> */}
            {showType === 'list' && (
              <Button
                modifiers={['icon', 'light']}
                image={assets.icons.list2}
                onClick={() => setShowType('grid')}
              />
            )}
            {showType === 'grid' && (
              <Button
                modifiers={['icon', 'light']}
                image={assets.icons.grid}
                onClick={() => setShowType('list')}
              />
            )}
          </Column>
        </Filter>
        <ListView
          records={patients}
          showType={showType}
          onClickItem={handleClickPatient}
        ></ListView>
      </MainLayout.Content>
    </MainLayout>
  );
};

export default Patients;

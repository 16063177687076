import React from 'react';

import { MainLayout } from 'layouts';
import { BackButton } from 'common';

const { Header, Content } = MainLayout;

const NotFound = () => {
  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton />
        </Header.Left>
      </Header>
      <Content>Not Found</Content>
    </MainLayout>
  );
};

export default NotFound;

import { useState, useCallback, useEffect, useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Row, ScrollView, Text, Button, BackButton } from 'common';

import { Container, Modal, Popover } from 'common';
import MedicationItem from '../Careplans/CreateCareplan/Medications/Item';
import CartConfirm from './CartConfirm';
import PaymentMethod from './PaymentMethod';
import assets from 'assets';
import { PatientHomeContext } from '../Context';
import { loadingVar } from 'graphql/cache';
import { FETCH_PAYMENT_METHODS } from 'graphql/queries';
import { ORDER_MEDS } from 'graphql/mutations';

const MedsCart = ({ medications, updateMedications, handleClose }) => {
  const [fetchPaymentMethods] = useLazyQuery(FETCH_PAYMENT_METHODS, {
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });
  const [paymentMethods, updatePaymentMethods] = useState([]);
  const [deletingMedication, setDeletingMedication] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showViewPayments, setShowViewPayments] = useState(false);
  const { goBack, patient } = useContext(PatientHomeContext);
  const [createOrder] = useMutation(ORDER_MEDS, {
    onCompleted: () => {
      goBack();
    },
    onError: () => {},
  });

  useEffect(() => {
    if (Object.values(medications).length === 0) {
      handleClose();
    }
  }, [medications]);

  const onRemove = useCallback((medication) => {
    setShowDeleteModal(true);
    setDeletingMedication(medication);
  }, []);

  const onConfirmDelete = () => {
    delete medications[deletingMedication.ndc];
    setShowDeleteModal(false);
    updateMedications({ ...medications });
  };

  const handleViewPaymentMethod = () => {
    setShowViewPayments(true);
  };

  const handleShowConfirm = () => {
    // if (!(paymentMethods?.length > 0)) {
    //   Modal.info({
    //     render: () => (
    //       <Container>
    //         <Text modifiers={['block', 'center']}>
    //           No payment method is added.
    //         </Text>
    //         <Text modifiers={['block', 'center']}>
    //           Please add credit card information.
    //         </Text>
    //       </Container>
    //     ),
    //   });
    //   return;
    // }
    for (let medication of Object.values(medications)) {
      if (
        !medication.quantity ||
        isNaN(medication.quantity) ||
        parseFloat(medication.quantity) <= 0
      ) {
        Modal.info({
          text: 'Please enter quantity to order medications.',
        });
        return;
      }
    }
    setShowConfirmModal(true);
  };

  const handleConfirmOrder = async () => {
    loadingVar(true);
    await createOrder({
      variables: {
        medications: Object.values(medications),
        patient: patient._id,
      },
    });
    loadingVar(false);
  };

  useEffect(() => {
    loadingVar(true);
    fetchPaymentMethods({
      variables: {
        patient: patient._id,
      },
      onCompleted: (data) => {
        loadingVar(false);
        updatePaymentMethods(data.paymentMethods || []);
      },
    });
  }, []);

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle']}>
          <BackButton onClick={handleClose} />
          <Text>Med Order Cart</Text>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        <Container modifiers={['fluid', 'flexBox', 'fullHeight']}>
          <ScrollView>
            {Object.entries(medications).map(([ndc, medication]) => (
              <MedicationItem
                medication={medication}
                key={ndc}
                noAlerts
                onRemove={() => {
                  onRemove(medication);
                }}
              />
            ))}
          </ScrollView>
          <Container modifiers={['flexFixed', 'shadow', 'backgroundWhite']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                image={assets.icons.icCloseGray}
                modifiers={['icon', 'transparent']}
                onClick={handleClose}
              />
              {/* <Button
                modifiers={['widthMedium', 'roundCorner']}
                onClick={handleViewPaymentMethod}
              >
                Payment Method
              </Button> */}
              <Button
                modifiers={['widthMedium', 'roundCorner']}
                onClick={handleShowConfirm}
              >
                Send Order
              </Button>
            </Row>
          </Container>
        </Container>
        <Popover
          open={showDeleteModal}
          onConfirm={onConfirmDelete}
          onClose={() => setShowDeleteModal(false)}
          footer
        >
          <Text modifiers={['block', 'center']}>
            Are you sure want to delete this medication?
          </Text>
        </Popover>
        <Modal open={showConfirmModal} contained large>
          <CartConfirm
            medications={medications}
            updateMedications={updateMedications}
            handleClose={() => setShowConfirmModal(false)}
            handleConfirm={handleConfirmOrder}
          />
        </Modal>
        {showViewPayments && (
          <PaymentMethod
            methods={paymentMethods}
            updateMethods={updatePaymentMethods}
            handleClose={() => setShowViewPayments(false)}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MedsCart;

import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ScrollView, Button } from 'common';
import { cloneDeep } from 'lodash';

import { FETCH_OPERATION_SCHEDULE } from 'graphql/queries';
import { UPDATE_OPERATION_SCHEDULE } from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import { Row, Column, Text, Container, Modal } from 'common';
import { SimpleLayout } from 'layouts';
import Schedule from './ScheduleSection';
import assets from 'assets';
import { ProviderContext } from 'contexts/provider';

export default ({}) => {
  const history = useHistory();
  const { updateSchedulerSet } = useContext(ProviderContext);
  const [fetchQuery, { data: { operationSchedule } = {} }] = useLazyQuery(
    FETCH_OPERATION_SCHEDULE,
    {
      fetchPolicy: 'no-cache',
      initialFetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    }
  );
  const [updateScheduleApi] = useMutation(UPDATE_OPERATION_SCHEDULE);
  const [schedules, updateSchedules] = useState();
  const [occupiedDays, updateOccupiedDays] = useState([]);

  const goBack = useCallback(() => {
    history.replace('/');
  }, []);
  const handleDone = async () => {
    const payload = cloneDeep(schedules).filter(
      (schedule) => schedule.days.length > 0
    );
    payload.forEach((schedule) => {
      if (!schedule.breakOn) {
        schedule.breaks = [];
      }
    });
    if (payload.length === 0) {
      Modal.info({ text: 'Please select days' });
    } else {
      loadingVar(true);
      await updateScheduleApi({
        variables: {
          schedule: payload,
        },
      });
      updateSchedulerSet(payload?.length > 0);
      loadingVar(false);
      goBack();
    }
  };
  const handleAddSchedule = () => {
    updateSchedules([
      ...schedules,
      {
        days: [],
        from: '08:00 AM',
        to: '06:30 PM',
        breakOn: false,
        breaks: [],
        duration: 5,
      },
    ]);
  };

  useEffect(() => {
    if (!operationSchedule) {
      updateSchedules([]);
      updateOccupiedDays([]);
    } else {
      updateSchedules(cloneDeep(operationSchedule));
      checkOccupiedDays(operationSchedule);
    }
  }, [operationSchedule]);

  const handleUpdateSchedule = (daysSelected) => {
    updateSchedules([...schedules]);
    if (daysSelected) {
      checkOccupiedDays(schedules);
    }
  };

  const checkOccupiedDays = useCallback((schedules) => {
    let days = [];
    schedules.forEach((schedule) => (days = [...days, ...schedule.days]));
    updateOccupiedDays(days);
  }, []);

  const handleRemoveSchedule = (index) => {
    Modal.confirm({
      title: 'Delete Schedule',
      text: 'Are you sure want to delete this schedule?',
      onOk: () => {
        schedules.splice(index, 1);
        updateSchedules([...schedules]);
        checkOccupiedDays(schedules);
      },
    });
  };
  useEffect(async () => {
    loadingVar(true);
    await fetchQuery();
    loadingVar(false);
  }, []);

  return (
    <SimpleLayout>
      <SimpleLayout.Header content="Schedule Set Up"></SimpleLayout.Header>
      <SimpleLayout.Content>
        <Container modifiers={['backgroundWhite', 'fullHeight', 'flexBox']}>
          <ScrollView>
            {schedules && (
              <Container>
                {schedules.length > 0 ? (
                  schedules.map((schedule, index) => (
                    <Container
                      key={index}
                      modifiers={['fluid', 'bottomGutters_2']}
                    >
                      <Row modifiers={['spaceBetween', 'middle']}>
                        <Column modifiers={['col']}>
                          <Text modifiers={['block', 'center', 'primary']}>
                            Schedule {index + 1}
                          </Text>
                        </Column>
                        <Button
                          modifiers={['icon', 'transparent']}
                          image={assets.icons.icCloseGray}
                          onClick={() => handleRemoveSchedule(index)}
                          width={20}
                          height={20}
                        />
                      </Row>
                      <Schedule
                        schedule={schedule}
                        handleUpdate={handleUpdateSchedule}
                        occupiedDays={occupiedDays}
                      />
                    </Container>
                  ))
                ) : (
                  <Container>
                    <Text modifiers={['medium', 'block']}>
                      You have no schedule set up.
                    </Text>
                  </Container>
                )}
                <Row modifiers={['withGutters', 'end']}>
                  <Button
                    modifiers={['roundCorner', 'widthMedium']}
                    onClick={handleAddSchedule}
                  >
                    Add Schedule
                  </Button>
                </Row>
              </Container>
            )}
          </ScrollView>
        </Container>
      </SimpleLayout.Content>
      <SimpleLayout.Footer>
        <Row modifiers={['middle', 'spaceBetween']}>
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icCloseGray}
            onClick={goBack}
          />
          <Button
            modifiers={[
              'widthSmall',
              'roundCorner',
              (!schedules || schedules.length === 0) && 'disabled',
            ]}
            onClick={handleDone}
          >
            Done
          </Button>
        </Row>
      </SimpleLayout.Footer>
    </SimpleLayout>
  );
};

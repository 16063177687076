import React, { forwardRef } from 'react';
import { Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';

import {
  Container,
  Row,
  Column as Col,
  Avatar,
  FormInput,
  TextArea,
} from 'common';
import { FormFileInput } from 'common';
import assets from 'assets';
import schema from 'utils/schema';

const groupSchema = Yup.object().shape({
  name: Yup.string().required('Group name is required.'),
  image: schema.file,
});

const initialValues = {
  name: '',
  description: '',
};

const Form = forwardRef(({ groupType }, ref) => {
  return (
    <Formik
      innerRef={ref}
      initialValues={initialValues}
      validationSchema={groupSchema}
      onSubmit={() => {}}
    >
      {({ setFieldValue, values }) => (
        <FormikForm>
          <Container>
            <Row>
              <Col modifiers={['col_3']}>
                <Field
                  required
                  name="image"
                  component={FormFileInput}
                  s3={{ folder: 'groups' }}
                  modifiers={['round', 'flexColumn']}
                  accept="image/*"
                  width={60}
                  height={60}
                  addText="Add Image"
                  editText="Edit"
                >
                  <Avatar
                    modifiers={['round']}
                    width={40}
                    height={40}
                    image={assets.icons.icEdit}
                  />
                </Field>
              </Col>
              <Col modifiers={['col_9']}>
                <Field
                  name="name"
                  modifiers={['squared']}
                  component={FormInput}
                  hasLabel
                  required
                  placeholder="Group's Name"
                />
              </Col>
            </Row>
            <Row>
              <Col modifiers={['col_12']}>
                <Field
                  name="description"
                  component={TextArea}
                  placeholder="Write a brief group description"
                  modifiers={[
                    'borderTransparent',
                    'smallContainer',
                    'legacyChatInput',
                  ]}
                  value={values.description}
                  onChange={({ target: { value } }) =>
                    setFieldValue('description', value)
                  }
                />
              </Col>
            </Row>
          </Container>
        </FormikForm>
      )}
    </Formik>
  );
});
export default Form;

import { useContext, useEffect, useState, useCallback } from 'react';
import { Row, Column, Text, Button, ScrollView } from 'common';

import { Container, Loader, PeriodPicker } from 'common';
import { CreateNoteContext } from '../Context';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import { FETCH_NOTE_ITEM_PAST_RECORDS } from 'graphql/queries';
import PastItem from './PastItem';
import { useLazyQuery } from '@apollo/client';
import assets from 'assets';

export default ({ selectable, handleAddFromPast, handleAdd }) => {
  const { patient } = useContext(PatientHomeContext);
  const { itemsPickedPeriods, setItemsPickedPeriods, goBack } =
    useContext(CreateNoteContext);
  const [pastAttachments, setPastAttachments] = useState([]);
  const [pastSelection, setPastSelection] = useState({});
  const [fetchItemRecords] = useLazyQuery(FETCH_NOTE_ITEM_PAST_RECORDS);

  const fetchRecords = useCallback(
    (period) => {
      // setPastDiagnoses(null);
      return;
      fetchItemRecords({
        variables: {
          type: 'attachments',
          period,
          patient: patient._id,
        },
        onCompleted: (data) => {
          setPastAttachments(data.noteItemPastRecords.attachments);
          setPastSelection({});
        },
      });
    },
    [patient]
  );

  const handleSelectPeriod = (value) => {
    setItemsPickedPeriods({ ...itemsPickedPeriods, attachments: value });
    fetchRecords(value);
  };

  const toggleSelectionPast = (index, attachment) => {
    if (pastSelection[index]) {
      delete pastSelection[index];
    } else {
      pastSelection[index] = attachment;
    }
    setPastSelection({ ...pastSelection });
  };

  const handleDone = () => {
    handleAdd(Object.values(pastSelection));
    goBack();
  };

  useEffect(() => {
    if (!patient) {
      return;
    }
    fetchRecords(itemsPickedPeriods.attachments);
  }, [patient]);

  return (
    <Container modifiers={['flexBox', 'fullHeight', 'fluid']}>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Column>
            <Text modifiers={['bold']}>Past Attachments</Text>
          </Column>
          <Column>
            <PeriodPicker
              value={itemsPickedPeriods.attachments}
              handleSelect={handleSelectPeriod}
            />
          </Column>
        </Row>
        {!selectable && (
          <Container>
            <Button
              modifiers={[selectable && 'disabled', 'roundCorner', 'fullWidth']}
              onClick={handleAddFromPast}
            >
              + ADD FROM PAST ATTACHMENTS
            </Button>
          </Container>
        )}
      </Container>
      <ScrollView modifiers={['flexWeight_2']}>
        <Container>
          {/* {pastDiagnoses === null ? (
            <Row modifiers={['center']}>
              <Loader.Icon />
            </Row>
          ) : ( */}
          {pastAttachments.map((attachment, index) => (
            <PastItem
              item={attachment}
              key={index}
              selected={pastSelection[index]}
              selectable={selectable}
              handleChange={() => {
                toggleSelectionPast(index, attachment);
              }}
            />
          ))}
          {/* )} */}
        </Container>
      </ScrollView>
      {selectable && (
        <Container modifiers={['footer']}>
          <Row modifiers={['spaceBetween', 'middle']}>
            <Button
              modifiers={['icon', 'transparent']}
              image={assets.icons.icCloseGray}
              onClick={goBack}
            />
            <Button
              modifiers={[
                'widthMedium',
                'roundCorner',
                Object.keys(pastSelection).length === 0 && 'disabled',
              ]}
              onClick={handleDone}
            >
              Add Selected
            </Button>
          </Row>
        </Container>
      )}
    </Container>
  );
};

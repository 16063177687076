import { useState } from 'react';

import { Checkbox, Column, Button } from 'common';

import { Container, Row, SlidingPane, Text } from 'common';
import { medicationModsTitle, frequencyTitle } from 'utils/helper';
import assets from 'assets';
import { startCase } from 'lodash';

const GroupSelectionModal = ({
  isVisible,
  type,
  value,
  medications,
  onConfirm,
  onClose,
}) => {
  const [selectedMedications, updateSelectedMedications] = useState({});
  const titles =
    type === 'frequency'
      ? frequencyTitle(value, true)
      : type === 'alerts'
      ? `Allowed skip ${value.triggerValue} ${value.triggerType}`
      : medicationModsTitle(value);
  const toggleSelection = (medication) => {
    selectedMedications[medication.ndc]
      ? delete selectedMedications[medication.ndc]
      : (selectedMedications[medication.ndc] = medication);
    updateSelectedMedications({ ...selectedMedications });
  };

  const handleConfirm = () => {
    onConfirm(selectedMedications);
    onClose();
  };

  return (
    <SlidingPane
      isOpen={isVisible}
      handleClose={onClose}
      modifiers={['fillHeight']}
    >
      <SlidingPane.Header modifiers={['hasBorder']}>
        <Text modifiers={['h2', 'bold', 'darkGrey', 'center', 'block']}>
          GROUP {type.toUpperCase()}
        </Text>
      </SlidingPane.Header>
      <SlidingPane.Content>
        <Row modifiers={['smallGutters']}>
          <Text modifiers={['medium', 'block', 'semiBold', 'secondary']}>
            {startCase(type)} Detail
          </Text>
        </Row>
        {type === 'frequency' ? (
          <>
            <Row modifiers={['smallGutters']}>
              <Text modifiers={['medium']}>Freq: </Text>
              <Text modifiers={['primary', 'medium']}>{titles?.frequency}</Text>
            </Row>
            <Row modifiers={['smallGutters']}>
              <Text modifiers={['medium']}>On: </Text>
              <Text modifiers={['primary', 'medium']}>{titles?.on}</Text>
            </Row>
            <Row modifiers={['smallGutters']}>
              <Text modifiers={['medium']}>@: </Text>
              <Column modifiers={['col', 'noPadding']}>
                <Text modifiers={['primary', 'medium']}>{titles?.at}</Text>
              </Column>
            </Row>
          </>
        ) : (
          <Row modifiers={['smallGutters']}>
            <Text modifiers={['orange', 'medium']}>{titles}</Text>
          </Row>
        )}
        <Row modifiers={['topGutters_2', 'bottomGutters_1']}>
          <Text modifiers={['medium', 'block', 'bold', 'secondary']}>
            Select the medications you would like to have this same {type}
          </Text>
        </Row>
        {Object.entries(medications).map(([ndc, medication]) => (
          <Row
            key={ndc}
            modifiers={['smallGutters']}
            onClick={() => toggleSelection(medication)}
          >
            <Checkbox
              dark={true}
              modifiers={['small', 'margin_1']}
              checked={!!selectedMedications[medication.ndc]}
              onChange={() => {}}
            />
            <Column modifiers={['col']}>
              <Text>{medication.name}</Text>
            </Column>
          </Row>
        ))}
        <Row modifiers={['withGutters']}>
          <Text modifiers={['block', 'success', 'medium']}>
            You have selected {Object.keys(selectedMedications).length}{' '}
            medications with the above {type}.
          </Text>
        </Row>
      </SlidingPane.Content>
      <Container modifiers={['flexBox', 'footer']}>
        <Row modifiers={['spaceBetween']}>
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icCloseGray}
            onClick={onClose}
          />
          <Button
            modifiers={[
              'primary',
              'roundCorner',
              'widthMedium',
              Object.keys(selectedMedications).length === 0 && 'disabled',
            ]}
            onClick={handleConfirm}
          >
            <Text modifiers={['subtitle', 'semiBold', 'white']}>
              Group {startCase(type)}{' '}
              {Object.keys(selectedMedications).length > 0
                ? `(${Object.keys(selectedMedications).length})`
                : ''}
            </Text>
          </Button>
        </Row>
      </Container>
    </SlidingPane>
  );
};

export default GroupSelectionModal;

import React, { useState } from 'react';

import { Button, Avatar } from 'common';
import assets from 'assets';

import Wrapper from './Wrapper';
import Card from './Card';

export const ListView = ({
  records,
  hasShowType,
  hasInvite,
  idField = '_id',
  onClickItem,
  extraFields,
  ...props
}) => {
  const [showType, setshowType] = useState('list');
  const hasExtraFields = Boolean(extraFields);

  if (!records) {
    return null;
  }

  return (
    <Wrapper modifiers={[hasShowType ? showType : props.showType]}>
      <div>
        <div>
          <b>{records.length}</b> Results
        </div>
        {hasShowType && showType === 'list' && (
          <Button
            modifiers={['icon', 'light']}
            image={assets.icons.list2}
            onClick={() => setshowType('grid')}
          />
        )}
        {hasShowType && showType === 'grid' && (
          <Button
            modifiers={['icon', 'light']}
            image={assets.icons.grid}
            onClick={() => setshowType('list')}
          />
        )}
      </div>
      {records.map((record) => (
        <Card
          modifiers={[hasShowType ? showType : props.showType]}
          key={record[idField]}
          onClick={() => {
            onClickItem(record);
          }}
        >
          <Avatar
            modifiers={['round']}
            image={record.photo?.url || assets.images.emptyAvatar}
            width={40}
            height={40}
          />
          <span>{`${record.firstName} ${record.lastName}`}</span>
          {hasInvite && (
            <Button modifiers={['outlineSecondary', 'small']}>Invite</Button>
          )}
          {hasExtraFields && extraFields(record)}
        </Card>
      ))}
    </Wrapper>
  );
};

export default ListView;

import { Container, Column, Row, Text } from 'common';
import { formatTitleAndName } from 'utils/string';
import { toLocalTime, careplanDates } from 'utils/time';

const InfoRow = ({ title, info, children }) => (
  <Row>
    <Column>
      <Text>{title}</Text>
    </Column>
    <Column>
      {children ? children : <Text modifiers={['primary']}>{info}</Text>}
    </Column>
  </Row>
);

const InfoSection = ({ careplan }) => {
  const [startDate, endDate] = careplanDates(careplan);
  return (
    <Container>
      <InfoRow
        title="Created By:"
        info={formatTitleAndName(careplan.creator.user)}
      />
      <InfoRow
        title="Date Created:"
        info={careplan.signDate ? toLocalTime(careplan.signDate, 'date') : ''}
      />
      <InfoRow title="Duration:" info={`${careplan.duration} Days`} />
      <InfoRow title="From:">
        <Text modifiers={['primary']}>{startDate}</Text> to{' '}
        <Text modifiers={['primary']}>{endDate}</Text>
      </InfoRow>
      <InfoRow title="Patient:" info={formatTitleAndName(careplan.user)} />
    </Container>
  );
};

export default InfoSection;

import { useEffect, useState, useCallback } from 'react';
import { ScrollView, Column, Text, Button } from 'common';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';

import { Container, DatePicker, Row, Popover } from 'common';
import { displayTime } from 'utils/time';
import { loadingVar } from 'graphql/cache';
import { FETCH_AVAILABLE_TIMES } from 'graphql/queries';

export default function BookAppointment({ provider, date: initDate, getRef }) {
  const [selectedDate, setSelectedDate] = useState(initDate || new Date());
  const [selectedSlot, setSelectedSlot] = useState();
  const [timeSlots, setTimeSlots] = useState([]);

  const handleChangeDate = useCallback((date) => {
    setSelectedDate(date);
  }, []);

  const [fetchAvailableSlots] = useLazyQuery(FETCH_AVAILABLE_TIMES, {
    onCompleted: ({ availableTimes }) => {
      if (!availableTimes) {
        return;
      }
      const slots = availableTimes.map((range) => {
        const start = new Date(range.start);
        const end = new Date(range.end);
        return {
          time: start,
          end,
          text: displayTime(start, 'hh:mm a'),
        };
      });
      setTimeSlots(slots);
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  useEffect(() => {
    async function fetchData() {
      setSelectedSlot(null);
      setTimeSlots([]);
      if (!provider || !selectedDate) {
        return;
      }
      loadingVar(true);
      const midnightTimestamp = new Date(
        displayTime(selectedDate, 'date')
      ).getTime();
      const from = new Date(Math.max(midnightTimestamp, new Date().getTime()));
      const to = new Date(midnightTimestamp + 24 * 3600 * 1000);
      const offset = moment().utcOffset();
      await fetchAvailableSlots({
        variables: { provider: provider._id, from, to, offset },
      });
      loadingVar(false);
    }
    fetchData();
  }, [provider, selectedDate, fetchAvailableSlots]);

  getRef.current = () => {
    return {
      time: selectedSlot?.time,
    };
  };

  return (
    <Container modifiers={['flexOne', 'fullHeight', 'flexBox']}>
      <Container modifiers={['flexFixed', 'fluid']}>
        <Row>
          <Text modifiers={['block', 'h2']}>Select Date and Time</Text>
        </Row>
        <DatePicker
          handleChange={handleChangeDate}
          value={selectedDate}
          minDate={new Date()}
        >
          <Row>
            <Column modifiers={['col_4', 'noPadding']}>
              <Text modifiers={['block', 'medium', 'center']}>Day</Text>
            </Column>
            <Column modifiers={['col_4', 'noPadding']}>
              <Text modifiers={['block', 'medium', 'center']}>Date</Text>
            </Column>
            <Column modifiers={['col_4', 'noPadding']}>
              <Text modifiers={['block', 'medium', 'center']}>Start</Text>
            </Column>
          </Row>
          <Row modifiers={['smallGutters', 'borderGray', 'roundCorner']}>
            <Column modifiers={['col_4']}>
              <Text modifiers={['block', 'medium', 'center']}>
                {displayTime(selectedDate, 'dddd')}
              </Text>
            </Column>
            <Column modifiers={['col_4']}>
              <Text modifiers={['block', 'medium', 'center', 'primary']}>
                {displayTime(selectedDate, 'MM/DD/YY')}
              </Text>
            </Column>
            <Column modifiers={['col_4']}>
              <Text modifiers={['block', 'medium', 'center']}>
                {selectedSlot
                  ? displayTime(selectedSlot.time, 'hh:mm a')
                  : '-- --'}
              </Text>
            </Column>
          </Row>
        </DatePicker>
      </Container>
      <Container modifiers={['flexFixed', 'fluid']}>
        <Text modifiers={['block', 'h2']}>Available time slots</Text>
      </Container>
      <ScrollView modifiers={['noPadding']}>
        <Row>
          {timeSlots?.length > 0 ? (
            timeSlots.map((slot, index) => (
              <Column modifiers={['col_4', 'noPadding']} key={index}>
                <Row modifiers={['topGutters_1', 'center']}>
                  <Button
                    modifiers={[
                      slot.time === selectedSlot?.time
                        ? null
                        : 'outlinePrimary',
                      'roundCorner',
                      'small',
                    ]}
                    onClick={() => setSelectedSlot(slot)}
                    key={slot.time}
                  >
                    {slot.text}
                  </Button>
                </Row>
              </Column>
            ))
          ) : (
            <Text modifiers={['medium']}>No appointment available</Text>
          )}
        </Row>
      </ScrollView>
    </Container>
  );
}

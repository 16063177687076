import ViewNote from 'pages/Notes/ViewNote';
import { BackButton, Avatar, Row, Column, Text } from 'common';
import { Modal } from 'common';
import { getFullName } from 'utils/string';

export default ({ note, goBack }) => (
  <Modal open>
    <Modal.Header>
      <Row modifiers={['middle']}>
        <BackButton onClick={goBack} />
        <Avatar
          user={note.user}
          width={40}
          height={40}
          modifiers={['squared']}
        />
        <Column modifiers={['col_8', 'flexBox']}>
          <Text modifiers={['semiBold', 'start']}>
            {getFullName(note.user)}
          </Text>
        </Column>
      </Row>
    </Modal.Header>
    <Modal.Body modifiers={['noMargin', 'textLeft']}>
      <ViewNote note={note} goBack={goBack} />
    </Modal.Body>
  </Modal>
);

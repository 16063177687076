import styled from 'styled-components';
import { Row } from 'common';
import { buildStyledComponent } from 'style';

const modifierConfig = {
  practice: () => `
    background-color: #f9f9ff;
  `,
};

const styles = () => `
  background-color: white;
`;

export default buildStyledComponent('Contact_Wrapper', styled(Row), styles, {
  modifierConfig,
});

import React from 'react';
import styled from 'styled-components';
import { Column as Col, Text } from 'common';
import { buildStyledComponent } from 'style';

const Component = ({ measurementValue, ...props }) => (
  <Col
    {...props}
    modifiers={[
      'col_3',
      'middle',
      'center',
      'verticalContentCenter',
      'flexBox',
    ]}
  >
    <Text modifiers={['xSmall', 'center']}>{measurementValue}</Text>
  </Col>
);

const modifierConfig = {
  danger: ({ theme }) => `
    background-color: ${theme.colors.danger_2};
  `,
  success: ({ theme }) => `
    background-color: ${theme.colors.success_2};
  `,
  muted: ({ theme }) => `
    > * {
      color: ${theme.colors.secondary};
    }
  `,
};

const styles = ({ theme }) => `
  background-color: #F3F3F4;
  border: none;
  border-radius: 0px 6px 6px 0px;
  > * {
    color: ${theme.colors.white};
  }
`;

export default buildStyledComponent(
  'VitalHighlight_VitalsItem',
  styled(Component),
  styles,
  {
    modifierConfig,
  }
);

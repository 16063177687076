import styled from 'styled-components';
import { Text } from 'common';
import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  veryBad: () => `
    background-color: #FE4D3C;
  `,
  bad: () => `
    background-color: #FE7D71;
  `,
  medium: () => `
    background-color: #F7B538;
  `,
  good: () => `
    background-color: #35BA83;
  `,
  veryGood: () => `
    background-color: #23AA72;
  `,
  selected: ({ theme }) => `
    border: 3px solid ${theme.colors.primary};
  `,
};

const styles = () => `
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${px2rem(40)};
  height: ${px2rem(40)};
`;

export default buildStyledComponent('Rating_Circle', styled.div, styles, {
  modifierConfig,
});

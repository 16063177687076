import { useMemo } from 'react';
import assets from 'assets';
import { Text, Row, Column as Col, Button } from 'common';
import { displayTime } from 'utils/time';
import { DIET_ITEMS, WELLNESS_ITEMS } from 'utils/constants';
import { getQuestionValueString } from 'utils/helper';
import { NoteItemWrapper } from './NoteItem';
import Hightlight from './Highlight';
import { PROGRESS_ITEMS } from 'utils/constants';

export default ({ type, itemType, measurement = {}, handleSelectItem }) => {
  const itemTypeAttrs = useMemo(
    () =>
      type === 'wellness' ? WELLNESS_ITEMS[itemType] : DIET_ITEMS[itemType],
    [type]
  );
  const { value, danger, success, muted, time } = measurement;
  let title;

  if (!value) {
    title = 'N/A';
  } else {
    title = getQuestionValueString(itemType, value);
  }

  return (
    <NoteItemWrapper
      modifiers={['round']}
      onClick={() => handleSelectItem(itemType)}
    >
      <Row>
        <Col modifiers={['col_9', 'fluid', 'noVerticalPadding']}>
          <Row>
            <Col
              modifiers={[
                'col_2',
                'flexBox',
                'center',
                'verticalContentCenter',
                'noVerticalPadding',
              ]}
            >
              <img src={itemTypeAttrs.icon} alt="" width="25" height="25" />
            </Col>
            <Col modifiers={['col_8', 'noVerticalPadding', 'fluid']}>
              <Text modifiers={['small', 'xSmall', 'block', 'secondaryColor']}>
                {itemTypeAttrs.title}
              </Text>
              <Text modifiers={['xSmall', 'block', 'muted']}>
                {time ? displayTime(time, 'MM/DD/YYYY • hh:mm a') : 'N/A'}
              </Text>
            </Col>
            <Col modifiers={['col_2', 'fluid', 'verticalContentCenter']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icGoNextBig}
                width={32}
                height={32}
              />
            </Col>
          </Row>
        </Col>
        <Hightlight
          measurementValue={title || 'N/A'}
          modifiers={[
            danger && 'danger',
            success && 'success',
            muted && 'muted',
          ]}
        />
      </Row>
    </NoteItemWrapper>
  );
};

import React, { useRef, forwardRef } from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';

import { FormInput, Row, Column, FormPhoneInput } from 'common';
import { FormPicker } from 'common';
import schema from 'utils/schema';
import { STATES, STATES_LIST } from 'utils/constants';
import Notification from './Notification';

const contactSchema = Yup.object().shape({
  phones: schema.phones,
  email: schema.email,
  address: schema.address,
  notifications: Yup.object(),
});

const Contact = forwardRef(({ patient, isEditing, show }, ref) => {
  const initialized = useRef(false);
  if (show) initialized.current = true;

  const initialValues = {
    address: patient.address,
    email: patient.email,
    phones: patient.phones,
    notifications: patient.notifications
      ? JSON.parse(JSON.stringify(patient.notifications))
      : {
          email: {},
          text: {},
          voice: {},
        },
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={contactSchema}
      innerRef={ref}
      onSubmit={() => {}}
    >
      {({ setFieldValue, values }) =>
        initialized.current && (
          <Form style={{ display: show ? null : 'none' }}>
            <Row>
              <Column modifiers="col_12">
                <Field
                  disabled={!isEditing}
                  hasLabel
                  required
                  name="email"
                  placeholder="Email"
                  component={FormInput}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers="col_6">
                <Field
                  disabled={!isEditing}
                  hasLabel
                  required
                  name="phones.mobile"
                  placeholder="Mobile number"
                  component={FormPhoneInput}
                />
              </Column>
              <Column modifiers="col_6">
                <Field
                  disabled={!isEditing}
                  hasLabel
                  name="phones.home"
                  placeholder="Home number"
                  component={FormPhoneInput}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers="col_6">
                <Field
                  disabled={!isEditing}
                  hasLabel
                  name="phones.work"
                  placeholder="Work number"
                  component={FormPhoneInput}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers="col_12">
                <Field
                  disabled={!isEditing}
                  hasLabel
                  name="address.addressLine1"
                  placeholder="Address"
                  component={FormInput}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers="col_12">
                <Field
                  disabled={!isEditing}
                  hasLabel
                  name="address.addressLine2"
                  placeholder="Address Line 2"
                  component={FormInput}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers="col_6">
                <Field
                  disabled={!isEditing}
                  hasLabel
                  name="address.city"
                  placeholder="City"
                  component={FormInput}
                />
              </Column>
              <Column modifiers="col_6">
                <Field
                  disabled={!isEditing}
                  hasLabel
                  name="address.state"
                  placeholder="State"
                  options={STATES}
                  idField="abbreviation"
                  nameField="name"
                  component={FormPicker}
                />
              </Column>
            </Row>
            <Row>
              <Column modifiers="col_6">
                <Field
                  disabled={!isEditing}
                  hasLabel
                  name="address.zipcode"
                  placeholder="Zip"
                  component={FormInput}
                  pattern="\d*"
                  maxLength="5"
                />
              </Column>
            </Row>
            <Notification
              isEditing={isEditing}
              patient={patient}
              value={values.notifications}
              onChange={(value) => setFieldValue('notifications', value)}
            />
          </Form>
        )
      }
    </Formik>
  );
});

export default Contact;

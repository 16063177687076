import styled from 'styled-components';
import { Container } from 'common';
import { buildStyledComponent, px2rem } from 'style';

const styles = ({ theme }) => `
  padding: 0;
  height: 100%;
  overflow: auto;

  :before {
    width: ${px2rem(2)};
    background: white;
  }
`;

export default buildStyledComponent('Wrapper_Table', styled(Container), styles);

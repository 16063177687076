import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import qs from 'query-string';

import { MainLayout } from 'layouts';
import {
  BackButton,
  Avatar,
  Container,
  Row,
  Column,
  Text,
  Button,
} from 'common';

import { CallPad, SlidingPane } from 'common';
import assets from 'assets';
import { getUser } from 'graphql/utils';
import {
  formatAddress,
  formatMemberDesignation,
  formatTitleAndName,
} from 'utils/string';
import { loadingVar } from 'graphql/cache';
import urls from 'routes/urls';

import CardContainer from './CardContainer';
import { CALL_TYPES, CallServiceContext } from 'contexts/call';

const { Header, Content } = MainLayout;

export default function ContactProfile() {
  const [isEditing, setIsEditing] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const [provider, setProvider] = useState(null);
  const [showNumberPad, setShowNumberPad] = useState(false);
  const { startCall } = useContext(CallServiceContext);

  useEffect(() => {
    (async () => {
      loadingVar(true);
      const user = await getUser(id);
      setProvider(user);
      loadingVar(false);
    })();
  }, [id]);

  if (!provider) {
    return null;
  }

  if (provider.status === 'new') {
    throw Error('The provider did not finish onboarding yet.');
  }

  const practice = provider.activeProviderPractice?.practice || {};

  const handleVideoCall = () => {
    startCall(CALL_TYPES.UNSCHEDULED, provider);
  };

  const initPSTN = (type) => {
    const phoneNo = provider.phones[type];
    if (!phoneNo) {
      return;
    }
    startCall(CALL_TYPES.PSTN, provider, null, { number: phoneNo, type });
  };

  const handleCallPSTN = (number) => {
    setShowNumberPad(false);
    startCall(CALL_TYPES.PSTN, provider, null, { number, type: 'number' });
  };

  return (
    <MainLayout>
      <Header style={{ backgroundColor: 'white' }}>
        <Header.Left>
          <Row modifiers={['middle']}>
            <BackButton />
            <Column>
              <Avatar
                modifiers={['round']}
                user={provider}
                width="40"
                height="40"
              />
            </Column>
            <Column modifiers={['col_5']}>
              <Text modifiers={['block', 'subtitle', 'semiBold']}>
                {formatTitleAndName(provider)}
              </Text>
            </Column>
            <Column></Column>
          </Row>
        </Header.Left>
        <Header.Bottom>
          <Row modifiers={['spaceBetween']}>
            <Button
              image={assets.icons.icCallBlue}
              imageWidth={20}
              imageHeight={20}
              height={32}
              style={{ borderRadius: 33 }}
              direction="row"
              modifiers={[
                'icon',
                'textIcon',
                'outlinePrimary',
                'roundCorners',
                'widthXSmall',
              ]}
              onClick={() => setShowNumberPad(true)}
            >
              Call
            </Button>
            <Button
              image={assets.icons.icMessageBlue}
              imageWidth={20}
              imageHeight={20}
              height={32}
              direction="row"
              style={{ borderRadius: 33 }}
              modifiers={[
                'icon',
                'textIcon',
                'outlinePrimary',
                'roundCorners',
                'widthXSmall',
              ]}
              onClick={() =>
                history.push({
                  pathname: urls.CHAT,
                  search: qs.stringify({
                    memberIds: [provider._id],
                  }),
                })
              }
            >
              Message
            </Button>
            <Button
              image={assets.icons.icCallVideo}
              imageWidth={20}
              imageHeight={20}
              height={32}
              direction="row"
              style={{ borderRadius: 33 }}
              onClick={handleVideoCall}
              modifiers={[
                'icon',
                'textIcon',
                'outlinePrimary',
                'roundCorners',
                'widthXSmall',
              ]}
            >
              Video
            </Button>
          </Row>
        </Header.Bottom>
      </Header>
      <Content style={{ backgroundColor: '#f9f9ff' }}>
        <Container>
          <CardContainer modifiers={['withGutters']}>
            <Row>
              <Text modifiers={['bold', 'small']}>Practice</Text>
            </Row>
            <Row>
              <Text modifiers={['topPadding', 'body', 'primary']}>
                {practice.name}, {formatAddress(practice.address)}
              </Text>
            </Row>
          </CardContainer>
          <CardContainer modifiers={['withGutters']}>
            <Row>
              <Text modifiers={['bold', 'small']}>Designation</Text>
            </Row>
            <Row>
              <Text modifiers={['body', 'primary', 'topPadding']}>
                {formatMemberDesignation(provider.memberDesignation)}
              </Text>
            </Row>
          </CardContainer>
          <CardContainer modifiers={['withGutters']}>
            <Row>
              <Text modifiers={['bold', 'small']}>NPI</Text>
            </Row>
            <Row>
              <Text modifiers={['body', 'primary', 'topPadding']}>
                {provider.npi}
              </Text>
            </Row>
          </CardContainer>
          <CardContainer modifiers={['withGutters']}>
            <Row>
              <Text modifiers={['bold', 'small']}>
                Phones (PSTN - Charges may apply)
              </Text>
            </Row>
            <Row
              modifiers={['spaceBetween', 'middle']}
              onClick={() => initPSTN('work')}
            >
              <Column modifiers={['col_4']}>
                <Text modifiers={['body', 'inline']}>Work</Text>
              </Column>
              <Column modifiers={['col_6']}>
                <Text modifiers={['body', 'primary', 'inline']}>
                  {provider.phones.work}
                </Text>
              </Column>
              <Column>
                <img
                  alt="call"
                  src={assets.icons.icCallBlue}
                  height="20"
                  width="20"
                />
              </Column>
            </Row>
            <Row
              modifiers={['spaceBetween', 'middle']}
              onClick={() => initPSTN('mobile')}
            >
              <Column modifiers={['col_4']}>
                <Text modifiers={['body', 'inline']}>Mobile</Text>
              </Column>
              <Column modifiers={['col_6']}>
                <Text modifiers={['body', 'primary', 'inline']}>
                  {provider.phones.mobile}
                </Text>
              </Column>
              <Column>
                <img
                  alt="call"
                  src={assets.icons.icCallBlue}
                  height="20"
                  width="20"
                />
              </Column>
            </Row>
            <Row
              modifiers={['spaceBetween', 'middle']}
              onClick={() => initPSTN('home')}
            >
              <Column modifiers={['col_4']}>
                <Text modifiers={['body', 'inline']}>Home</Text>
              </Column>
              <Column modifiers={['col_6']}>
                <Text modifiers={['body', 'primary', 'inline']}>
                  {provider.phones.home}
                </Text>
              </Column>
              <Column>
                <img
                  alt="call"
                  src={assets.icons.icCallBlue}
                  height="20"
                  width="20"
                />
              </Column>
            </Row>
          </CardContainer>
          <CardContainer modifiers={['withGutters']}>
            <Row>
              <Text modifiers={['bold', 'small']}>E-mail</Text>
            </Row>
            <Row>
              <Text modifiers={['body', 'topPadding']}>Work</Text>
            </Row>
            <Row>
              <Text modifiers={['topPadding', 'body', 'primary']}>
                {provider.email}
              </Text>
            </Row>
          </CardContainer>
        </Container>
        <SlidingPane
          isOpen={showNumberPad}
          handleClose={() => setShowNumberPad(false)}
          modifiers={['fillHeight']}
        >
          <CallPad handleCall={handleCallPSTN} />
        </SlidingPane>
      </Content>
    </MainLayout>
  );
}

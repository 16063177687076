import { useState, useMemo, useEffect, useContext, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';

import { Container, Row, Column, Text, ScrollView, SearchBar } from 'common';
import { PeriodPicker } from 'common';
import Item from './Item';
import { formatTitleAndName } from 'utils/string';
import { FETCH_CALL_HISTORY } from 'graphql/queries';
import { EVENTS, SocketContext } from 'contexts/socket';
import { AuthContext } from 'contexts/auth';

const List = ({ user }) => {
  const [query, updateQuery] = useState();
  const [period, updatePeriod] = useState('3m');
  const { me } = useContext(AuthContext);

  const [queryCalls, { data: { callHistory: calls } = {} }] = useLazyQuery(
    FETCH_CALL_HISTORY,
    {
      fetchPolicy: 'no-cache',
      initialFetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    queryCalls({
      variables: {
        user: user?._id || user,
        period,
      },
    });
  }, [period]);

  const filteredItems = useMemo(() => {
    if (!calls) {
      return [];
    }
    if (!query) {
      return calls;
    }
    const trimmedQuery = query.trim().toLowerCase();
    if (!trimmedQuery || trimmedQuery.length === 0) {
      return calls || [];
    }
    return calls.filter((call) => {
      for (let attendee of call.attendees) {
        if (attendee.user._id === me._id) {
          continue;
        }
        if (
          formatTitleAndName(attendee.user).toLowerCase().includes(trimmedQuery)
        ) {
          return true;
        }
      }
      if (
        call.referredPatient &&
        formatTitleAndName(call.referredPatient)
          .toLowerCase()
          .includes(trimmedQuery)
      ) {
        return true;
      }
      return false;
    });
  }, [calls, query]);

  const handleSelectCall = useCallback((call) => {}, []);

  return (
    <Container modifiers={['fullHeight', 'flexBox']}>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <SearchBar
            value={query}
            onChange={updateQuery}
            placeholder="Search"
          />
          <Column>
            <PeriodPicker value={period} handleSelect={updatePeriod} />
          </Column>
        </Row>
      </Container>
      <ScrollView>
        <Container>
          {filteredItems?.length > 0 ? (
            filteredItems?.map((call, i) => (
              <Item
                handleClick={() => handleSelectCall(call)}
                call={call}
                key={call._id}
              />
            ))
          ) : (
            <Text modifiers={['muted']}>No call records found.</Text>
          )}
        </Container>
      </ScrollView>
    </Container>
  );
};

export default List;

import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import assets from 'assets';

import { Button, Modal } from 'common';
import ViewerControl from 'common/MediaViewer/ViewerControl';

export function PDFViewer({ url, setPDFViewerIsOpen }) {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfScaleValue, setPDFScaleValue] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onCountPDFPages = (isNext) => {
    if (isNext && numPages > pageNumber) {
      const num = pageNumber + 1;
      setPageNumber(num);
    }

    if (!isNext && pageNumber > 1) {
      const num = pageNumber - 1;
      setPageNumber(num);
    }
  };

  const onChangePDFScale = (isZoomOut) => {
    if (isZoomOut && pdfScaleValue < 5) {
      const value = pdfScaleValue + 0.1;
      setPDFScaleValue(value);
    }

    if (!isZoomOut && pdfScaleValue > 0.1) {
      const value = pdfScaleValue - 0.1;
      setPDFScaleValue(value);
    }
  };

  return (
    <Modal open>
      <ViewerControl
        onClick={() => setPDFViewerIsOpen(false)}
        icon={assets.icons.close}
        modifiers={['right', 'top']}
      />

      <Modal.Body modifiers={['flex']}>
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          className="custom_pdf_viewer_margin"
        >
          <Page pageNumber={pageNumber} scale={pdfScaleValue} />
        </Document>
      </Modal.Body>

      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            image={assets.icons.arrowLeftView}
            modifiers={['icon', 'transparent']}
            width={50}
            height={50}
            onClick={() => onCountPDFPages(false)}
          />

          <Button
            image={assets.icons.arrowRightView}
            modifiers={['icon', 'transparent']}
            width={50}
            height={50}
            onClick={() => onCountPDFPages(true)}
          />
        </div>

        <p
          style={{
            textAlign: 'center',
            color: 'black',
            margin: 'unset',
          }}
        >
          Page {pageNumber} of {numPages}
        </p>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            image={assets.icons.circleBlackMinus}
            modifiers={['icon', 'transparent']}
            width={50}
            height={50}
            onClick={() => onChangePDFScale(false)}
          />

          <Button
            image={assets.icons.circleBlackPlus}
            modifiers={['icon', 'transparent']}
            width={50}
            height={50}
            onClick={() => onChangePDFScale(true)}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

import React from 'react';
import { noop } from 'lodash';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Container, Row, Column, Button, HR } from 'common';

import { FormPinInput, FormPasswordInput } from 'common';
import schema from 'utils/schema';

const userSchema = Yup.object().shape({
  password: schema.password,
  passwordConfirmation: schema.confirmPassword,
  pin: schema.pin,
  pinConfirmation: schema.confirmPin,
});

export default function Security({
  initialValues,
  updateFormData,
  goNext = noop,
}) {
  const handleSubmit = (values) => {
    updateFormData(values);
    goNext();
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={userSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Row>
              <Column modifiers={['col_12']}>
                <Field
                  name="password"
                  label="Password"
                  required
                  largeLabel
                  component={FormPasswordInput}
                />
              </Column>
              <Column modifiers={['col_12']}>
                <Field
                  name="passwordConfirmation"
                  label="Confirm Password"
                  required
                  largeLabel
                  component={FormPasswordInput}
                />
              </Column>
              <Column modifiers={['col_12', 'fluid']}>
                <HR modifiers={['colored']} />
              </Column>
              <Column modifiers={['col_12']}>
                <Field
                  name="pin"
                  hasLabel
                  placeholder="4 Digit Code"
                  required
                  fields={4}
                  wide
                  largeLabel
                  autoFocus={false}
                  component={FormPinInput}
                />
              </Column>
              <Column modifiers={['col_12']}>
                <Field
                  name="pinConfirmation"
                  hasLabel
                  placeholder="Confirm 4 Digit Code"
                  required
                  fields={4}
                  autoFocus={false}
                  largeLabel
                  wide
                  component={FormPinInput}
                />
              </Column>
            </Row>
            <Row modifiers={['withGutters', 'center']}>
              <Button
                type="submit"
                modifiers={['primary', 'widthMedium', 'roundCorner']}
              >
                Continue
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

import styled from 'styled-components';

import { Row, Column, Button, Text } from 'common';
import { Field } from 'formik';
import { buildStyledComponent, px2rem } from 'style';

import {
  Container,
  FormPicker,
  FormTimePicker,
  FormInput,
  FormDatePicker,
} from 'common';
import assets from 'assets';

export const Directions = [
  { id: 'none', name: 'None', shortName: 'Direction' },
  { id: 'AC', name: 'AC - Before Meal', shortName: 'AC' },
  { id: 'HC', name: 'HC - At Bedtime', shortName: 'HC' },
  { id: 'INT', name: 'INT - Between Meals', shortName: 'INT' },
  { id: 'PC', name: 'PC - After Meal', shortName: 'PC' },
];

export const TimeSlot = buildStyledComponent(
  'TimeSlot',
  styled(({ name, ...props }) => (
    <Container {...props}>
      <Field
        labelLightWeight
        useLabel
        asInfo
        noBorder
        name={name}
        component={FormTimePicker}
      />
    </Container>
  )),
  ({ theme }) => `
    width: ${px2rem(80)};
    text-align: center;
    border-radius: ${px2rem(15)};
    border: solid ${px2rem(1)} ${theme.colors.blue};
    padding: ${px2rem(3)} ${theme.dimensions.padding_1};
  `
);

export const CustomRow = buildStyledComponent(
  'FrequencyCustomRow',
  styled(
    ({
      title,
      children,
      hasThreeComponents,
      highlight,
      noBorder,
      ...props
    }) => (
      <Row modifiers={['spaceBetween', 'withGutters']} {...props}>
        {title && <Text>{title}</Text>}
        {children}
      </Row>
    )
  ),
  ({ theme, hasThreeComponents, highlight, noBorder }) => `
    background: ${theme.colors.white};
    border-radius: ${theme.dimensions.borderRadius_1};
    border: 1px solid ${
      noBorder ? 'transparent' : highlight ? theme.colors.primary : '#E7E7E9'
    };
    overflow: hidden;

    div div > span {
      font-size: ${theme.dimensions.fontSize};
      color: ${theme.colors.primary};
      font-weight: ${theme.fontWeights.normal};
    }

    input {
      width: 100%;
      border: none;
      color: ${theme.colors.primary};
    }

    >* {
      padding: ${px2rem(10)} ${px2rem(8)} !important;
    }
    > :not(:last-child) {
      border-right: solid ${px2rem(1)} ${
    noBorder ? 'transparent' : highlight ? theme.colors.primary : '#E7E7E9'
  };
    }
    >:first-child {
      width: ${hasThreeComponents ? '50%' : '60%'};
    }
    >:nth-child(2) {
      width: ${hasThreeComponents ? '20%' : '40%'};
    }
    >:nth-child(3) {
      width: 30%;
    }
  `
);

export const TwoRowField = buildStyledComponent(
  'TwoRowField',
  styled(({ children, ...props }) => <Row {...props}>{children}</Row>),
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    padding: 0 !important;

    > * {
      padding: ${px2rem(5)} !important;
    }

    > :first-child {
      border-bottom: solid ${px2rem(1)} #E7E7E9;
    }
  `
);

export const CustomField = ({ fieldType, placeholder, ...props }) => {
  if (fieldType === 'picker') {
    return (
      <Field
        asInfo
        noPadding
        widthSmall
        noEmptyOption
        noBorder
        placeholder={placeholder}
        component={FormPicker}
        {...props}
      />
    );
  }
  if (fieldType === 'timePicker') {
    return (
      <Field
        useLabel
        noEmptyOption
        placeholder={placeholder}
        component={FormTimePicker}
        {...props}
      />
    );
  }
  if (fieldType === 'datePicker') {
    return (
      <Field
        useLabel
        minDate={new Date()}
        title
        component={FormDatePicker}
        {...props}
      />
    );
  }
  return (
    <Field
      asInfo
      noPadding
      widthSmall
      hasBorderBottom
      component={FormInput}
      {...props}
    />
  );
};

export const WeeklyTimeItem = buildStyledComponent(
  'FrequencyWeeklyTime',
  styled(({ handleRemove, handleClick, value, ...props }) => (
    <Column onClick={() => handleClick(value)} {...props}>
      <Row modifiers={['middle', 'spaceBetween']}>
        <Text modifiers={['white', 'medium']}>{value}</Text>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleRemove(value);
          }}
          width={20}
          height={20}
          image={assets.icons.icCloseWhite_1}
          modifiers={['transparent', 'icon']}
        />
      </Row>
    </Column>
  )),
  ({ theme }) => `
    padding: 0;
    margin-right: ${px2rem(5)};
    margin-bottom: ${px2rem(10)};
    width: ${px2rem(103)};

    >:first-child {
      padding: ${px2rem(10)} ${px2rem(7)};
      border-radius: ${px2rem(20)};
      background-color: ${theme.colors.primary};
      span {
        padding: 0;
      }
    }
  `
);

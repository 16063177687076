import { useEffect, useState, useContext, useMemo } from 'react';

import { ScrollView, Row, Column, Button } from 'common';

import { Container, Text } from 'common';
import VitalsHistory from './Vitals';
import VitalDetails from './Detail';
import VitalInput from './VitalInput';
import AddVital from './AddVital';
import { CreateNoteContext } from '../Context';
import { VITALS } from 'utils/constants';
import assets from 'assets';

export default () => {
  const { goBack, setBackHandler, note, updateNote } =
    useContext(CreateNoteContext);
  const [selectedVital, setSelectedVital] = useState();
  const [isAdding, setIsAdding] = useState(false);
  const [vitals, updateVitals] = useState({});

  const handleSelectVital = (vitalType) => () => {
    setSelectedVital(vitalType);
    setBackHandler(() => {
      setSelectedVital(null);
    });
  };

  const handleSetAdding = () => {
    setIsAdding(true);
    setBackHandler(() => {
      setIsAdding(false);
    });
  };

  const handleRemoveVital = (vitalType) => {
    delete vitals[vitalType];
    if (vitalType === 'bloodPressure') {
      delete vitals['bloodPressure2'];
    }
    updateVitals({ ...vitals });
  };

  const handleSaveVital = (type, value, value2) => {
    vitals[type] = value;
    if (type === 'bloodPressure') {
      vitals['bloodPressure2'] = value2;
    }
    updateVitals({ ...vitals });
  };

  const handleDone = () => {
    let values = {};
    Object.entries(vitals).forEach(([type, value]) => {
      if (parseInt(value, 10) > 0) {
        values[type] = parseInt(value, 10);
      }
    });
    if (Object.keys(values).length === 0) {
      values = null;
    }
    note.content.objective = values;
    updateNote({ ...note });
    goBack();
  };

  const addedVitalTypes = useMemo(() => {
    return Object.keys(VITALS).filter((item) => !!vitals[item]);
  }, [vitals]);

  useEffect(() => {
    if (note.content?.objective) {
      updateVitals({ ...note.content?.objective });
    }
  }, [note]);

  return (
    <Container modifiers={['fluid', 'flexBox', 'fullHeight']}>
      <Container
        modifiers={[
          'fluid',
          'flexBox',
          'fullHeight',
          (isAdding || selectedVital) && 'noDisplay',
        ]}
      >
        <Container style={{ background: '#F3F3F4' }} modifiers={['flexFixed']}>
          <Row modifiers={['middle', 'spaceBetween']}>
            <Column modifiers={['noPadding']}>
              <Text modifiers={['bold', 'block']}>Objective Vitals</Text>
              <Text modifiers={['block', 'muted', 'medium']}>
                Add Vitals to note
              </Text>
            </Column>
            <Column modifiers={['noPadding']}>
              <Button
                modifiers={['success', 'roundCorner']}
                onClick={handleSetAdding}
              >
                <Text modifiers={['subtitle', 'white', 'bold']}>+ ADD</Text>
              </Button>
            </Column>
          </Row>
        </Container>
        <ScrollView>
          <Container>
            <Text modifiers={['bold', 'block', 'secondaryColor']}>
              Recently Added Vitals
            </Text>
            {addedVitalTypes.length > 0 ? (
              addedVitalTypes.map((vitalType) => (
                <VitalInput
                  vitalType={vitalType}
                  editable
                  value={vitals[vitalType]}
                  value2={
                    vitalType === 'bloodPressure' && vitals.bloodPressure2
                  }
                  handleSave={handleSaveVital}
                  handleRemove={handleRemoveVital}
                />
              ))
            ) : (
              <Row modifiers={['withGutters']}>
                <Text modifiers={['block', 'medium']}>Press Add to start.</Text>
              </Row>
            )}
          </Container>
        </ScrollView>
        {/* <ScrollView>
          <VitalsHistory handleSelectVital={handleSelectVital} />
        </ScrollView> */}
        <Container modifiers={['footer', 'flexFixed']}>
          <Row modifiers={['spaceBetween', 'middle']}>
            <Button
              modifiers={['icon', 'transparent']}
              image={assets.icons.icCloseGray}
              onClick={goBack}
            />
            <Button
              modifiers={['widthSmall', 'roundCorner']}
              onClick={handleDone}
            >
              Done
            </Button>
          </Row>
        </Container>
      </Container>
      {isAdding && <AddVital vitals={vitals} updateVitals={updateVitals} />}
      {selectedVital && <VitalDetails vitalType={selectedVital} />}
    </Container>
  );
};

import styled from 'styled-components';
import { Text } from 'common';
import { buildStyledComponent } from 'style';

const modifierConfig = {
  selected: ({ theme }) => `
    > ${Text} {
      position: relative;
      &::before {
        content: ' ';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -8px;
        border-radius: 50rem;
        background-color: ${theme.colors.primary};
        height: 3px;
        width: 100%;
      }

    }
  `,
  muted: () => `
    opacity: 0.5;
  `,
};

const styles = () => `
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
  flex-basis: 20%;
  max-width: 20%;
`;

export default buildStyledComponent('Rating_Wrapper', styled.div, styles, {
  modifierConfig,
});

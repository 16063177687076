import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import { Row } from 'common';

import Wrapper from './Wrapper';
import Home from './Home';
import Invitations from './Invitations';
import Reports from './Reports';
import AddPatients from './AddPatients';
import NavData from './NavData';

const NavMenu = () => {
  const history = useHistory();
  const [selectedNav, setSelectedNav] = useState(NavData.home.title);

  useEffect(() => {
    const nav = Object.values(NavData).find((s) => {
      const currentUrl = window.location.pathname.substring(1);
      const navUrl = s.url.substring(1);

      return (
        currentUrl.substring(currentUrl.lastIndexOf('/')) ===
        navUrl.substring(navUrl.lastIndexOf('/'))
      );
    });

    if (nav) {
      setSelectedNav(nav.title);
    }
  }, []);

  const onRoutePage = (url, navTitle) => {
    setSelectedNav(navTitle);
    history.push(url);
  };

  return (
    <Wrapper modifiers={['fluid']}>
      <Row modifiers={['middle', 'spaceBetween']}>
        <Home
          selected={selectedNav === NavData.home.title}
          handleClick={() => onRoutePage(NavData.home.url, NavData.home.title)}
        />
        <Invitations
          selected={selectedNav === NavData.invitations.title}
          handleClick={() =>
            onRoutePage(NavData.invitations.url, NavData.invitations.title)
          }
        />
        <Reports
          selected={selectedNav === NavData.reports.title}
          handleClick={() =>
            onRoutePage(NavData.reports.url, NavData.reports.title)
          }
        />
        <AddPatients
          selected={selectedNav === NavData.patient.title}
          handleClick={() =>
            onRoutePage(NavData.patient.url, NavData.patient.title)
          }
        />
      </Row>
    </Wrapper>
  );
};

export default NavMenu;

import { useState, useEffect, useContext } from 'react';
import { Text } from 'common';

import { Container, Tabs } from 'common';
import Note from './Note';
import Diagnosis from './Diagnosis';
import Careplan from './Careplan';
import Medication from './Medication';
import ViewNote from 'pages/Notes/ViewNote';
import ViewCareplan from 'pages/Careplans/CareplanDetail';
import ViewMedication from 'pages/Pharmacy/Detail';
import { PatientHomeContext } from '../Context';

const Records = () => {
  const { setBackHandler, goBack } = useContext(PatientHomeContext);
  const [selectedTab, updateSelectedTab] = useState(0);
  const [activeNote, setActiveNote] = useState();
  const [activeCareplan, setActiveCareplan] = useState();
  const [activeMedication, setActiveMedication] = useState();
  const [activeDiagnosis, setActiveDiagnosis] = useState();

  const handleTabChange = (tab) => {
    updateSelectedTab(tab);
  };

  const handleViewNote = (note) => {
    setActiveNote(note);
    setBackHandler(() => {
      setActiveNote(null);
      return false;
    });
  };

  const handleViewCareplan = (careplan) => {
    setActiveCareplan(careplan);
    setBackHandler(() => {
      setActiveCareplan(null);
      return false;
    });
  };

  const handleViewMedication = (medication) => {
    setActiveMedication(medication);
    setBackHandler(() => {
      setActiveMedication(null);
      return false;
    });
  };

  useEffect(() => {
    setBackHandler('');
  }, []);

  return (
    <Container modifiers={['backgroundGray', 'fullHeight', 'flexBox', 'fluid']}>
      <Container
        modifiers={[
          'fluid',
          'fullHeight',
          'flexBox',
          (activeNote ||
            activeCareplan ||
            activeDiagnosis ||
            activeMedication) &&
            'noDisplay',
        ]}
      >
        <Container modifiers={['padding_2', 'flexFixed']}>
          <Text modifiers={['block', 'bold']}>Records</Text>
        </Container>
        <Container modifiers={['fluid', 'flexFixed']}>
          <Tabs current={selectedTab} onChange={handleTabChange} flatTabs>
            <Tabs.Tab title="Notes" />
            <Tabs.Tab title="Diagnosis" />
            <Tabs.Tab title="Care Plans" />
            <Tabs.Tab title="Medications" />
          </Tabs>
        </Container>
        <Container
          modifiers={['fluid', 'flexOne', 'fullHeight', 'backgroundWhite']}
        >
          <Container
            modifiers={[
              'flexBox',
              'fullHeight',
              'fluid',
              selectedTab !== 0 && 'noDisplay',
            ]}
          >
            <Note handleViewNote={handleViewNote} />
          </Container>
          <Container
            modifiers={[
              'flexBox',
              'fullHeight',
              'fluid',
              selectedTab !== 1 && 'noDisplay',
            ]}
          >
            <Diagnosis />
          </Container>
          <Container
            modifiers={[
              'flexBox',
              'fullHeight',
              'fluid',
              selectedTab !== 2 && 'noDisplay',
            ]}
          >
            <Careplan handleViewCareplan={handleViewCareplan} />
          </Container>
          <Container
            modifiers={[
              'flexBox',
              'fullHeight',
              'fluid',
              selectedTab !== 3 && 'noDisplay',
            ]}
          >
            <Medication handleViewMedication={handleViewMedication} />
          </Container>
        </Container>
      </Container>
      {activeNote && <ViewNote note={activeNote} goBack={goBack} />}
      {activeCareplan && (
        <ViewCareplan careplan={activeCareplan} goBack={goBack} noFooter />
      )}
      {activeMedication && (
        <ViewMedication order={activeMedication} viewOnly={true} />
      )}
    </Container>
  );
};

export default Records;

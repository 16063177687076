import { Text, ContentCard, Row, Column } from 'common';
import { DIET_ITEMS, VITALS, WELLNESS_ITEMS } from 'utils/constants';
import { toLocalTime } from 'utils/time';

export const getItemContent = (tasksByHours, response) => {
  const timeKey = toLocalTime(response.time, 'hh:mm A');
  const tasks = tasksByHours[timeKey];
  switch (response.measure) {
    case 'medication':
      return tasks?.map((task) => task.name);
    case 'activity':
      return tasks?.map((task) => task.activity);
    case 'wellness':
    case 'diet':
      if (response.response?.type) {
        return [
          DIET_ITEMS[response.response?.type]?.title ||
            WELLNESS_ITEMS[response.response?.type]?.title,
        ];
      }
      return [''];
    case 'vital':
      if (response.response?.type) {
        return [VITALS[response.response?.type]];
      }
      return [''];
  }
  return [];
};

const ListItem = ({
  icon,
  title,
  positiveTitle,
  readings,
  positivePct,
  handleSelect,
}) => (
  <ContentCard modifiers={['withGutters', 'fluid']}>
    <Row modifiers={['middle']} onClick={handleSelect}>
      <Column modifiers={['col_5', 'flexBox', 'verticalContentCenter']}>
        <img src={icon} />
        <Text modifiers={['darkGrey', 'subtitle', 'semiBold']}>{title}</Text>
      </Column>
      <Column modifiers={['col_3', 'center']}>
        <Text modifiers={['darkGrey', 'subtitle', 'semiBold', 'block']}>
          Readings
        </Text>
        <Text modifiers={['danger', 'subtitle', 'semiBold', 'block']}>
          {readings}
        </Text>
      </Column>
      <Column modifiers={['col_4', 'center']}>
        <Text modifiers={['darkGrey', 'subtitle', 'semiBold', 'block']}>
          % {positiveTitle}
        </Text>
        <Text
          modifiers={[
            positivePct === 'N/A'
              ? 'muted'
              : positivePct >= 50
              ? 'success'
              : 'danger',
            'subtitle',
            'semiBold',
            'block',
          ]}
        >
          {positivePct}
          {positivePct !== 'N/A' && '%'}
        </Text>
      </Column>
    </Row>
  </ContentCard>
);

export default ListItem;

import { useState, useContext, useMemo } from 'react';

import { Container, Row, Column, Text, ScrollView, SearchBar } from 'common';

import { PeriodPicker } from 'common';
import ListItem from './ListItem';
import { CoordinateContext } from '../Context';
import { formatTitleAndName } from 'utils/string';

export default () => {
  const {
    careplans,
    careplanPeriod: period,
    updateCareplanPeriod: setPeriod,
  } = useContext(CoordinateContext);
  const [query, updateQuery] = useState();

  const handleSelectPeriod = (value) => {
    setPeriod(value);
  };

  const cps = useMemo(() => {
    if (!careplans) {
      return [];
    }
    if (!query) {
      return careplans;
    }
    const trimmedQuery = query.trim().toLowerCase();
    if (!trimmedQuery || trimmedQuery.length === 0) {
      return careplans || [];
    }
    return careplans.filter(
      (careplan) =>
        formatTitleAndName(careplan.user)
          .toLowerCase()
          .includes(trimmedQuery) ||
        formatTitleAndName(careplan.creator?.user)
          .toLowerCase()
          .includes(trimmedQuery)
    );
  }, [careplans, query]);

  return (
    <Container modifiers={['fullHeight', 'flexBox']}>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <SearchBar
            value={query}
            onChange={updateQuery}
            placeholder="Search"
          />
          <Column>
            <PeriodPicker value={period} handleSelect={handleSelectPeriod} />
          </Column>
        </Row>
      </Container>
      <ScrollView>
        <Container>
          {cps?.length > 0 ? (
            cps.map((careplan, i) => <ListItem careplan={careplan} key={i} />)
          ) : (
            <Text modifiers={['muted']}>
              You don't have any shared careplans
            </Text>
          )}
        </Container>
      </ScrollView>
    </Container>
  );
};

import { useState } from 'react';
import { Container } from 'common';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';
import Header from './Header';
import Main from './MainList';
import Footer from './Footer';
import Book from './BookAppointment';

const Wrapper = buildStyledComponent(
  'AppointmentsWrapper',
  styled(Container),
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    background-color: ${theme.colors.background};

    >:first-child {
      flex: 0;
      background: ${theme.colors.white};
      padding: ${theme.dimensions.padding_1} ${theme.dimensions.padding_1};
      box-shadow: 0px -2px 10px rgba(46, 41, 78, 0.08);
      z-index: 1;

      >:first-child>:last-child {
        width: ${px2rem(42)};
      }
    }
    >:nth-child(2) {
      flex: 1 0;
      >:nth-child(2) {
        background: ${theme.colors.white};
        margin: 0;
        padding: ${theme.dimensions.padding_2};
      }
    }
    >:nth-child(3) {
      flex: 0;
      background: ${theme.colors.white};
      padding: ${theme.dimensions.padding_1} ${theme.dimensions.padding_2};
    }
  `
);

const Appointments = () => {
  const [showBookAppointment, updateShowBookAppointment] = useState(false);
  const [bookTime, updateBookTime] = useState();
  const [selectedDate, updateSelectedDate] = useState(new Date());
  const handleDone = (appointment) => {
    if (appointment) {
      updateBookTime(new Date());
    }
    updateShowBookAppointment(false);
  };
  return (
    <Wrapper>
      <Header />
      <Main
        selectedDate={selectedDate}
        updateSelectedDate={updateSelectedDate}
        bookTime={bookTime}
      />
      <Footer handleBook={() => updateShowBookAppointment(true)} />
      {showBookAppointment && (
        <Book date={selectedDate} handleDone={handleDone} />
      )}
    </Wrapper>
  );
};

export default Appointments;

import { useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { Column, Text, HR, Button } from 'common';

import { Container, Row } from 'common';
import assets from 'assets';
import { PatientHomeContext } from '../Context';
import { buildStyledComponent, px2rem } from 'style';
import { formatTitleAndName } from 'utils/string';
import { displayTime } from 'utils/time';

const CartItem = buildStyledComponent(
  'CartItem',
  styled(({ medication, index, onRemove }) => (
    <Row modifiers={['spaceBetween', 'middle']}>
      <Text modifiers={['medium']}>{index + 1}.</Text>
      <Column modifiers={['col', 'noPadding']}>
        <Text modifiers={['medium']}>
          {medication.name} x {medication.quantity}
        </Text>
      </Column>
      <Button
        image={assets.icons.icCloseGray}
        modifiers={['icon', 'transparent']}
        onClick={() => onRemove(medication)}
      />
    </Row>
  )),
  () => `
    >:first-child {
      width: ${px2rem(25)};
    }
  `
);

const InfoItem = buildStyledComponent(
  'InfoItem',
  styled(({ label, text, ...props }) => (
    <Row modifiers={['smallGutters']} {...props}>
      <Text modifiers={['medium', 'block']}>{label}</Text>
      <Text modifiers={['primary', 'medium', 'block', 'preserveLineBreak']}>
        {text}
      </Text>
    </Row>
  )),
  ({ theme }) => `
    >:first-child {
      width: ${px2rem(70)};
    }
    >:last-child {
      flex: 1;
    }
  `
);

const CartConfirm = ({
  medications,
  updateMedications,
  handleConfirm,
  handleClose,
}) => {
  const { patient, provider, practice } = useContext(PatientHomeContext);

  const onRemove = useCallback(
    (medication) => {
      delete medications[medication.ndc];
      updateMedications({ ...medications });
      if (Object.values(medications).length === 0) {
        handleClose();
      }
    },
    [medications]
  );

  return (
    <Container modifiers={['fluid']}>
      <Row modifiers={['smallGutters', 'center']}>
        <Text modifiers={['block', 'center', 'primary']}>
          Confirm Meds Order Cart
        </Text>
      </Row>
      {Object.values(medications).map((medication, index) => (
        <CartItem
          medication={medication}
          index={index}
          onRemove={onRemove}
          key={index}
        />
      ))}
      <HR />
      <Container modifiers={['fluid', 'withGutters']}>
        <InfoItem label="Pharmacy:" text="Right Way Meds" />
        <InfoItem
          label="Address:"
          text={'149 A South Market St.\nHolly Springs, MS38365'}
        />
        <InfoItem label="Email:" text="orders@rightwaymeds.com" />
        <InfoItem label="Phone:" text="662-25-9813" />
      </Container>
      <HR />
      <Container modifiers={['fluid', 'withGutters']}>
        <InfoItem label="Provider:" text={formatTitleAndName(provider)} />
        <InfoItem label="Practice:" text={practice?.name} />
      </Container>
      <HR />
      <Container modifiers={['fluid', 'withGutters']}>
        <InfoItem label="Patient:" text={formatTitleAndName(patient)} />
        <InfoItem label="DoB:" text={displayTime(patient.dob, 'MM/DD/YYYY')} />
      </Container>
      <Row modifiers={['spaceBetween', 'middle']}>
        <Button
          image={assets.icons.icCloseGray}
          modifiers={['icon', 'transparent']}
          onClick={handleClose}
        />
        <Button
          modifiers={['widthSmall', 'roundCorner']}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </Row>
    </Container>
  );
};

export default CartConfirm;

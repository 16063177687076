import { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { Button, Row, Column, Text } from 'common';

import { Container, SlidingPane, CallPad } from 'common';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import { CALL_TYPES, CallServiceContext } from 'contexts/call';

import Wrapper from './Wrapper';
import History from './History';
import { formatPhoneNumber } from 'utils/string';
import assets from 'assets';

const Calls = () => {
  const { setBackHandler, patient } = useContext(PatientHomeContext);
  const { startCall } = useContext(CallServiceContext);
  const [showHistory, updateShowHistory] = useState(false);
  const [showNumberPad, setShowNumberPad] = useState(false);
  const phones = useMemo(() => patient?.phones || {}, [patient]);
  const handleCall = useCallback((number, type) => {
    if (!number) {
      return;
    }
    startCall(CALL_TYPES.PSTN, patient, null, { number, type });
  });

  const handleMeet = () => {
    startCall(CALL_TYPES.UNSCHEDULED, patient);
  };

  const handleCallPSTN = (number) => {
    setShowNumberPad(false);
    handleCall(number, 'number');
  };

  useEffect(() => {
    setBackHandler('');
  }, []);

  const handleShowHistory = useCallback(() => {
    updateShowHistory(true);
    setBackHandler(() => {
      updateShowHistory(false);
      return false;
    });
  }, []);

  if (!patient) {
    return null;
  }

  return (
    <Wrapper>
      <Container
        modifiers={['fullHeight', 'fluid', showHistory && 'noDisplay']}
      >
        <Container modifiers={['card']}>
          <Text modifiers={['block']}>
            Phone <Text modifiers={['medium']}>(PSTN - Charges may apply)</Text>
          </Text>
          <Row
            modifiers={['middle', 'spaceBetween']}
            onClick={() => handleCall(phones.work, 'work')}
          >
            <Column modifiers={['col_3', 'noPadding']}>
              <Text modifiers={['medium', 'block']}>Work</Text>
            </Column>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['center', 'medium', 'primary', 'block']}>
                {formatPhoneNumber(phones.work)}
              </Text>
            </Column>
            <Column modifiers={['col_3', 'noPadding']}>
              <Row modifiers={['end']}>
                <Button
                  image={assets.icons.icCallBlue}
                  modifiers={['icon', 'transparent']}
                />
              </Row>
            </Column>
          </Row>
          <Row
            modifiers={['middle', 'spaceBetween']}
            onClick={() => handleCall(phones.mobile, 'mobile')}
          >
            <Column modifiers={['col_3', 'noPadding']}>
              <Text modifiers={['medium', 'block']}>Mobile</Text>
            </Column>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['center', 'medium', 'primary', 'block']}>
                {formatPhoneNumber(phones.mobile)}
              </Text>
            </Column>
            <Column modifiers={['col_3', 'noPadding']}>
              <Row modifiers={['end']}>
                <Button
                  image={assets.icons.icCallBlue}
                  modifiers={['icon', 'transparent']}
                />
              </Row>
            </Column>
          </Row>
          <Row
            modifiers={['middle', 'spaceBetween']}
            onClick={() => handleCall(phones.home, 'home')}
          >
            <Column modifiers={['col_3', 'noPadding']}>
              <Text modifiers={['medium', 'block']}>Home</Text>
            </Column>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['center', 'medium', 'primary', 'block']}>
                {formatPhoneNumber(phones.home)}
              </Text>
            </Column>
            <Column modifiers={['col_3', 'noPadding']}>
              <Row modifiers={['end']}>
                <Button
                  image={assets.icons.icCallBlue}
                  modifiers={['icon', 'transparent']}
                />
              </Row>
            </Column>
          </Row>
        </Container>
        <Container modifiers={['topGutters_2', 'card']}>
          <Row modifiers={['middle', 'spaceBetween']}>
            <Column modifiers={['noPadding']}>
              <Text modifiers={['block', 'medium']}>Video</Text>
              <Text modifiers={['block', 'small']}>(Meet now)</Text>
            </Column>
            <Column modifiers={['noPadding']}>
              <Button
                modifiers={['outlinePrimary', 'roundCorner', 'widthSmall']}
                onClick={handleMeet}
              >
                <Row modifiers={['spaceBetween', 'middle']}>
                  <img src={assets.icons.icCallBlue} />
                  <Text modifiers={['primary']}>Video</Text>
                </Row>
              </Button>
            </Column>
          </Row>
        </Container>
        <Container>
          <Row modifiers={['center']}>
            <Button
              image={assets.icons.icCallDialer}
              modifiers={['icon', 'transparent']}
              imageWidth={100}
              imageHeight={100}
              width={100}
              height={100}
              onClick={() => setShowNumberPad(true)}
            />
          </Row>
          <Row modifiers={['center']}>
            <Text modifiers={['block', 'primary', 'center']}>DIALER</Text>
          </Row>
        </Container>
        <Container modifiers={['padding_2']} onClick={handleShowHistory}>
          <Row modifiers={['center']}>
            <Text modifiers={['primary']}>Call History &gt;&gt;&gt;</Text>
          </Row>
        </Container>
        <SlidingPane
          isOpen={showNumberPad}
          handleClose={() => setShowNumberPad(false)}
          modifiers={['fillHeight']}
        >
          <CallPad handleCall={handleCallPSTN} />
        </SlidingPane>
      </Container>
      {showHistory && <History />}
    </Wrapper>
  );
};

export default Calls;

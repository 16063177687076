import React, { useContext, useEffect, useState, useMemo, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';

import { FETCH_NOTES } from 'graphql/queries';
import { Row, Column, Text, ScrollView } from 'common';

import { Container, Tabs, Loader, PeriodPicker } from 'common';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import { EVENTS, SocketContext } from 'contexts/socket';
import Item from './Item';

const Notes = ({ handleViewNote }) => {
  const { patient, records, setRecords } = useContext(PatientHomeContext);
  const [fetchNotes, { loading }] = useLazyQuery(FETCH_NOTES, {
    onCompleted: ({ notes }) => {
      const newNotes = records.notes;
      newNotes.list = notes;
      setRecords({ ...records, notes: newNotes });
    },
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });
  const [tab, setTab] = useState(0);
  const { subscribe } = useContext(SocketContext);
  const notesListUpdateSubscription = useRef();

  const fetchPastNotes = (period = null) => {
    fetchNotes({
      variables: {
        patient: patient._id,
        period: period || records.notes.period,
      },
    });
  };

  const handleSelectPeriod = (value) => {
    const notes = records.notes;
    if (value !== notes.period) {
      notes.period = value;
      notes.list = [];
      setRecords({ ...records, notes });
      if (!!patient) {
        fetchPastNotes(value);
      }
    }
  };

  useEffect(() => {
    if (!patient) {
      return;
    }
    // if (!records.notes.list) {
    fetchPastNotes();
    // }
  }, [patient]);

  const signedNotes = useMemo(() => {
    if (records?.notes?.list?.length > 0) {
      return records.notes.list.filter((note) => !note.isDraft);
    }
    return [];
  }, [records]);

  const draftNotes = useMemo(() => {
    if (records?.notes?.list?.length > 0) {
      return records.notes.list.filter((note) => note.isDraft);
    }
    return [];
  }, [records]);

  const notes = tab === 0 ? signedNotes : draftNotes;

  useEffect(() => {
    notesListUpdateSubscription.current?.unsubscribe();
    notesListUpdateSubscription.current = subscribe(
      EVENTS.PATIENT_NOTES,
      (payload) => {
        if (payload?.patient === patient?._id) {
          fetchPastNotes();
        }
      }
    );
    return () => {
      notesListUpdateSubscription.current?.unsubscribe();
    };
  }, [fetchPastNotes, patient]);

  return (
    <Container modifiers={['flexBox', 'fullHeight']}>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Text modifiers={['medium', 'bold']}>Notes List</Text>
          <Column>
            <PeriodPicker
              value={records.notes.period}
              handleSelect={handleSelectPeriod}
            />
          </Column>
        </Row>
        <Tabs current={tab} onChange={setTab} flatTabs>
          <Tabs.Tab
            title={`Signed Notes${
              signedNotes.length > 0 ? ` (${signedNotes.length})` : ''
            }`}
          />
          <Tabs.Tab
            title={`Draft${
              draftNotes.length > 0 ? ` (${draftNotes.length})` : ''
            }`}
          />
        </Tabs>
      </Container>
      <ScrollView>
        {loading ? (
          <Row modifiers={['center', 'withGutters']}>
            <Loader.Icon />
          </Row>
        ) : notes.length > 0 ? (
          <Container>
            {notes.map((note, index) => (
              <Item
                key={index}
                note={note}
                handleClick={() => handleViewNote(note)}
              />
            ))}
          </Container>
        ) : (
          <Text>No notes found.</Text>
        )}
      </ScrollView>
    </Container>
  );
};

export default Notes;

import React, { useState, useContext, useEffect } from 'react';
import { startCase } from 'lodash';
import { Container, Row, Column, Button, Text } from 'common';
import assets from 'assets';

import { CreateNoteContext } from '../Context';

import History from './History';
import NoteInput from '../InputForm';
import Footer from '../Footer';
import { PatientHomeContext } from 'pages/PatientHome/Context';

export default function SimpleNote({ noteType }) {
  const { note, updateNote, setBackHandler } = useContext(CreateNoteContext);
  const { goBack } = useContext(PatientHomeContext);
  const [showInput, setShowInput] = useState(false);
  const [inputValue, updateInputValue] = useState('');

  const handleShowInput = (showStatus = null) => {
    setShowInput(showStatus !== null ? showStatus : !showInput);
    if (showStatus === true || !showInput) {
      setBackHandler(() => setShowInput(false));
    }
  };

  const handleSave = (value) => {
    setShowInput(false);
    updateInputValue(value);
    goBack();
  };

  const handleDone = () => {
    const content = note.content || {};
    content[noteType] = inputValue;
    updateNote({ ...note, content });
    goBack();
  };

  useEffect(() => {
    if (!note) {
      return;
    }
    const content = note.content || {};
    if (content[noteType]) {
      updateInputValue(content[noteType]);
    }
  }, [note]);

  return (
    <Container modifiers={['fluid', 'flexBox', 'fullHeight']}>
      <Container style={{ background: '#F3F3F4' }} modifiers={['flexFixed']}>
        <Row modifiers={['middle', 'spaceBetween']}>
          <Column modifiers={['noPadding']}>
            <Text modifiers={['bold', 'block']}>{startCase(noteType)}</Text>
            <Text modifiers={['block', 'muted', 'medium']}>
              Add {noteType} to note
            </Text>
          </Column>
          <Column modifiers={['noPadding']}>
            <Button
              modifiers={[showInput ? 'info' : 'success', 'roundCorner']}
              onClick={() => {
                handleShowInput();
              }}
            >
              <Text modifiers={['subtitle', 'white', 'bold']}>+ ADD</Text>
            </Button>
          </Column>
        </Row>
      </Container>
      <History
        handleEdit={() => {
          handleShowInput(true);
        }}
        handleClickRemove={() => updateInputValue('')}
        hide={showInput}
        noteType={noteType}
        inputValue={inputValue}
      />
      <NoteInput
        value={!!note?.content && note?.content[noteType]}
        handleSave={handleSave}
        isVisible={showInput}
        handleCancel={() => {
          goBack();
        }}
      >
        <Button
          modifiers={['icon', 'transparent']}
          image={assets.icons.icClosePlain}
          width={20}
          height={20}
          imageWidth={10}
          imageHeight={10}
          onClick={() => handleSave(note?.content[noteType])}
          style={{ alignSelf: 'center' }}
        />
        <Text
          style={{ flex: 1 }}
          modifiers={['h2', 'bold', 'darkGrey', 'center']}
        >{`Add ${startCase(noteType)}`}</Text>
      </NoteInput>
      <Footer modifiers={['flexFixed', 'shadow']}>
        <Row modifiers={['spaceBetween']}>
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icCloseGray}
            onClick={goBack}
          />
          <Button
            modifiers={['widthXSmall', 'primary', 'roundCorner']}
            onClick={handleDone}
          >
            <Text modifiers={['subtitle', 'semiBold', 'white']}>Done</Text>
          </Button>
        </Row>
      </Footer>
    </Container>
  );
}

import { useContext, useMemo } from 'react';
import { Column, Avatar } from 'common';
import styled from 'styled-components';
import moment from 'moment';

import { Container, Text, Row, HR } from 'common';
import { displayTime, secondsToDuration } from 'utils/time';
import { formatTitleAndName } from 'utils/string';
import { AuthContext } from 'contexts/auth';
import assets from 'assets';
import { buildStyledComponent, px2rem } from 'style';

const TypeDuration = buildStyledComponent(
  'CallItemTypeDuration',
  styled(({ duration, type, ...props }) => (
    <Row modifiers={['middle', 'spaceBetween']} {...props}>
      {type === 'video' ? (
        <img src={assets.icons.icCallVideo} />
      ) : (
        <img src={assets.icons.icCallGreen} />
      )}
      <Text modifiers={['secondary', 'medium']}>{duration}</Text>
    </Row>
  )),
  () => `
    >:last-child {
      margin-left: ${px2rem(5)};
      width: ${px2rem(50)};
    }
  `
);

const CallItem = ({ handleClick, call }) => {
  const { me } = useContext(AuthContext);
  const otherAttendee = useMemo(
    () => call?.attendees?.find((attendee) => attendee.user._id !== me._id),
    [me, call]
  );
  const callDuration = useMemo(() => {
    if (!call.startTime || !call.endTime) {
      return null;
    }
    const duration =
      moment(call.endTime).unix() - moment(call.startTime).unix();
    return secondsToDuration(duration, true);
  }, [call]);
  return (
    <Container
      modifiers={['card', 'padding_2', 'withGutters']}
      onClick={handleClick}
    >
      <Row modifiers={['middle', 'spaceBetween']}>
        <Avatar
          modifiers={['round']}
          user={otherAttendee.user}
          width={50}
          height={50}
        />
        <Column modifiers={['col']}>
          {formatTitleAndName(otherAttendee.user)}
          <Row>
            <Text modifiers={['block', 'medium', 'blue']}>
              {displayTime(call.createdAt, 'hh:mm a, MM/DD/YY')}
            </Text>
          </Row>
        </Column>
        <TypeDuration
          type={call.isPSTN ? 'pstn' : 'video'}
          duration={callDuration}
        />
      </Row>
      {call.referredPatient &&
        call.referredPatient._id !== otherAttendee.user._id && (
          <>
            <HR modifiers={['gray']} />
            <Row modifiers={['topGutters_1']}>
              <Text>
                Reference:{' '}
                <Text modifiers={['primary']}>
                  {formatTitleAndName(call.referredPatient)}
                </Text>
              </Text>
            </Row>
          </>
        )}
    </Container>
  );
};

export default CallItem;

import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { isEmpty, isFunction } from 'lodash';
import { useMutation } from '@apollo/client';
import axios from 'axios';
import qs from 'query-string';
import { Text, Button, HR } from 'common';
import { SlidingPane } from 'common';
import { ChatLayout } from 'layouts';
import { EVENTS, SocketContext } from 'contexts/socket';
import { TIMER_TYPE, TimerContext } from 'contexts/timer';
import { CALL_TYPES, CallServiceContext } from 'contexts/call';
import { FETCH_OR_CREATE_CHAT, SIGN_S3 } from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import assets from 'assets';
import urls from 'routes/urls';
import { getUser } from 'graphql/utils';
import BookAppointment from 'pages/Appointments/BookAppointment';

import MessageItem from './MessageItem';
import {
  // S3_VIDEO_FOLDER,
  // S3_IMAGE_FOLDER,
  SHARE_OPTIONS,
  // MEDIA_TYPES,
} from './constants';

// import AddMedia from './AddMedia';
import { AddAttachment } from 'common';
import ShareOptions from './ShareOptions';
import Participants from './Participants';
import ReferPatient from './ReferPatient';
import ChatFooter from './ChatFooter';
import AddParticipant from './AddParticipant';
import ReferredPatient from './ReferredPatient';

export default function Chat({ history, location }) {
  const [memberIds, setMemberIds] = useState([]);
  const [referredPatientId, setReferredPatientId] = useState(null);
  const [includedPatientId, setIncludedPatientId] = useState(null);
  const [includedPatient, setIncludedPatient] = useState(null);
  const [members, setMembers] = useState([]);
  const [referredPatient, setReferredPatient] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [isSelectingMessages, setIsSelectingMessages] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [isAddingMedia, setIsAddingMedia] = useState(false);
  const [isAddingParticipant, setIsAddingParticipant] = useState(false);
  const [signS3] = useMutation(SIGN_S3);
  const [uploading, setUploading] = useState(false);
  const [isReferringPatient, setIsReferringPatient] = useState(false);
  const [scrollTargetMessageId, setScrollTargetMessageId] = useState(null);
  const [hasAttachment, setHasAttachment] = useState(false);
  const [showBookAppointment, setShowBookAppointment] = useState(false);
  const [attachment, setAttachment] = useState({});
  const chatContentRef = useRef(null);
  const scrollTargetMessageRef = useRef(null);
  const attachmentRef = useRef(null);

  // Either patient is included in the chat or referenced
  // We store all info associated with the patient
  const patientId = includedPatientId || referredPatientId;

  const [
    fetchOrCreateChat,
    { data: { fetchOrCreateChat: chat } = {}, loading },
  ] = useMutation(FETCH_OR_CREATE_CHAT);

  const { subscribe, sendData } = useContext(SocketContext);
  const { addTimer, completeTimer, setPatientId } = useContext(TimerContext);
  const { startCall } = useContext(CallServiceContext);

  const handleMessageReceive = useCallback(
    (payload) => {
      const {
        _id,
        chat: chatId,
        sender,
        text,
        attachment,
        note,
        careplan,
        createdAt,
      } = payload;
      if (chat?._id === chatId) {
        const shouldScroll = getShouldScroll();

        setMessages((messages) => [
          ...messages,
          { _id, sender, text, attachment, createdAt, note, careplan },
        ]);

        if (shouldScroll) {
          scrollToMessage();
        }
      }
    },
    [chat?._id]
  );

  const getShouldScroll = () => {
    const element = chatContentRef.current;
    return element.scrollTop + element.clientHeight === element.scrollHeight;
  };

  const scrollToMessage = () => {
    if (scrollTargetMessageId && scrollTargetMessageRef.current) {
      scrollTargetMessageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setTimeout(() => {
        setScrollTargetMessageId(null);
      }, 500);
    } else {
      // scroll to last message
      const element = chatContentRef.current;
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    }
  };

  useEffect(() => {
    if (chat?._id) {
      addTimer(TIMER_TYPE.CHAT, chat?._id);
    }
    return () => {
      if (chat?._id) {
        completeTimer(TIMER_TYPE.CHAT);
      }
    };
  }, [chat?._id]);

  useEffect(() => {
    setPatientId(patientId);
  }, [setPatientId, patientId]);

  useEffect(() => {
    const query = qs.parse(location.search);
    const memberIds = Array.isArray(query.memberIds)
      ? query.memberIds
      : [query.memberIds];
    setMemberIds(memberIds);
    setReferredPatientId(query.referredPatientId);
    if (location.state?.messageId) {
      setScrollTargetMessageId(location.state.messageId);
    }

    (async () => {
      const users = await Promise.all(memberIds.map((mId) => getUser(mId)));
      const patient = users.find((user) => user.role === 'patient');

      setIncludedPatientId(patient?._id);
      setIncludedPatient(patient);
    })();
  }, [location]);

  useEffect(() => {
    (async () => {
      const { data } = await fetchOrCreateChat({
        variables: {
          chat: {
            memberIds,
            referredPatientId,
          },
        },
      });

      setMessages(data.fetchOrCreateChat.messages);
      scrollToMessage();
    })();
  }, [memberIds, referredPatientId, fetchOrCreateChat]);

  useEffect(() => {
    (async () => {
      const users = await Promise.all(memberIds.map((mId) => getUser(mId)));
      setMembers(users);

      if (referredPatientId) {
        const patient = await getUser(referredPatientId);
        setReferredPatient(patient);
      }
    })();
  }, [memberIds, referredPatientId]);

  useEffect(() => {
    const subscription = subscribe(EVENTS.CHAT, handleMessageReceive);

    return () => {
      subscription.unsubscribe();
    };
  }, [subscribe, handleMessageReceive]);

  loadingVar(loading || uploading);

  const handleMeetNow = () => {
    startCall(CALL_TYPES.UNSCHEDULED, members[0], referredPatient);
  };

  const onSendMessage = async ({ message } = {}, { resetForm } = {}) => {
    if (!isEmpty(attachment)) {
      sendFile(attachment.file, attachment.type, message);
    } else {
      sendData(EVENTS.CHAT, {
        chatId: chat._id,
        memberIds,
        referredPatientId,
        text: message,
      });
    }
    if (isFunction(resetForm)) resetForm();
  };

  const onSelectShareAction = (name) => () => {
    switch (name) {
      case SHARE_OPTIONS.ADD_PARTICIPANT:
        setIsAddingParticipant(true);
        break;
      case SHARE_OPTIONS.ADD_ATTACHMENT:
        attachmentRef.current.openInput();
        break;
      case SHARE_OPTIONS.ADD_NOTE:
        setIsSelectingMessages(true);
        break;
      case SHARE_OPTIONS.REFER_PATIENT:
        setIsReferringPatient(true);
        break;
      case SHARE_OPTIONS.MEDIA_LIBRARY:
        setIsAddingMedia(true);
        break;
      case SHARE_OPTIONS.VIEW_ATTACHMENTS:
        // listAttachments();
        break;
      case SHARE_OPTIONS.BOOK_APPOINTMENT:
        setShowBookAppointment(true);
        break;
      default:
        return;
    }
    setIsPaneOpen(false);
  };

  // const listAttachments = useCallback(() => {
  //   if (messages.length) {
  //     const attachmentsList = messages
  //       .map(({ attachment }) => attachment)
  //       .filter((attachment) => !!attachment?.url);

  //     setAttachments(attachmentsList);
  //   }
  // }, [messages]);

  const onSelectMessage = (messageId) => (checked) => {
    setSelectedMessages((_selectedMessages) => {
      if (!checked) {
        const messageIdx = _selectedMessages.indexOf(messageId);
        const newState = _selectedMessages.slice();
        newState.splice(messageIdx, 1);
        return newState;
      }
      return [..._selectedMessages, messageId];
    });
  };

  useEffect(() => {
    if (isAddingMedia) {
      if (isPaneOpen) setIsPaneOpen(false);
    }
  }, [isAddingMedia, isPaneOpen]);

  const sendFile = async (file, fileType, message = null) => {
    setUploading(true);
    const {
      data: {
        signS3: { url, signedRequest },
      },
    } = await signS3({
      variables: {
        folder: `attachments-${chat._id}`,
        fileType,
      },
    });
    axios
      .put(signedRequest, file, {
        headers: {
          'Content-Type': fileType,
        },
      })
      .then(() => {
        const attachment = {
          url,
          originalName: file.name,
        };
        if (fileType.includes('video')) {
          attachment.type = 'video';
        } else if (fileType.includes('image')) {
          attachment.type = 'image';
        } else if (fileType === 'application/pdf') {
          attachment.type = 'pdf';
        } else {
          attachment.type = fileType.split('/')[1];
        }
        sendData(EVENTS.CHAT, {
          chatId: chat._id,
          memberIds,
          referredPatientId,
          attachment,
          text: message,
        });
      })
      .catch((error) => {
        // errorVar(error);
      })
      .finally(() => {
        setUploading(false);
        URL.revokeObjectURL(file);
        setAttachment({});
      });
  };

  const handleReferPatient = (id) => {
    setIsReferringPatient(false);

    if (id) {
      history.replace({
        pathname: urls.CHAT,
        search: qs.stringify({
          memberIds,
          referredPatientId: id,
        }),
      });
    }
  };

  const handleAddParticipants = (newParticipants) => {
    const newMemberIds = newParticipants.map((p) => p._id);
    history.replace({
      pathname: urls.CHAT,
      search: qs.stringify({
        memberIds: [...memberIds, ...newMemberIds],
        referredPatientId,
      }),
    });
    setIsAddingParticipant(false);
  };

  const handleAddToNote = () => {
    if (!patientId) {
      return;
    }

    history.push({
      pathname: `/patients/${patientId}/notes/create`,
      state: {
        attachments: [
          {
            category: 'chat',
            chatId: chat?._id,
            messageIds: selectedMessages,
            createdAt: Date(),
          },
        ],
      },
    });
  };

  const handleAddAttachment = (attachment) => {
    setAttachment(attachment);
  };

  useEffect(() => {
    // if attachment is present
    if (!isEmpty(attachment)) {
      const isPreviewableType =
        attachment.type.includes('video') ||
        attachment.type.includes('image') ||
        attachment.type === 'application/pdf';
      if (isPreviewableType) {
        // trigger preview display logic
        setHasAttachment(true);
      } else {
        // for unsupported file preview, send file directly
        sendFile(attachment.file, attachment.type);
      }
    } else setHasAttachment(false);
  }, [attachment]);

  return (
    <ChatLayout>
      <ChatLayout.Header
        handleClickBack={
          isSelectingMessages ? () => setIsSelectingMessages(false) : undefined
        }
        showTimer={!!patientId}
        handleMeet={handleMeetNow}
        isAddingToNote={isSelectingMessages}
        stopAddToNote={() => {
          setIsSelectingMessages(false);
          setSelectedMessages([]);
        }}
      >
        <Participants members={members} />
      </ChatLayout.Header>
      <ReferredPatient referredPatient={referredPatient} />
      <ChatLayout.ContentWrapper
        hasAttachment={hasAttachment}
        ref={chatContentRef}
      >
        {messages.map((message) => (
          <MessageItem
            ref={
              message._id === scrollTargetMessageId
                ? scrollTargetMessageRef
                : null
            }
            message={message}
            key={message._id}
            isSelecting={isSelectingMessages}
            isSelected={selectedMessages.includes(message._id)}
            onClickSelect={onSelectMessage}
          />
        ))}
        {isReferringPatient && (
          <ReferPatient
            referredPatientId={referredPatientId}
            onSelect={handleReferPatient}
          />
        )}
      </ChatLayout.ContentWrapper>
      <ChatLayout.Footer>
        <ChatFooter
          hasAttachment={hasAttachment}
          attachment={attachment}
          onRemoveAttachment={() => setAttachment({})}
          handleOpenShareOptions={() => setIsPaneOpen(true)}
          handleSubmit={onSendMessage}
          isAddingToNote={isSelectingMessages}
          selectedMessagesCount={selectedMessages.length}
          onClickAddToNote={handleAddToNote}
        />
      </ChatLayout.Footer>
      {isAddingParticipant && (
        <AddParticipant
          hasPatient={!!patientId}
          existingMemberIds={memberIds}
          handleDone={handleAddParticipants}
          handleBack={() => setIsAddingParticipant(false)}
        />
      )}
      <AddAttachment
        ref={attachmentRef}
        onAddAttachment={handleAddAttachment}
      />
      {showBookAppointment && (
        <BookAppointment
          date={new Date()}
          patient={includedPatient}
          handleDone={() => setShowBookAppointment(false)}
        />
      )}
      <SlidingPane isOpen={isPaneOpen} handleClose={() => setIsPaneOpen(false)}>
        <SlidingPane.Header>
          <Button
            image={assets.icons.icChatPlus}
            modifiers={['icon', 'transparent']}
            width={16}
            height={16}
          />
          <Text modifiers={['bold']}>Gazuntite Message Center</Text>
          <Button
            onClick={() => setIsPaneOpen(false)}
            image={assets.icons.icClosePlain}
            modifiers={['icon', 'transparent']}
            width={9}
            height={9}
            imageWidth={9}
            imageHeight={9}
          />
        </SlidingPane.Header>
        <HR modifiers={['colored']} />
        <SlidingPane.Content>
          <ShareOptions
            hasPatient={!!patientId}
            hasDirectPatient={!!includedPatientId}
            onClickAction={onSelectShareAction}
          />
        </SlidingPane.Content>
      </SlidingPane>
    </ChatLayout>
  );
}

import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Container, Row, Column, Text, Button } from 'common';

import { buildStyledComponent, px2rem } from 'style';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import { formatTitleAndName } from 'utils/string';
import { displayTime } from 'utils/time';

const ListType = buildStyledComponent(
  'ActiveCareplanSection',
  styled(({ type, modifiers, ...props }) => (
    <Button modifiers={['transparent', 'white', 'small']} {...props}>
      {type}
    </Button>
  )),
  ({ theme }) => `
    text-align: center;
    box-shadow: none;
    border-radius: 0;
  `,
  {
    modifierConfig: {
      active: ({ theme }) => `
        border-bottom: solid ${theme.dimensions.borderWidth_1} ${theme.colors.info};
      `,
    },
  }
);

const CareplanItem = buildStyledComponent(
  'CareplanItem',
  styled(({ careplan, ...props }) => (
    <Row {...props}>
      <Column modifiers={['col_5', 'start', 'noPadding']}>
        {formatTitleAndName(careplan?.creator.user)}
      </Column>
      <Column modifiers={['col_7', 'noPadding']}>
        <Row modifiers={['spaceBetween']}>
          <Text modifiers={['medium']}>
            Created:{' '}
            <Text modifiers={['primary', 'medium']}>
              {careplan && displayTime(careplan.signDate, 'MM/DD/YY')}
            </Text>
          </Text>
          <Text modifiers={['primary', 'medium']}>&gt;</Text>
        </Row>
      </Column>
    </Row>
  )),
  ({ theme }) => `
    box-shadow: ${px2rem(2)} ${px2rem(0)} ${px2rem(5)} ${px2rem(1)} ${
    theme.colors.darkGrey
  };
    padding: ${theme.dimensions.padding_2};
    margin: ${theme.dimensions.padding_2} 0;
  `
);

const CareplanList = ({ careplans, onItemClick }) => {
  const items = careplans.map((careplan) => (
    <CareplanItem
      key={careplan?._id}
      careplan={careplan}
      onClick={() => {
        onItemClick(careplan);
      }}
    />
  ));

  return <Container>{items}</Container>;
};

const Component = ({ showCareplan, ...props }) => {
  const { activeCareplan, careplans } = useContext(PatientHomeContext);
  const [activeSelected, setActiveSelected] = useState(true);
  const expiredCareplans =
    careplans?.filter((careplan) => !careplan.isActive) || [];

  return (
    <Container modifiers={['fluid']} {...props}>
      <Row modifiers={['spaceAround']}>
        <Column modifiers={['noPadding', 'col_4', 'center', 'flexBox']}>
          <ListType
            type="active"
            onClick={() => setActiveSelected(true)}
            modifiers={[activeSelected && 'active']}
          />
        </Column>
        <Column modifiers={['noPadding', 'col_4', 'center', 'flexBox']}>
          <ListType
            type="expired"
            onClick={() => setActiveSelected(false)}
            modifiers={[!activeSelected && 'active']}
          />
        </Column>
      </Row>
      <Row>
        {careplans && (
          <CareplanList
            careplans={activeSelected ? [activeCareplan] : expiredCareplans}
            onItemClick={showCareplan}
          />
        )}
      </Row>
    </Container>
  );
};

const styles = ({ theme }) => `
  font-size: ${theme.dimensions.fontSizeMedium};
  text-align: center;
`;

const CareplanListSection = buildStyledComponent(
  'CareplanListSection',
  styled(Component),
  styles
);

export default CareplanListSection;

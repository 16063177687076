import { Column } from 'common';
import { buildStyledComponent } from 'style';
import styled from 'styled-components';

const styles = () => `
  display: flex;
  align-items: center;
  padding: 0;
  flex: 1;
  flex-wrap: wrap;
`;

const contentStyles = ({ membersAmount }) => `
  display: ${membersAmount > 1 ? 'inline-block' : 'flex'};
  align-items: center;
`;

const Content = buildStyledComponent(
  'Participants_AvatarContent',
  styled.div,
  contentStyles
);

const AvatarWrapper = buildStyledComponent(
  'Participants_AvatarWrapper',
  styled(Column),
  styles
);

AvatarWrapper.Content = Content;

export default AvatarWrapper;

import React from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import _ from 'lodash';

import {
  Container,
  Row,
  Column,
  Button,
  Text,
  BackButton,
  Modal,
} from 'common';
import { FormPinInput, FormPasswordInput } from 'common';
import { MainLayout } from 'layouts';
import assets from 'assets';

import schema from 'utils/schema';
import { useMutation } from '@apollo/client';
import { UPDATE_SECURITY } from 'graphql/mutations';

const { Header, Content } = MainLayout;
const createPasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().min(
    8,
    ({ min }) => `Password must be at least ${min} characters`
  ),
  password: schema.password.optional(),
  confirmPassword: schema.confirmPassword.optional(),
  currentPin: Yup.string().length(4, 'Please enter valid code'),
  pin: schema.pin.optional(),
  confirmPin: schema.confirmPin.optional(),
});

const initialValues = {
  currentPassword: '',
  password: '',
  confirmPassword: '',
  currentPin: '',
  pin: '',
  confirmPin: '',
};

export default function Security() {
  const [updateSecurity] = useMutation(UPDATE_SECURITY);

  const handleSubmit = async (values, { resetForm }) => {
    updateSecurity({
      variables: {
        security: _.pick(values, [
          'currentPassword',
          'password',
          'currentPin',
          'pin',
        ]),
      },
      onCompleted: (data) => {
        if (data.updateSecurity) {
          Modal.info({
            render: () => (
              <Modal.Body>
                <Row modifiers={['middle', 'center']}>
                  <Column modifiers={['col_12']}>
                    <Text modifiers={['secondaryColor', 'bold']}>
                      Your passwords have been updated successfully
                    </Text>
                  </Column>
                  <Column modifiers={['col_12', 'flexBox', 'center']}>
                    <Button
                      image={assets.icons.icCloseGrey}
                      modifiers={['icon', 'transparent']}
                    />
                  </Column>
                </Row>
              </Modal.Body>
            ),
          });

          resetForm();
        }
      },
      onError: () => {},
    });
  };

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton />
        </Header.Left>
        <Header.Center>
          <Text modifiers={['semiBold']}>Security</Text>
        </Header.Center>
        <Header.Right />
      </Header>
      <Content>
        <Formik
          initialValues={initialValues}
          validationSchema={createPasswordSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, dirty }) => (
            <Form>
              <Container modifiers="padding_2">
                <Text modifiers="bold">Change Password</Text>
                <Container>
                  <Field
                    name="currentPassword"
                    placeholder="Enter Current Password"
                    label="Enter Current Password"
                    component={FormPasswordInput}
                  />
                </Container>
                <Container>
                  <Field
                    name="password"
                    placeholder="New password"
                    label="New Password"
                    component={FormPasswordInput}
                  />
                </Container>
                <Container>
                  <Field
                    name="confirmPassword"
                    placeholder="Confirm New Password"
                    label="Confirm New Password"
                    component={FormPasswordInput}
                  />
                </Container>
              </Container>
              <hr />
              <Container modifiers="padding_2">
                <Text modifiers="bold">Change 4-digit Code</Text>
                <Container>
                  <Field
                    hasLabel
                    placeholder="Enter Current 4-digit Code"
                    name="currentPin"
                    component={FormPinInput}
                    fields={4}
                  />
                </Container>
                <Container>
                  <Field
                    hasLabel
                    placeholder="New 4-digit Code"
                    name="pin"
                    component={FormPinInput}
                    fields={4}
                  />
                </Container>
                <Container>
                  <Field
                    hasLabel
                    placeholder="Confirm New 4-digit Code"
                    name="confirmPin"
                    component={FormPinInput}
                    fields={4}
                  />
                </Container>
              </Container>
              <Container>
                <Row modifiers={['center']}>
                  <Button
                    type="submit"
                    modifiers={(!isValid || !dirty) && 'disabled'}
                  >
                    Save
                  </Button>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      </Content>
    </MainLayout>
  );
}

import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  dark: ({ theme }) => ({
    styles: `
      background-color: ${theme.colors.transparent};
      border-color: ${theme.colors.white};
      color: ${theme.colors.white};
    `,
  }),
  error: ({ theme }) => ({
    styles: `
      border-color: ${theme.colors.danger} !important;
    `,
  }),
  borderBottomOnly: ({ theme }) => ({
    styles: `
      border-width: 0;
      border-bottom-width: 1px;
      border-color: ${theme.colors.grey};
      padding: ${theme.dimensions.padding_1} 0;
    `,
  }),
  borderPrimaryBottomOnly: ({ theme }) => ({
    styles: `
      border-width: 0;
      border-bottom-width: 1px;
      border-color: ${theme.colors.primary};
      padding: ${theme.dimensions.padding_1} ${theme.dimensions.padding_1};
      border-radius: 0px;
      margin-bottom: 3px;
      background-color: unset;
    `,
  }),
  valid: ({ theme }) => ({
    styles: `
      border-color: ${theme.colors.primary};
    `,
  }),
  large: () => ({
    styles: ``,
  }),
  asInfo: ({ theme }) => `
    color: ${theme.colors.primary};
    text-align: center;
  `,
  asGrayInfo: ({ theme }) => `
    border: 0;
    color: ${theme.colors.text};
    text-align: center;
    font-weight: ${theme.fontWeights.bold};
  `,
  noBorder: () => `
    border: none;
  `,
  noPadding: () => `
    padding: 0;
  `,
  widthSmall: () => `
    width: ${px2rem(40)};
  `,
  fullWidth: () => `
    width: 100%;
  `,
  hidden: () => `
    border: none;
    background-color: transparent;
    box-shadow: none;
  `,
  disabled: () => `
    border: none;
    background-color: transparent;
    box-shadow: none;
  `,
  vital: () => `
    box-shadow: 0px 0px 2px #EDEBFE;
    border: 1px solid #DFDFFF;
    border-radius: 6px;
    color: #3B4C6A;
    text-align: center;
  `,
  fitContent: ({ value = '' }) => `
    width: ${value.length}ch;
    min-width: 4ch;
    padding-right: 0;
  `,
  textCenter: () => `
    text-align: center;
  `,
  squared: () => `
    padding: 10px;
  `,
  darkPlaceholder: ({ theme }) => `
    &::placeholder {
      color: ${theme.colors.darkGrey};
    }
  `,
  textNormal: ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeNormal};
  `,
};

const styles = ({ theme }) => `
  background-color: ${theme.colors.white};
  border: 1px solid #3B4C6A;
  color: ${theme.colors.secondary};
  font-size: ${theme.dimensions.fontSizeLarge};
  font-family: ${theme.fonts.secondary} !important;
  outline: none;
  padding: ${theme.dimensions.padding_1};
  width: 100%;
  border-radius: 10px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
`;

export const Input = buildStyledComponent('Input', styled.input, styles, {
  modifierConfig,
});

export default Input;

import { createRef, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import { Container } from 'common';

import { FormTimePicker } from '../Form/FormTimePicker';

export const TimePicker = ({
  handleChange,
  children,
  noFormWrap,
  name = 'time',
  value,
}) => {
  const timeFieldRef = createRef();

  const handleChangeTime = (value) => {
    handleChange(value);
  };

  const handleComponentClick = useCallback(
    (event) => {
      if (timeFieldRef.current) {
        timeFieldRef.current.click();
      }
    },
    [timeFieldRef]
  );

  const fieldComponent = (
    <Field
      type="hidden"
      name={name}
      component={FormTimePicker}
      handleChange={handleChangeTime}
      innerRef={timeFieldRef}
    />
  );

  let wrapper;

  if (noFormWrap) {
    wrapper = fieldComponent;
  } else {
    wrapper = (
      <Formik initialValues={{ time: value }}>
        {() => <Form>{fieldComponent}</Form>}
      </Formik>
    );
  }

  return (
    <Container modifiers={['fluid']}>
      <Container modifiers={['fluid']} onClick={handleComponentClick}>
        {children}
      </Container>
      {wrapper}
    </Container>
  );
};

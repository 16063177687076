import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const dayStyle = ({ theme }) => `
  width: ${px2rem(36)};
  height: ${px2rem(36)};
  overflow: hidden;
  border: solid ${px2rem(2)} ${theme.colors.primary};
  border-radius: ${px2rem(18)};
  color: ${theme.colors.primary};
  font-size: ${px2rem(24)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const modifierConfig = {
  selected: ({ theme }) => `
    background-color: ${theme.colors.primary};
    border-color: ${theme.colors.primary} !important;
    color: ${theme.colors.white};
  `,
  disabled: ({ theme }) => `
    border-color: ${theme.colors.gray5};
    color: ${theme.colors.gray5};
    pointer-events: none;
  `,
  roundRect: ({ theme }) => `
    border-radius: ${px2rem(8)} !important;
    border-color: ${theme.colors.gray5} !important;
    color: ${theme.colors.gray5};
    font-size: ${px2rem(15)};
  `,
};

const Day = buildStyledComponent('WeekDay', styled.div, dayStyle, {
  modifierConfig,
});

const styles = ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Wrapper = buildStyledComponent('WeekDayPicker', styled.div, styles);

Wrapper.Day = Day;

export default Wrapper;

import styled from 'styled-components';
import { Container } from 'common';
import { buildStyledComponent, px2rem } from 'style';

const styles = () => `
  border: 1px solid #FAFAFF;
  overflow-y: auto;
  border-radius: ${px2rem(8)};
  max-height: ${px2rem(200)};
`;

export default buildStyledComponent(
  'PastItems_Wrapper',
  styled(Container),
  styles
);
